import { makeStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

interface INavbarButtonsProps {
  buttons?: boolean;
  mobile?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

export default makeStyles(theme => ({
  root: (props: INavbarButtonsProps) => ({
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: -20,
      right: -20,
      height: 1.5,
      backgroundColor: props?.buttons ? 'transparent' : configTheme.megafonColors.background.grey.default,
      [theme.breakpoints.up(configTheme.breakpoints.values.mdx)]: {
        left: 0,
        right: 0
      }
    }
  }),
  container: {
    position: 'relative'
  },
  scrollContainer: {
    overflow: 'auto',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0
    }
  },
  itemWrap: (props: INavbarButtonsProps) => ({
    '&:not(:first-child)': {
      marginLeft: props.buttons ? 0 : 36,
      [theme.breakpoints.up(configTheme.breakpoints.values.mdx)]: {
        marginLeft: props?.buttons ? 0 : 0,
        paddingLeft: props?.buttons ? 0 : 36
      },
      [theme.breakpoints.down(configTheme.breakpoints.values.mdx)]: {
        marginLeft: props?.buttons ? 0 : 10
      }
    }
  }),
  item: {
    ...configTheme.fontFamily.MegaFonGraphikLCRegular,
    color: configTheme.colors.text.dark.neutral,
    fontSize: 14,
    cursor: 'pointer',
    letterSpacing: '-0.3px',
    padding: '13px 0',
    lineHeight: '18px',
    textDecoration: 'none',
    position: 'relative',
    whiteSpace: 'nowrap',
    display: 'block',
    [theme.breakpoints.up(configTheme.breakpoints.values.mdx)]: {
      fontSize: 17
    },
    '&.exact': {
      color: configTheme.megafonColors.basic.black.normal,
      pointerEvents: 'none'
    }
  },
  itemButton: (props: INavbarButtonsProps) => ({
    margin: 0,
    border: `1px solid ${ configTheme.megafonColors.basic.green.default }`,
    borderLeft: props.isFirst ? `1px solid ${ configTheme.megafonColors.basic.green.default }` : 'none',
    borderRadius: props.isFirst ? '20px 0 0 20px' : props.isLast ? '0 20px 20px 0' : 0,
    boxShadow: 'none !important',
    '& .mfui-button__inner': {
      height: props.mobile ? 30 : 35,
      fontSize: props.mobile ? 12 : 17
    },
    '& .mfui-button__text': {
      ...configTheme.fontFamily.MegaFonGraphikLCRegular
    }
  }),
  itemLink: {
    '&::before': {
      content: '',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 1.5,
      transition: 'background-color 175ms linear',
      backgroundColor: configTheme.megafonColors.background.grey.default
    },
    '&.exact': {
      color: configTheme.megafonColors.basic.black.normal,
      pointerEvents: 'none',
      '&::before': {
        content: '""'
      }
    }
  }
}));
