import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconFavorite: FC <SvgIconProps> = props => (
  <SvgIcon width="17" height="15" viewBox={ '0 0 17 15' } { ...props }>
    <path d="M5.03553 1C2.80677 1 1 2.80677 1 5.03553C1 6.10582 1.42517 7.13228 2.18198 7.88909L8.14645
      13.8536C8.34171 14.0488 8.65829 14.0488 8.85355 13.8536L14.818 7.88909C15.5748 7.13228 16 6.10582 16
      5.03553C16 2.80677 14.1932 1 11.9645 1C10.8942 1 9.86772 1.42517 9.11091 2.18198L8.5 2.79289L7.88909
      2.18198C7.13228 1.42517 6.10582 1 5.03553 1Z"
    />
  </SvgIcon>
);

export default IconFavorite;
