//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
import {
  TListHorizontalProps
} from './list.horizontal.models';
//#endregion

const CARD_HEIGHT = {
  xxs: {
    left: 260,
    central: 237,
    picture: 350,
    book: 250
  },
  xs: {
    left: 300,
    central: 237,
    picture: 350,
    book: 250
  },
  sm: {
    left: 342,
    central: 260,
    picture: 560,
    book: 250
  },
  md: {
    left: 375,
    central: 342,
    picture: 560,
    book: 340
  },
  lg: {
    left: 455,
    central: 374,
    picture: 560,
    book: 500
  },
  xl: {
    left: 514,
    central: 384,
    picture: 560,
    book: 500
  }
};

const getCardHeight = ({ size= 'sm', cardAlign= 'central' }: TListHorizontalProps) => CARD_HEIGHT[size][cardAlign];

export default makeStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      margin: '0 auto'
    },
    list: {
      overflowX: 'scroll',
      scrollBehavior: 'smooth',
      cursor: 'pointer',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        backgroundColor: 'transparent'
      }
    },
    dot: (props: TListHorizontalProps) => ({
      width: props.size === 'xxs' ? 8 : 11,
      height: props.size === 'xxs' ? 8 : 11,
      margin: 5,
      borderRadius: '50%',
      backgroundColor: configTheme.megafonColors.background.grey.default
    }),
    dotActive: (props: TListHorizontalProps) => ({
      width: props.size === 'xxs' ? 10 : 13,
      height: props.size === 'xxs' ? 10 : 13,
      backgroundColor: configTheme.megafonColors.basic.purple
    }),
    dots: (props: TListHorizontalProps) => ({
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }),
    xxs: (props: TListHorizontalProps) => ({
      height: getCardHeight({ ...props, size: 'xxs' })
    }),
    xs: (props: TListHorizontalProps) => ({ height: getCardHeight({ ...props, size: 'xs' }) }),
    sm: (props: TListHorizontalProps) => ({
      height: getCardHeight({ ...props, size: 'sm' })
    }),
    md: (props: TListHorizontalProps) => ({ height: getCardHeight({ ...props, size: 'md' }) }),
    lg: (props: TListHorizontalProps) => ({ height: getCardHeight({ ...props, size: 'lg' }) }),
    xl: (props: TListHorizontalProps) => ({ height: getCardHeight({ ...props, size: 'xl' }) })
  }),
  { name: 'ListHorizontal' }
);
