import React from 'react';

const RU = {
  action: {
    add: 'Добавить {{of}}',
    cancel: 'Отменить {{of}}',
    close: 'Закрыть {{of}}',
    change: 'Изменить {{of}}',
    create: 'Создать {{of}}',
    del: 'Удалить {{of}}',
    edit: 'Редактировать {{of}}',
    save: 'Сохранить {{of}}',
    upload: 'Загрузить {{of}}',
    import: 'Импорт {{of}}',
    chooseFile: 'Прикрепить файл',
    begin: 'Начать',
    disable: 'Отключить',
    toPlug: 'Подключить',
    comeIn: 'Войти'
  },
  common: {
    price: 'Стоимость',
    profile: 'Личный кабинет',
    showAll: 'Показать все',
    noResult: 'Ничего не найдено',
    titleApp: 'Мегафон | ЗОЖ',
    rus: 'Русский',
    eng: 'Английский',
    name: 'Имя',
    uri: 'Идентификатор ресурса',
    format: 'Формат',
    encode: 'Кодировка',
    group: 'Группа {{group}}',
    yourUri: 'Ваш URI: {{uri}}',
    state: 'Состояние',
    type: 'Тип',
    host: 'Хост',
    show: 'Показать',
    startNow: 'Начать сейчас!',
    hide: 'Скрыть',
    of: {
      group: 'группу',
      operator: 'оператора'
    },
    description: 'Описание',
    URI: 'URI',
    abonent: 'абонент',
    action: 'действие',
    addParam: 'добавить параметр',
    additional: 'дополнительные',
    apply: 'применить',
    back: 'назад',
    basic: 'базовые',
    contentType: 'контент',
    country: 'страна',
    create: 'создать',
    dateFrom: 'с',
    dateTo: 'до',
    dcs: 'dcs',
    delete: 'удалить',
    direction: 'направление',
    download: 'скачать',
    edit: 'редактировать',
    embeddedService: 'встроенный сервис',
    encoding: 'кодировка',
    esmClass: 'esmClass',
    file: 'файл',
    find: 'найти',
    found: 'найдено',
    formatType: 'формат',
    groupByDate: 'групировать по дате',
    groupByFields: 'группировать по полям',
    id: 'id',
    language: 'язык',
    link: 'линк',
    linkIds: 'линки',
    linkType: 'тип линка',
    message: 'сообщение',
    move: 'переместить',
    msisdnList: 'msisdn лист',
    msisdn: 'msisdn',
    notFound: 'не найдено',
    num: 'короткий номер',
    operator: 'оператор',
    operators: 'операторы',
    params: 'параметры',
    pattern: 'шаблон',
    permissions: 'разрешения',
    plugin: 'входящие подключения',
    plugout: 'исходящие подключения',
    priority: 'приоритет',
    process: 'выполнить',
    protocolId: 'протокол',
    recipient: 'получатель',
    runOnHost: 'запустить на хосте',
    save: 'Сохранить',
    sdpMlMessage: 'sdpMlMessage',
    service: 'сервис',
    serviceId: 'сервис',
    serviceIds: 'сервисы',
    serviceURIType: 'URI тип',
    string: 'строка',
    teaserName: 'тизер',
    test: 'тест',
    text: 'текст',
    title: 'название',
    upload: 'загрузить',
    uploadType: 'тип загрузки',
    url: 'URL',
    urlMsisdn: 'URL для msisdn',
    search: 'Поиск',
    'clear-form': 'Очистить форму',
    status: 'cтатус',
    findByName: 'найти по имени',
    sessionExpired: 'сессия закончилась',
    clear: 'очистить',
    hello: 'добрый день',
    more: 'Показать еще',
    nothing: 'Ничего не найдено',
    cancel: 'Отмена',
    add: 'Добавить',
    read: 'Читать',
    recommend: 'Рекомендуем',
    passTest: 'Пройти тест',
    listen: 'Слушать',
    close: 'Закрыть'
  },
  components: {
    shared: {
      badge: {
        available: 'Подписан',
        paid: 'По подписке',
        free: 'Бесплатно'
      }
    }
  },
  contentType: {
    all: 'Все',
    video: 'Посмотреть',
    audio: 'Послушать',
    book: 'Почитать',
    free: 'БЕСПЛАТНО',
    paid: 'ПОДПИСКА'
  },
  actionType: {
    WATCH: 'Смотреть онлайн',
    READ: 'Читать онлайн',
    LISTEN: 'Слушать онлайн',
    now: {
      by: {
        contentType: {
          GAME_HTML5: 'Играть сейчас',
          VIDEO: 'Смотреть сейчас',
          BOOK: 'Читать сейчас'
        }
      }
    }
  },
  auth: {
    enterPhone: 'Введите номер телефона',
    enterCode: 'Введите код из SMS',
    phoneLabel: 'Номер телефона',
    sendCode: 'Далее',
    codeSent: 'Мы отправили код на номер {{phone}}',
    codeLabel: 'Код из SMS',
    login: 'Войти',
    cancel: 'Отмена',
    toPhone: 'Другой номер телефона',
    resendCode: 'Отправить код еще раз',
    title: 'Вход по SMS',
    descr: 'Для получения доступа к контенту необходимо авторизоваться.',
    errors: {
      msisdnInvalid: 'Номер введен неверно или не принадлежит сети оператора.'
    },
    codeStatus: {
      wrong: 'Неверный код подтверждения. Пожалуйста, попробуйте еще раз.',
      exhausted: 'Срок действия кода подтверждения истек. Пожалуйста, введите новый код.'
    },
    warning: 'При отмене авторизации вы не сможете получить доступ к программе питания и тренировок.'
  },
  header: {
    diet: 'Моя диета',
    auth: 'Вход',
    me: '',
    main: 'Главная'
  },
  footer: {
    company: `© ЗАО «ТТ мобайл», МегаФон-Точикистон, ${new Date().getFullYear()}`,
    terms: 'Пользовательское соглашение'
  },
  me: {
    header: 'Личный кабинет',
    subscription: 'Подписка',
    number: 'Номер',
    subscriptions: 'Подписка',
    myData: 'Мои данные',
    change: 'Изменить',
    notifications: 'Нотификации',
    notification: 'Нотификация',
    notificationType: 'Канал доставки',
    activate: 'Подключить',
    deactivate: 'Отключить',
    deactivateHeader: 'Отключить подписку?',
    deactivateText:
      'Есть над чем задуматься: многие известные личности призывают нас к новым свершениям, которые, в свою очередь, должны быть представлены в исключительно положительном свете.',
    cancel: 'Отмена',
    exit: 'Выйти',
    exitHeader: 'Выйти?',
    exitText:
      'Доступ к персональным рекомендациям по питанию, тренировкам и контенту предоставляется только авторизованным пользователВы точно хотите выйти?',
    weight: 'Вес',
    height: 'Рост',
    age: 'Возраст',
    sex: 'Пол',
    target: 'Цель',
    msisdn: 'Номер телефона',
    WEIGHT_DOWN: 'снижение веса',
    WEIGHT_UP: 'набор веса',
    WEIGHT_KEEP: 'сохранение веса',
    WEIGHT_DOWN_BF: 'снижение веса. С учетом грудного вскармливания',
    WEIGHT_UP_BF: 'набор веса. С учетом грудного вскармливания',
    WEIGHT_KEEP_BF: 'сохранение веса. С учетом грудного вскармливания',
    MALE: 'мужской',
    FEMALE: 'женский',
    notEnteredPlaceholder: 'Данные не введены',
    notificationRowSeparator: ', '
  },
  menu: {
    logo: 'ЗОЖ'
  },
  promoBanner: {
    params: 'Параметры',
    program: 'Расчет программы питания',
    menu: 'Вкусное меню на каждый день',
    workout: 'Несложные тренировки',
    result: 'Отличный результат!'
  },
  diet: {
    promo: 'Улучшить форму, почувствовать уверенность и легкость можно всего за несколько простых шагов:',
    params: 'Укажите ваши параметры и желаемую цель: похудеть, набрать или удерживать вес',
    program: 'Получите индивидуальную программу питания и тренировок',
    menu: 'Питайтесь в соответствии с составленным для вас вкусным меню на каждый день',
    workout: 'Выполняйте зарядку и несложные тренировки, указанные в вашей программе',
    result: 'Наслаждайтесь отличным результатом! При желании пройтите второй круг диеты',
    paramsMobile: 'Укажите ваши параметры и желаемую цель',
    programMobile: 'Получите индивидуальную программу питания и тренировок на неделю и следуйте рекомендациям',
    resultMobile: 'Наслаждайтесь результатом или пройдите второй круг диеты',
    foodBasket: 'Продуктовая корзина',
    weekProducts: 'Продукты на неделю',
    specifyParameters: 'Указать параметры',
    changeList: 'Список замен',
    dayMenu: 'Меню на день',
    trainings: 'Тренировки',
    advices: 'Советы диетолога',
    editData: 'Редактировать данные',
    notifications: 'Нотификации',
    listProduct: 'Список продуктов',
    myParameters: 'Мои параметры',
    myProgram: 'Моя программа',
    notSpecified: 'Не указано',
    notification: {
      title: 'Напоминания',
      edit: 'Редактировать нотификацию',
      name: 'Название',
      time: 'Время',
      daily: 'Ежедневно',
      workingDays: 'Только по будням',
      howSend: 'Способ отправки напоминаний',
      sms: 'SMS',
      pushNotification: 'Push-уведомление',
      add: 'Добавить',
      error: 'Заполните все формы',
      maxAmount: '*Максимальное количество нотификаций - 5',
      editHeader: 'Редактировать нотификацию',
      createHeader: 'Добавить нотификацию',
      editInfo: 'Редактировать нотификацию',
      createInfo: 'Добавить нотификацию',
      textError: 'Заполните название верно',
      timeError: 'Заполните время верно',
      pleaseFillField: 'Пожалуйста, заполните это поле',
      addAnotherReminder: 'Добавить еще напоминание',
      onWeekdaysAt: 'По будням в',
      dailyAt: 'Ежедневно в'
    },
    changeParameters: 'Изменить параметры',
    channel: {
      header: 'Канал доставки',
      changeHeader: 'Изменить канал доставки',
      label: 'Канал доставки',
      info: 'Изменить канал сообщения'
    },
    weekendProgram: {
      header: 'Неделя завершена!',
      desc: {
        0: 'Ура! Неделя завершена. Мы надеемся, что вам удалось соблюсти все рекомендации по здоровому питанию и тренировкам.',
        1: 'Довольны ли вы результатом?'
      },
      yourGoal: 'Ваша цель',
      yourStartingWeight: 'Ваш исходный вес был<br /><strong>{{weight}} кг</strong>, ваш вес после диеты: ',
      weightAfter: 'Вес, кг',
      startNewProgram: 'Начать новую неделю',
      goToKnowledge: 'Перейти к базе знаний по ЗОЖ'
    },
      parameters: {
      title: 'Мои параметры',
      params: 'Здесь указаны ваши параметры и цель, на основании которых рассчитана программа питания и тренировок.',
      info: 'При изменении параметров и цели ваша индивидуальная программа может быть обновлена.',
      desc: 'Здесь указаны ваши параметры и цель, на основании которых рассчитана программа питания и тренировок. ' +
        'При изменении параметров и цели ваша индивидуальная программа может быть обновлена.',
      height: 'Рост, см',
      weight: 'Вес, кг',
      age: 'Возраст',
      target: 'Ваша цель',
      sex: 'Пол',
      breastFeeding: 'Грудное вскармливание',
      yes: 'Да',
      no: 'Нет',
      woman: 'Женщина',
      man: 'Мужчина',
      womanSymbol: 'Ж',
      manSymbol: 'М',
      loseWeight: 'Похудеть',
      gainWeight: 'Набрать вес',
      maintainWeight: 'Удерживать вес',
      parametersNotSpecified: {
        title: 'Параметры не указаны. ',
        desc: 'Для расчета индивидуальной программы питания и тренировок необходимо указать ваши параметры.'
      }
    },
    products: {
      desc: {
        1: 'Ознакомьтесь со списком продуктов на неделю, которые потребуются для вашей диеты.',
        2: 'Если в списке есть продукты, на которые у вас аллергия или есть противопоказания врача, смело заменяйте продукты на другие или в крайнем случае исключайте',
        3: 'Для замены подбирайте продукты с похожим <strong>гликемическим индексом и калорийностью.</strong>'
      },
      title: 'Список продуктов на неделю',
      expand: 'Развернуть',
      collapse: 'Свернуть'
    }
  },
  modalIndividualProgramSubscribe: {
    desc1: 'Худеть легко, вкусно и полезно!',
    desc2: 'Оформите подписку «Моя диета» (16+) за 10 ₽/день и получите доступ к индивидуальной программе питания и тренировок. Бонусный период 1 день – бесплатно.',
    desc3: 'Плата будет списана с номера',
    btnSubscribe: 'Подписаться',
    btnCancel: 'Отмена',
    footer: {
      desc1: 'Нажимая кнопку «Подписаться», вы подтверждаете заказ услуги «Моя диета» на условиях',
      link: 'Пользовательского соглашения.',
      desc2: 'Стоимость 10 ₽/день (с НДС). Оплата с контентного лицевого счета, а при его отсутствии — с основного лицевого счета Абонента..'

    }
  },
  individualProgram: {
    nextDay: 'Следующий день',
    setReminders: 'Установить напоминания',
    reminders: 'Напоминания',
    day: 'День',
    weekend: 'Завершить неделю'
  },
  individualProgramMenuBlock: {
    menuForDay: 'Меню на день',
    showAllTips: 'Показать все советы',
    hideAllTips: 'Скрыть все советы',
    mokData: {
      displayHeaderBreakfast: 'Завтрак c 08:00 до 10:00',
      displayHeaderSnack: ' Перекус с 11:00 до 12:00',
      displayHeaderDinner: 'Обед с 13:00 до 14:00',
      displayHeaderAfternoon: 'Полдник с 16:00 до 17:00',
      displayHeader: 'Ужин с 19:00 до 20:00',
      displayDescription: `<p><strong>332 ккал</strong>,&nbsp;<em>белки 24, жиры 24,
      углеводы 8</em>&nbsp;готовим на 2 дня - на сегодня и
      на завтра&nbsp;<strong>Внимание</strong>: граммы и количество
      указаны на один день, складываем самостоятельно.</p> <p><strong>Фриттата с
      овощами</strong></p> <blockquote> <p>Яйцо куриное&nbsp;<strong>шт. 2</strong></p>
      <p>Яйцо белок&nbsp;<strong>шт. 2</strong></p> <p>Кабачки свежие&nbsp;<strong>гр.
      50</strong></p> <p>Помидоры&nbsp;<strong>гр. 100</strong></p> <p>Кефир 2,5%&nbsp;<strong>гр.
      25</strong></p> <p>Соль&nbsp;<strong>щепотка 1</strong></p> <p>Масло оливковое&nbsp;<strong>чай.л.
      2</strong></p> </blockquote> <p>Кабачок натереть на крупной или мелкой терке, выложить
      на сухую сковородку, немного обжарить и посолить. Яйца хорошо взбить с кефиром, посолить,
      вылить сверху на кабачок, перемешать и накрыть крышкой. Помидоры нарезать на кругляшки
       и подать отдельно, полив оливковым маслом.</p>`
    }

  },
  individualProgramDietolog: {
    title: 'Советы диетолога',
    desc: `В первые дни диеты вам может быть сложно перестроиться на новый режим питания.
     Но важно помнить о вашей цели и о том, что скаждым днем будет все легче вести здоровый образ жизни.`
  },
  individualProgramWorkout: {
    title: 'Тренировки',
    training: 'Тренировка',
    desc: 'Начальные занятия будут довольно простыми, и со временем начнется наращивание темпа и нагрузки.'
  },
  modalCalcProgram: {
    loseWeight: 'Похудеть',
    toGainWeight: 'Набрать вес',
    maintainWeight: 'Удерживать вес',
    yourParametersForCalculation: 'Укажите ваши параметры для расчета дневной нормы калорий, составления программы питания и рекомендованных тренировок. ' +
      'Введенные данные будете видеть только вы.',
    yourParameters: 'Ваши параметры',
    myParameters: 'Мои параметры',
    age: 'Возраст',
    woman: 'Я женщина',
    man: 'Я мужчина',
    calculateMyProgram: 'Рассчитать мою программу',
    programDescription: `Если вы не укажете ваши параметры, вам будет предложена универсальная программа`,
    cancel: 'Отмена',
    WaitMinute: 'Минуточку',
    calcProgram: 'Рассчитываем для вас программу...',
    checkValidData: 'Проверьте правильность введенных данных',
    saveParameters: 'Сохранить параметры',
    kg: 'кг',
    sm: 'см'
  },
  subscribe: {
    FAIL: 'Активация подписки не удалась. Повторите попытку позже.',
    NO_MONEY: 'Не достаточно средств для покупки или продления подписки.',
    error: {
      fail: {
        header: 'Ошибка активации',
        text: 'Активация подписки не удалась.<br/>Повторите попытку позже.',
        close: 'Закрыть'
      },
      no_money: {
        header: 'Недостаточный баланс',
        text:
          'Недостаточно средств для списания абонентской платы. Сервис будет доступен после пополнения баланса и успешного списания абонентской платы.',
        close: 'Закрыть'
      },
      shared: {
        header: 'Ошибка'
      },
      header: 'Ошибка',
      text: 'Активация подписки не удалась. Повторите попытку позже.',
      close: 'Закрыть'
    },
    modal: {
      header: 'Подписка',
      customText: 'Для того, чтобы {{ whatToDo }}, у вас должна быть подписка на "{{ subscribeName }}" {{ price }}',
      paymentPhone: 'Плата будет списана с номера: ',
      activate: 'Подписаться',
      paymentWarn: 'Плата будет списана с номера',
      cancel: 'Отмена'
    }
  },
  calc: {
    header: 'Калькулятор калорий',
    text:
      'Сервис рассчитает дневную норму калорий в соответствии с вашей целью и текущими данными. Вы будете получать меню, продуктовую корзину и рекомендации' +
      ' по тренировкам каждые 7 дней. Данные всегда можно обновить и скорректировать рекомендации. Пока данные не введены, в разделе «Моя диета» ' +
      'отображаются усредненные рекомендации',
    height: 'Рост, см',
    weight: 'Вес, кг',
    age: 'Возраст',
    target: 'Ваша цель',
    woman: 'Женщина',
    man: 'Мужчина',
    breastFeeding: 'Учитывать грудное вскармливание',
    calc: 'Рассчитать',
    error: {
      height: 'Рост, см:',
      weight: 'Вес, кг:',
      age: 'Возраст:'
    },
    delete: 'Удалить'
  },
  main: {
    header: 'Заголовок на главной',
    preInfo: {
      headerMain: '',
      header1: '',
      text1: '',
      header2: '',
      text2: '',
      header3: '',
      text3: ''
    }
  },
  error: {
    errorWord: 'Ошибка',
    codeWrong: 'Неверный код подтверждения. <br /> Пожалуйста, попробуйте еще раз',
    timeout: 'Срок действия кода истек',
    input: 'Проверьте правильность введённых данных и повторите попытку',
    message: {
      text: 'Страница не найдена',
      text400: 'Неправильный запрос',
      text403: 'Доступ к ресурсу отклонен',
      text404: 'Страница не найдена',
      text500: 'Содержимое не найдено',
      desc: 'Что-то пошло не так. Мы уже работаем над устранением проблемы.',
      goTo: 'Перейти на',
      goIn: 'Перейти в',
      goBack: 'Вернуться назад',
      goMain: 'На главную',
      main: 'Главную страницу',
      me: 'Личный кабинет'
    },
    network: {
      header: 'Ошибка сети',
      text: 'Кажется, вы не подключены к интернету'
    },
    noMoney: {
      header: 'Недостаточный баланс',
      text:
        'Недостаточно средств для списания абонентской платы. Сервис будет доступен после пополнения баланса и успешного списания абонентской платы'
    },
    maxNotifications: {
      header: 'Добавить нотификацию',
      text: 'Заведено максимально количество нотификаций'
    },
    attributes: {
      empty: 'Пока данные не введены, в разделе «Моя диета» отображаются усредненные рекомендации'
    }
  },
  banner: {
    start: {
      title: 'Можно ли есть и худеть? Можно! Вкусное меню на каждый день, калькулятор калорий и несложные тренировки помогут прийти в форму.',
      desc: 'Заполните параметры и получите персональный план питания. Без голода со 100% результатом! Эффективное похудение. Без вреда. Без нагрузок на организм. Без голода!'
    }
  },
  game: {
    contentHeader: 'Игра',
    questionIndex: 'Вопрос {{ index }} из {{ total }}',
    yourResult: `Правильных ответов {{ correctCount }} из {{ totalCount }}.`,
    moreAbout: 'Узнайте больше о правильном питании',
    more: 'ПОДРОБНЕЕ',
    playAgain: 'Сыграть еще раз',
    gameResult: 'Результат теста'
  },
  study: {
    start: 'Начать',
    play: 'Вперед!',
    playAgain: 'Пройти еще раз',
    question: 'Вопрос {{ index }}: {{ text }}',
    result: 'Правильных ответов {{ correct }} из {{ total }}.',
    ranks: {
      5: 'Гений',
      4: 'Умница',
      3: 'Неплохо!',
      2: 'Старайся лучше',
      1: 'Плохо!',
      0: 'Плохо!'
    }
  },
  dietTargetType: {
    all: 'Все',
    down: 'Похудение',
    up: 'Набор веса',
    keep: 'Удержание веса'
  },
  subscription: {
    activate: 'Подписаться',
    cancel: 'Отмена',
    next: 'Далее'
  },
  dialog: {
    all: {
      close: 'Закрыть'
    },
    subscribe: {
      success: {
        header: 'Подписка активирована',
        text: 'Подписка «{{ name }}» успешно активирована.<br/>Лучшие книги о саморазвитии, бизнесе, психологии, ЗОЖ и многом другом теперь доступны без ограничений!',
        close: 'Далее'
      },
      no_money: {
        header: 'Недостаточный баланс',
        text: 'Недостаточно средств для списания абонентской платы. Сервис будет доступен после пополнения баланса и успешного списания абонентской платы.',
        close: 'Закрыть'
      },
      fail: {
        header: 'Ошибка активации',
        text: 'Активация подписки не удалась. Повторите попытку позже.',
        close: 'Закрыть'
      },
      not_found: {
        header: 'Ошибка',
        text: 'Не удалось получить статус подписки.<br/>Повторите попытку позже или обратитесь к администратору.',
        close: 'Закрыть'
      },
      paymentPhone: 'Плата будет списана с номера: ',
      activate: 'Подписаться',
      cancel: 'Отмена'
    },
    confirm: {
      adult: {
        header: 'Подтвердите<br/>возраст',
        text: 'Вы подтверждаете, что вам<br/>исполнилось 18 лет?',
        close: 'Отмена',
        deactivate: 'Подтвердить'
      },
      logout: {
        header: 'Выйти?',
        text: 'Неограниченный доступ к контенту предоставляется только авторизованным пользователям. <br/><br/>Вы точно хотите выйти?',
        close: 'Отмена',
        deactivate: 'Выйти'
      },
      unsubscribe: {
        header: 'Отключить подписку?',
        text: 'При отключении подписки доступ к услуге «{{name}}» будет утерян. <br/><br/>Вы точно хотите отключить подписку?',
        archive:
          'Услуга «{{name}}» находится в архиве. При отключении подписки возобновление доступа к контенту портала по текущей цене будет невозможно.' +
          ' <br/><br/>Вы точно хотите отключить подписку?',
        close: 'Отмена',
        deactivate: 'Отключить'
      },
      olympiad_abort: {
        header: 'Завершить<br/>олимпиаду?',
        text:
          'Если ты завершишь олимпиаду сейчас, ответы не сохранятся. Чтобы получить приз, нужно будет пройти олимпиаду заново.<br/><br/>Точно завершить?',
        close: 'Продолжить',
        deactivate: 'Завершить'
      },
      olympiad_prize: {
        header: 'Призы',
        text: 'Все призы, которые ты получаешь за олимпиады, доступны в Профиле.<br/><br/>Можно смотреть и играть в любое время!',
        close: 'Перейти в Профиль',
        deactivate: 'Понятно'
      }
    },
    game: {
      header: 'Как установить <br/>игру?',
      text: 'Чтобы установить игру, нужно разрешить установку приложений из неизвестных источников в настройках.',
      close: 'Закрыть'
    }
  },
  content: {
    new: 'Новое',
    subscribed: 'Подписан',
    bySubscription: 'По подписке',
    free: 'Бесплатно'
  },
  quiz: {
    contentHeader: 'Игра',
    questionIndex: 'Вопрос {{ index }} из {{ total }}',
    yourResult: `Правильных ответов: {{ correctCount }} из {{ totalCount }}.`,
    moreAbout: 'Узнайте больше о правильном питании',
    more: 'ПОДРОБНЕЕ',
    gameOver: 'Тест завершен!',
    playAgain: 'Пройти еще раз',
    close: 'Закрыть'
  },
  promo: {
    0: {
      title: 'Хотите обрести лучшую форму?'
    },
    1: {
      title: 'Хотите знать больше?'
    }
  }
};

export default RU;
