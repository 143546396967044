//#region imports
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

interface IProps {
  isNotDesktop: boolean;
  isTabletMenu: boolean;
}

export const stylesLayout = makeStyles<Theme, IProps>(theme =>
  createStyles({
    menu: {
      maxWidth: 1440,
      margin: '0 auto',
      '& a': {
        fontSize: ({ isTabletMenu }) => isTabletMenu ? 16 : 17
      }
    }
  }), { name: 'Layout' });
