//#region imports
import React from 'react';

import CardVertical from 'app/components/shared/card/vertical/card.vertical.index';
//#endregion

const ColumnCard = props => {
  const {
    index,
    style,
    padding,
    size,
    align,
    arr,
    type,
    isTransparent
  } = props;

  return (
    <div style={ {
      ...style,
      left: Number(style.left),
      width: Number(style.width) - padding
    } }>
        <CardVertical
          size={ size }
          align={ align }
          type={ type }
          { ...arr[index] }
          isTransparent={ isTransparent }
        />
    </div>
  );
};

export default ColumnCard;
