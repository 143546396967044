import { makeStyles, createStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

interface IProps {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export const stylesDietBanner = makeStyles(
  createStyles<any, IProps>({
    root: {
      border: 'none'
    },
    container: {
      marginTop: ({ isMobile }) => isMobile ? 40 : 80,
      borderRadius: 10,
      backgroundColor: configTheme.megafonColors.basic.white,
      border: `1px solid #D6D6D6`,
      boxShadow: '0px 5px 4px rgba(0, 0, 0, 0.05)',
      '& img': {
        width: ({ isTablet }) => isTablet ? 245 : 475,
        height: ({ isTablet }) => isTablet ? 347 : 'unset',
        borderRight: `1px solid ${ configTheme.colors.border.primary.light}`,
        borderRadius: ({ isMobile }) => isMobile ? '5px 5px 0 0' : '10px 0 0 10px'
      }
    },
    mobileContainer: {
      paddingTop: 45,
      maxWidth: 'calc(100vw - 40px)'
    },
    mobile: {
      border: `1px solid ${ configTheme.megafonColors.border.grey}`,
      borderRadius: 5,
      backgroundColor: configTheme.megafonColors.basic.white,
      boxShadow: '0px 5px 4px rgba(0, 0, 0, 0.05)'
    },
    imageWrapper: {
      flexBasis: '30%'
    },
    imageContainer: {
      marginRight: ({ isMobile }) => isMobile ? '0!important' : 20,
      height: '100%'
    },
    image: {
      marginRight: ({ isMobile }) => (!isMobile ? 30 : 0),
      width: '100% !important',
      height: '100% !important',
      objectFit: 'cover'
    },
    header: {
      margin: ({ isMobile, isTablet }) => isMobile ? '0 0 27px 0' : (isTablet ? '15px 0' : '28px 0 15px 0'),
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      fontSize: ({ isMobile }) => isMobile ? 21 : 36,
      color: configTheme.megafonColors.basic.black.normal
    },
    desc: {
      marginRight: ({ isMobile }) => isMobile ? '0px' : '30px',
      fontSize: ({ isMobile }) => isMobile ? 16 : 19,
      lineHeight: ({ isMobile }) => isMobile ? '20px' : '24px',
      color: configTheme.megafonColors.basic.black.normal
    },
    textBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      alignItems: ({ isMobile }) => isMobile ? 'center' : 'flex-start',
      margin: ({ isMobile }) => isMobile ? '5px 15px 0px 15px' : '0px'
    },
    textContent: ({ isMobile }) => (!isMobile ?
      {
        display: 'flex',
        flexDirection: 'column'
      }
      : {}),
    button: ({ isMobile, isTablet }) => (isMobile ?
      {
        margin: '10px 0 40px 0',
        fontSize: 16,
        '& .mfui-button__inner': {
          height: 43
        }
      }
      : {
        margin: isTablet ? '25px 0 40px 0' : '30px 0 50px 0',
        padding: '0 60px',
        fontSize: isTablet ? 17 : 19,
        '& .mfui-button__inner': {
          height: isTablet ? 43 : 60
        }
      }),
    steps: {
      position: 'relative',
      maxWidth: 780,
      margin: ({ isTablet }) => isTablet ? '0 10px 0 -10px' : '10px 20px 0 -35px',
      display: 'flex',
      justifyContent: 'space-between',
      listStyleType: 'none',
      paddingInlineStart: 0,
      '&:after': {
        content: '""',
        height: 5,
        position: 'absolute',
        top: ({ isTablet }) => isTablet ? 22 : 26,
        left: '7%',
        right: '7%',
        backgroundColor: configTheme.colors.border.primary.light
      }
    },
    step: {
      width: 'calc(100%/5)',
      display: 'flex',
      flexDirection: 'column'
    },
    stepIcon: {
      position: 'relative',
      zIndex: 2,
      width: ({ isTablet }) => isTablet ? 47 : 55,
      height: ({ isTablet }) => isTablet ? 47 : 55,
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: configTheme.megafonColors.basic.white,
      border: `1px solid ${configTheme.megafonColors.basic.green.default}`,
      borderRadius: '50%',
      '& svg': {
        width: 'auto',
        height: 'auto',
        transform: ({ isTablet }) => isTablet ? 'scale(0.9)' : 'scale(1)'
      }
    },
    stepTitle: {
      marginTop: 10,
      fontSize: ({ isTablet }) => isTablet ? 16 : 17,
      textAlign: 'center'
    }
  })
);
