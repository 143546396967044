//#region imports
import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import { isIOS } from 'mobile-device-detect';
import cx from 'classnames';
import isElement from 'lodash/isElement';
import throttle from 'lodash/throttle';
import get from 'lodash/get';
import { Button } from '@megafon/ui-core';

import { loadLibrary } from './loader/player.loader';
import './player.styles.css';
import PlayerWrapper from '../player/wrapper/player.wrapper';
import PlayerControls from '../player/controls/player.controls';
import { IPlayerProps } from './player.models';
import IconCloseBig from 'app/components/shared/icons/close/icon.close.big';
//#endregion

let isStarted = false;
let timerId: any;

class Player extends PureComponent<IPlayerProps> {
  private initialization: any;
  private player: any;
  private _userActivityTimer: any;
  private _handleUserActivity: any;

  ampRoot: any;
  videoNode: any;
  controlBar: any;
  closeButton: any;

  state = {
    viewportHeight: window.innerHeight,
    currentTime: 0,
    duration: 0,
    muted: false,
    paused: true,
    started: false,
    isFullscreen: false,
    isUserActive: false
  };

  constructor(props: any) {
    super(props);

    this.ampRoot = React.createRef();
    this.videoNode = React.createRef();
    this.controlBar = React.createRef();
    this.closeButton = React.createRef();

    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.onPaused = this.onPaused.bind(this);
    this.onEnded = this.onEnded.bind(this);

    this._handleUserActivity = throttle(() => {
      clearTimeout(this._userActivityTimer);
      if (!this.state.isUserActive) this.setState({ isUserActive: true });
      this._userActivityTimer = setTimeout(() => {
        if (this.state.isUserActive) this.setState({ isUserActive: false });
      }, 5000);
    }, 300);
  }

  componentWillUnmount() {
    this._handleUserActivity?.cancel();
    clearTimeout(this._userActivityTimer);

    this.destroyPlayer();
    this.stopTimer();

    isStarted = false;
    window.removeEventListener('resize', () => this.setState({ viewportHeight: window.innerHeight }));
  }

  componentDidMount() {
    const { skin } = this.props as IPlayerProps;
    this.initialization = loadLibrary(skin).then(() => {
      this.createPlayer();
      this.setVideo();
    });
    window.addEventListener('resize', () => this.setState({ viewportHeight: window.innerHeight }));
  }

  componentDidUpdate(prevProps: any) {
    const { src } = this.props as IPlayerProps;
    if (prevProps.src !== src) {
      this.initialization.then(() => this.setVideo());
    }
  }

  setVideo() {
    const { src } = this.props as IPlayerProps;
    this.player.src(src);
    if (isElement(this.controlBar.current)) this.player.playerElement()?.appendChild(this.controlBar.current);
    if (isElement(this.closeButton.current)) this.player.playerElement()?.appendChild(this.closeButton.current);
  }

  destroyPlayer() {
    if (this.player) {
      this.player.dispose();
    }
  }

  createPlayer() {
    const { onInstanceCreated } = this.props as IPlayerProps;
    const defaultOptions = {
      controls: true,
      logo: { enabled: false },
      preload: 'auto',
      playsInline: true
    };
    // @ts-ignore
    this.player = window.amp(this.videoNode.current, { ...defaultOptions, autoplay: this.props.isAutoPlay && !isIOS });

    this.player && this.player.addEventListener('timeupdate', this.onTimeUpdate.bind(this), false);
    this.player && this.player.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this), false);
    this.player && this.player.addEventListener('durationchange', this.onDurationChange.bind(this), false);
    this.player && this.player.addEventListener('unmute', this.onUnmuted.bind(this), false);
    this.player && this.player.addEventListener('mute', this.onMuted.bind(this), false);
    this.player && this.player.addEventListener('play', this.onPlay.bind(this), false);
    this.player && this.player.addEventListener('pause', this.onPaused.bind(this), false);
    this.player && this.player.addEventListener('start', this.onStart.bind(this), false);
    this.player && this.player.addEventListener('ended', this.onEnded.bind(this), false);

    onInstanceCreated && onInstanceCreated(this.player);
  }

  onTimeUpdate() {
    const currentTime = get(this.player, 'cache_.currentTime');
    if (!isStarted && parseFloat(currentTime.toFixed(0)) === 0) {
      isStarted = true;
      const panelLeft = document.querySelector('.amp-controlbaricons-left');
      const panelMiddle = document.querySelector('.amp-controlbaricons-middle');
      const panelRight = document.querySelector('.amp-controlbaricons-right');
      // @ts-ignore
      if (panelLeft) panelLeft.style.cssText = 'display: table-cell !important';
      // @ts-ignore
      if (panelMiddle) panelMiddle.style.cssText = 'display: table !important';
      // @ts-ignore
      if (panelRight) panelRight.style.cssText = 'display: table-cell !important';
      {
        this.props.loggingContainer?.actions.logStartVideo({
          accessToken: this.props.token,
          contentId: this.props.contendId
        });
        this.startTimer();
      }
    }
    this.setState({ currentTime: this.player?.currentTime() });
  }

  onFullscreenChange() {
    this.setState({ isFullscreen: this.player?.isFullscreen() });
  }

  onDurationChange() {
    this.setState({ duration: this.player?.duration() });
  }

  onUnmuted() {
    this.setState({ muted: false });
  }

  onMuted() {
    this.setState({ muted: true });
  }

  onPlay() {
    this.setState({ paused: false });
  }

  onPaused() {
    this.setState({ paused: true });
    this.stopTimer();
  }

  onStart() {
    this.setState({ started: true });
  }

  onEnded() {
    this.stopTimer();
  }

  startTimer() {
    timerId = setInterval(() => {
      this.props.loggingContainer?.actions.logPartVideo({
        contentViewId: this.props.loggingContainer.contentViewId,
        contentId: this.props.contendId,
        tickSeconds: '5'
      });
    }, 5000);
  }

  stopTimer() {
    clearInterval(timerId);
  }

  renderDesktop() {
    const { skin = 'amp-default', title, cornerPlayBtn, button, onClose, isAutoPlay } = this.props as IPlayerProps;
    const { currentTime, duration, muted, paused, isFullscreen } = this.state;
    const vh = this.state.viewportHeight - (90 + 107 + 24);
    return (
      <>
        <PlayerWrapper classes={ { root: 'amp-title' } } style={ { background: '#4D4D4D' } }>
          <Grid
            className="amp-title__panel"
            container
            spacing={ 0 }
            direction="row"
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid className="amp-title__logo" item>
              { title }
            </Grid>
            <Grid item>
              <Button
                theme="white"
                onClick={ onClose }
                classes={ { inner: 'amp-video__button' } }
              >
                { button?.title }
              </Button>
            </Grid>
          </Grid>
        </PlayerWrapper>

        <PlayerWrapper
          classes={ { root: 'amp-video', shape: 'amp-video__shape' } }
          style={ {
            willChange: 'height',
            maxHeight: 900,
            height: vh
          } }
        >
          <div
            style={ {
              willChange: 'height',
              maxHeight: 900,
              height: vh,
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: 1000,
              transition: 'height 0.3s'
            } }
          >
            <div className="amp-video__container" style={ { height: '100%' } }>
              <video
                ref={ this.videoNode }
                className={ cx('azuremediaplayer', `${skin}-skin`, { 'amp-big-play-centered': !cornerPlayBtn }) }
                aria-hidden="true"
                preload="auto"
                tabIndex={ 0 }
                playsInline
                autoPlay={ isAutoPlay }
              />

              <PlayerControls
                ref={ this.controlBar }
                className="amp-video__control-bar"
                style={ { transform: !isFullscreen ? 'translateY(100%)' : undefined, marginBottom: !isFullscreen ? -12 : 12 } }
                currentTime={ currentTime }
                duration={ duration }
                paused={ paused }
                muted={ muted }
                isFullscreen={ isFullscreen }
                onPlayed={ () => {
                  this.player?.play();
                } }
                onPaused={ () => {
                  this.player?.pause();
                } }
                onMuted={ (b: any) => {
                  this.player?.muted(b);
                } }
                onTimeChange={ (n: any) => {
                  this.player?.currentTime(n);
                } }
                onFullscreen={ (b: any) => {
                  if (b) this.player?.enterFullscreen();
                  else this.player?.exitFullscreen();
                } }
              />
            </div>
          </div>
        </PlayerWrapper>
      </>
    );
  }

  renderMobile() {
    const { skin = 'amp-default', cornerPlayBtn, onClose, isAutoPlay } = this.props as IPlayerProps;
    const { currentTime, duration, muted, paused, isFullscreen } = this.state;
    const vh = this.state.viewportHeight;
    return (
      <>
        <div className="amp-video" style={ { willChange: 'height', maxHeight: 900, height: vh, background: '#292929' } }>
          <div
            style={ {
              willChange: 'height',
              maxHeight: 900,
              height: vh,
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: 1000,
              transition: 'height 0.3s'
            } }
          >
            <div className="amp-video__container" style={ { height: '100%' } }>
              <video
                ref={ this.videoNode }
                className={ cx('azuremediaplayer', `${skin}-skin`, { 'amp-big-play-centered': !cornerPlayBtn }) }
                preload="auto"
                tabIndex={ 0 }
                aria-hidden="true"
                playsInline
                autoPlay={ isAutoPlay }
              />

              <PlayerWrapper ref={ this.controlBar } className="amp-video__control-bar" style={ { marginBottom: 12 } }>
                <PlayerControls
                  currentTime={ currentTime }
                  duration={ duration }
                  paused={ paused }
                  muted={ muted }
                  isFullscreen={ isFullscreen }
                  onPlayed={ () => {
                    this.player?.play();
                  } }
                  onPaused={ () => {
                    this.player?.pause();
                  } }
                  onMuted={ (b: any) => {
                    this.player?.muted(b);
                  } }
                  onTimeChange={ (n: any) => {
                    this.player?.currentTime(n);
                  } }
                  onFullscreen={ (b: any) => {
                    if (b) this.player?.enterFullscreen();
                    else this.player?.exitFullscreen();
                  } }
                />
              </PlayerWrapper>

              <div
                ref={ this.closeButton }
                className="amp-video__btn-close"
                style={ {
                  display: 'flex',
                  position: 'fixed',
                  top: '3%',
                  right: '5%',
                  height: '50px',
                  width: '50px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  cursor: 'pointer',
                  paddingTop: '3px',
                  userSelect: 'none',
                  zIndex: '1000'
                } }
                onClick={ onClose }
              >
                <IconCloseBig style={ { width: 38, height: 38 } }/>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { paused, started, isFullscreen, isUserActive } = this.state;
    const { isMobile } = this.props;
    return (
      <section
        ref={ this.ampRoot }
        className={ cx('amp-root', {
          'amp-root_mobile': isMobile,
          'amp-root_started': started,
          'amp-root_playing': !paused,
          'amp-root_fullscreen': isFullscreen,
          'amp-root_user-active': isUserActive,
          'amp-root_user-inactive': !isUserActive
        }) }
        style={ { height: this.state.viewportHeight } }
        onMouseEnter={ this._handleUserActivity }
        onMouseMove={ this._handleUserActivity }
        onMouseDown={ this._handleUserActivity }
        onMouseUp={ this._handleUserActivity }
        onTouchStart={ this._handleUserActivity }
      >
        <style>
          { `
              .vjs-control-bar.vjs-hidden {
                display: flex !important;
              }
              .amp-controlbaricons-left {
                display: none !important;
              }
              .amp-controlbaricons-right {
                display: none !important;
              }
              .amp-controlbaricons-middle {
                display: none !important;
              }
              body {
                overflow: hidden;
              }
              .amp-flush-skin .vjs-control-bar .amp-controlbaricons-middle {
                min-width: 100px;
              }
              .amp-flush-skin.vjs-user-inactive .vjs-playing .vjs-control-bar {
                opacity: 0 !important;
              }
            ` }
        </style>

        { isMobile ? this.renderMobile() : this.renderDesktop() }
      </section>
    );
  }
}

export default Player;
