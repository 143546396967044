//#region imports
import React, { FC, memo, forwardRef, useEffect } from 'react';
import classnames from 'classnames';
import map from 'lodash/map';

import { ELoadStatus } from 'app/models/shared.model';
import { ICategoryContainerProps, CategoryContainer } from 'app/containers/category.container';

import { CategoriesProps, ConnectedCategoriesProps, ECategoriesClassKey } from './categories.models';
import { useCategoriesStyles } from './categories.styles';
import Category from '../category/category.index';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

//#region Categories
export const Categories: FC<CategoriesProps> = memo(
  forwardRef<HTMLDivElement, CategoriesProps>((props, ref) => {
    const { className, style, classes, data, loading, uAgeType, onConfirm } = props;
    const { isMobile } = useBreakpoints();
    const styles = useCategoriesStyles({ isMobile });

    return (
      <div ref={ ref } className={ classnames(className, styles[ECategoriesClassKey.root]) } style={ style }>
        { map(data, category => (
          <Category key={ category.id } data={ category } loading={ loading } uAgeType={ uAgeType } onConfirm={ onConfirm } />
        )) }
      </div>
    );
  })
);
Categories.displayName = 'CategoriesMain';

Categories.defaultProps = { data: [], loading: false };
//#endregion

//#region ConnectedCategories
const ConnectedCategories: FC<ConnectedCategoriesProps> = forwardRef<typeof Categories, ConnectedCategoriesProps>((connectedProps, ref) => {
  const { containerCategory, ...props } = connectedProps;

  useEffect(() => {
    containerCategory.actions.fetchCategoryItems();
  }, []);

  return (
    <Categories
      ref={ ref }
      { ...props }
      data={ containerCategory.items }
      loading={ containerCategory.status.items === ELoadStatus.loading }
    />
  );
});
ConnectedCategories.displayName = 'ConnectedCategoriesMain';
//#endregion

export default CategoryContainer(ConnectedCategories) as typeof Categories;
