import { ThunkDispatch } from 'redux-thunk';

import { IRootState } from 'app/shared/reducers/root.reducer';
import { connect } from 'app/containers/connect';

import {
  logPosition,
  setState,
  logLogin,
  logLogout,
  logPartVideo,
  logStartVideo,
  logView,
  logDownload, logSubscriptionChange
} from 'app/entities/logging/logging.actions';

const mapStateToProps = (state: IRootState) => ({
  authenticated: state.user.data.authenticated,
  contentViewId: state.logging.contentViewId,
  state: state.logging
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, unknown, any>) => ({
  setState: state => dispatch(setState(state)),
  logPosition: state => dispatch(logPosition(state)),
  logView: state => dispatch(logView(state)),
  logSubscriptionChange: (isSubscribe, subscribeId, position) =>
    dispatch(logSubscriptionChange(isSubscribe, subscribeId, position)),
  logDownload: state => dispatch(logDownload(state)),
  logLogin: position => dispatch(logLogin(position)),
  logLogout: position => dispatch(logLogout(position)),
  logStartVideo: params => dispatch(logStartVideo(params)),
  logPartVideo: params => dispatch(logPartVideo(params))
});

export type TContainerLoggingProps = ReturnType<typeof mapStateToProps> & {
  actions: ReturnType<typeof mapDispatchToProps>;
};

export const ContainerLogging = connect<TContainerLoggingProps>('containerLogging', mapStateToProps, mapDispatchToProps);
