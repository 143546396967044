//#region imports
import React from 'react';
import { StandardProps } from '@material-ui/core';

import { EBreakPointSize } from 'app/models/model.theme';
//#endregion

export enum EBadgeType {
  free = 'free',
  paid = 'paid',
  available = 'available'
}

type TBadgeSize = Exclude<keyof typeof EBreakPointSize, 'xs' | 'xl'>;

type TBadgeClassKey = 'root' | 'icon' | 'label' | TBadgeSize;

export interface IBadgeAttributes {
  type?: keyof typeof EBadgeType;
  text?: string;
  size?: keyof typeof EBreakPointSize;
  inverted?: boolean;

  onClick?(event: React.MouseEvent<HTMLElement>): void;
}

export interface IBadgeProps extends StandardProps<IBadgeAttributes, TBadgeClassKey> {
}
