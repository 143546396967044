export enum ThemePalette {
  light = 'light',
  dark = 'dark'
}

export enum EBreakPointSize {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl'
}
