//#region imports
import React, { FC, ReactElement, forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForkRef } from '@material-ui/core';
import classnames from 'classnames';

import { EIconAlign, EIconSize, EIconColor, EIconSizeClassKey, EIconColorClassKey } from 'app/models/model.icons';
import { IconTextProps, EIconTextClassKey } from './icontext.models';
import { useIconTextStyles } from './icontext.styles';
//#endregion

export const IconText: FC<IconTextProps> = forwardRef<HTMLDivElement, IconTextProps>((props, ref) => {
  const { className, style, classes, htmlColor, color, align, size, text, iconComponent, iconOnly } = props;
  const styles = useIconTextStyles({ classes });
  const { t } = useTranslation();

  const rootRef = useRef(null);
  const handleRef = useForkRef(rootRef, ref);

  let displayIcon: ReactElement;
  const displayIconClassName: string = classnames(styles[EIconTextClassKey.icon], { [styles[EIconTextClassKey.iconOnly]]: iconOnly });
  if (typeof iconComponent === 'object' || typeof iconComponent === 'function') {
    const SvgComponent = iconComponent;
    displayIcon = (
      <span className={ displayIconClassName }>
        <SvgComponent />
      </span>
    );
  }

  return (
    <div
      ref={ handleRef }
      className={ classnames(className, styles[EIconTextClassKey.root], {
        [styles[EIconColorClassKey.colorDefault]]: !htmlColor && color === EIconColor.default,
        [styles[EIconColorClassKey.colorPrimary]]: !htmlColor && color === EIconColor.primary,
        [styles[EIconColorClassKey.colorSecondary]]: !htmlColor && color === EIconColor.secondary,
        [styles[EIconColorClassKey.colorSuccess]]: !htmlColor && color === EIconColor.success,
        [styles[EIconColorClassKey.colorError]]: !htmlColor && color === EIconColor.error,
        [styles[EIconColorClassKey.colorWhite]]: !htmlColor && color === EIconColor.white,
        [styles[EIconColorClassKey.colorDisabled]]: !htmlColor && color === EIconColor.disabled,
        [styles[EIconSizeClassKey.sizeDefault]]: size === EIconSize.default,
        [styles[EIconSizeClassKey.sizeSmall]]: size === EIconSize.small
      }) }
      style={ { color: htmlColor, ...style } }
    >
      { align === EIconAlign.left && displayIcon }
      { !iconOnly && <span className={ styles[EIconTextClassKey.text] }>{ text ?? t('components.shared.icontext.text.default') }</span> }
      { align === EIconAlign.right && displayIcon }
    </div>
  );
});

IconText.defaultProps = {
  color: EIconColor.default,
  align: EIconAlign.left,
  size: EIconSize.default,
  iconOnly: false
} as IconTextProps;
