//#region imports
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@material-ui/core';
import isFunction from 'lodash/isFunction';
import classnames from 'classnames';
import { Button } from '@megafon/ui-core';

import stylesModal from 'app/components/shared/modal/modal.styles';
import { IModalProps } from 'app/components/shared/modal/modal.models';
import Modal from 'app/components/shared/modal/modal.index';
import { ESubscribeStatus } from 'app/models/model.subscription';
//#endregion

export interface IModalButton {
  title?: string;
  type?: ESubscribeStatus;
  onClick?: () => void;
}

export interface IModalNotificationProps extends IModalProps {
  type: ESubscribeStatus;
  button: {
    cancel: IModalButton;
  };
  title?: string;
  desc?: string;
}

const ModalNotification: FC<IModalNotificationProps> = props => {
  const {
    className,
    size,
    desc,
    button,
    onClose
  } = props;

  const { t } = useTranslation();
  const styles = stylesModal({ size });

  const handleClose = (): void => {
    if (isFunction(onClose)) onClose();
  };

  return (
    <Modal { ...props }>
      <DialogContent className={ classnames(className, {
        [styles[size]]: size
      }) }>
        <div className={ styles.desc } dangerouslySetInnerHTML={ { __html: desc } }/>
      </DialogContent>
      <DialogActions className={ styles.content }>
        <div className={ styles.buttons }>
          <Button
            onClick={ handleClose }
          >
            { button?.cancel?.title || t('components.shared.modal.notification.button.cancel') }
          </Button>
        </div>
      </DialogActions>
    </Modal>
  );
};

ModalNotification.displayName = 'ModalNotification';

export default ModalNotification;
