import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconSide: FC <SvgIconProps> = props => (
  <SvgIcon width={ 19 } height={ 19 } viewBox={ '0 0 19 19' } { ...props }>
    <path d="M6.33337 17.7334L15.2 9.50006L6.33337 1.26672" strokeLinecap="square"/>
  </SvgIcon>
);

export default IconSide;
