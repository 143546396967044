import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

interface IProps {
  isTabletMenu: boolean;
}

export default makeStyles<Theme, IProps>(theme =>
    createStyles({
    root: {
      backgroundColor: configTheme.megafonColors.background.grey.dark,
      padding: ({ isTabletMenu }) => isTabletMenu ? '15px 0' : '20px 0 28px 0',
      color: configTheme.megafonColors.basic.white
    },
    company: {
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      fontSize: ({ isTabletMenu }) => isTabletMenu ? 14 : 15,
      margin: '0 0 10px',
      color: 'inherit'
    },
    terms: {
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      fontSize: ({ isTabletMenu }) => isTabletMenu ? 14 : 15,
      textDecoration: 'underline',
      color: 'inherit'
    }
  }),
  { name: 'Footer' }
);
