import { createStyles, Theme, makeStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';

interface IProps {
  isMobile?: boolean;
  isError?: boolean;
}

export const stylesModalNotifications = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      transform: 'translateZ(0)'
    },
    loading: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    paper: {
      width: ({ isMobile }) => isMobile ? 335 : 372,
      maxHeight: '100vh',
      backgroundColor: configTheme.megafonColors.basic.white,
      boxSizing: 'border-box',
      borderRadius: 5
    },
    container: {
      padding: '30px 20px 20px 20px'
    },
    header: {
      display: 'flex',
      height: ({ isMobile }) => isMobile ? 56 : 76,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
      backgroundColor: configTheme.megafonColors.basic.green.default
    },
    title: {
      fontSize: ({ isMobile }) => isMobile ? 16 : 19,
      lineHeight: ({ isMobile }) => isMobile ? '17px' : '30px',
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      color: configTheme.megafonColors.basic.white
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: configTheme.megafonColors.basic.black.normal
    },
    timeContainer: {
      width: 185,
      display: 'flex',
      justifyContent: 'space-between',
      margin: ({ isMobile }) => isMobile ? '20px 0 20px 0' : '20px 0 30px 0',
      '& .MuiFormHelperText-root.Mui-error': {
        marginLeft: -60
      }
    },
    timeInput: {
      width: 48
    },
    timeLabel: {
      marginTop: 12,
      fontSize: ({ isMobile }) => isMobile ? 14 : 17
    },
    wrapperRadioGroup: {
      display: 'flex',
      flexDirection: 'row',
      margin: 0,
      '& .MuiTypography-body1': {
        fontFamily: 'inherit'
      }
    },
    radioTitle: {
      marginTop: 30,
      fontSize: ({ isMobile }) => isMobile ? 14 : 17
    },
    containerRadio: {
      position: 'relative',
      width: ({ isMobile }) => isMobile ? 147 : 165,
      height: ({ isMobile }) => isMobile ? 30 : 35,
      margin: 0,
      '& .MuiFormControlLabel-label': {
        position: 'absolute',
        left: 0,
        right: 0,
        fontSize: ({ isMobile }) => isMobile ? 12 : 17,
        textAlign: 'center',
        ...configTheme.fontFamily.MegaFonGraphikLCRegular
      }
    },
    buttonsSetReminders: {
      height: 100
    },
    buttons: {
      margin: ({ isMobile }) => isMobile ? '30px 0 20px 0' : '35px 0 30px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& button:nth-child(2n)': { marginTop: 20 },
      '& .mfui-button__inner': {
        height: 43,
        fontSize: ({ isMobile }) => isMobile ? 16 : 17
      },
      '& .mfui-button__text': {
        marginBottom: ({ isMobile }) => isMobile ? 5 : 2
      }
    },
    notificationTime: {
      marginTop: 5,
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      color: configTheme.colors.text.dark.neutral
    },
    actionsContainer: {
      display: 'flex',
      margin: ({ isMobile }) => isMobile ? '5px 0 15px 0' : '10px 0 30px 0'
    },
    actionsTextButton: {
      borderBottom: `1px dashed ${configTheme.megafonColors.basic.purple}`
    },
    actionsItem: {
      marginRight: 25,
      display: 'flex',
      alignItems: 'center',
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      color: configTheme.megafonColors.basic.purple,
      cursor: 'pointer'
    },
    actionsIcon: {
      width: 15,
      height: 15,
      marginRight: 5
    },
    actionsIconBig: {
      width: 20,
      height: 20,
      marginRight: 5
    }
  })
);
