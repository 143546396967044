import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconFree: FC <SvgIconProps> = props => (
  <SvgIcon width="24" height="23" viewBox={ '0 0 24 23' } { ...props }>
    <path d="M21.4286 11.5H2.57143M21.4286 11.5C22.2964 11.5 23 10.8284 23 10V8.5C23 7.67157 22.2964 7
      21.4286 7H2.57143C1.70355 7 1 7.67157 1 8.5V10C1 10.8284 1.70355 11.5 2.57143 11.5M21.4286 11.5V19C21.4286
      20.6569 20.0215 22 18.2857 22H5.71429C3.97853 22 2.57143 20.6569 2.57143 19L2.57143 11.5M12 7V5.5M12
      7H6.83673C5.34895 7 4.14286 5.84873 4.14286 4.42857V4C4.14286 2.34315 5.54996 1 7.28571 1C9.88934 1 12 3.01472
      12 5.5M12 7H17.1633C18.6511 7 19.8571 5.84873 19.8571 4.42857V4C19.8571 2.34315 18.45 1 16.7143 1C14.1107 1 12
      3.01472 12 5.5M12 7V22"
    />
  </SvgIcon>
);

export default IconFree;
