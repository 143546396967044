//#region imports
import React, { FC } from 'react';
import {
  TCardVerticalProps
} from './card.vertical.models';
import stylesCardVertical from './card.vertical.styles';
import CardVerticalCentral from './central/card.vertical.central.index';
import CardVerticalLeft from './left/card.vertical.left.index';
import CardVerticalPicture from './picture/card.vertical.picture.index';
import CardVerticalArticle from 'app/components/shared/card/vertical/article/card.vertical.article.index';
//#endregion

const CardVertical: FC<TCardVerticalProps> = ({ align, type, ...rest }) => {

  const styles = stylesCardVertical();

  const renderCard = () => {
    if (type === 'ARTICLE') return <CardVerticalArticle { ...rest } />;
    if (align === 'left') return <CardVerticalLeft { ...rest } />;
    if (align === 'central') return <CardVerticalCentral { ...rest } />;
    return <CardVerticalPicture { ...rest } />;
  };

  return (
    <div className={ styles.root }>
      { renderCard() }
    </div>
  );
};

CardVertical.displayName = 'CardVertical';

export default CardVertical;
