import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';

import MobileToolbar from './toolbar/mobile.toolbar.index';
import MenuMobileModal from './modal/menu.modal';

import { IMenuItem } from 'app/models/model.menu';

export interface IMenuMobileProps {
  items?: IMenuItem[];
  logo: ReactNode;
  toolbar: ReactNode;
  logoRedirectUrl?: string;
  toggleMenuCallback?: (toggleMenu?: Function | SyntheticEvent) => void;
}

const MenuMobile = (props: IMenuMobileProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { toolbar, logo, items } = props;

  const toggleMenu = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    props.toggleMenuCallback && props.toggleMenuCallback(toggleMenu);
  }, [isModalOpen]);

  if (isModalOpen) {
    return (
      <MenuMobileModal
        toggleMenuCallback={ toggleMenu }
        items={ items }
        logo={ logo }
        toggleMenu={ toggleMenu }
      />
    );
  }

  return (
    <nav>
      <MobileToolbar
        toggleMenuCallback={ toggleMenu }
        toolbar={ toolbar }
        logo={ logo }
        logoRedirectUrl={ props.logoRedirectUrl }
      />
    </nav>
  );
};

MenuMobile.displayName = 'MenuMobile';

export default MenuMobile;
