//#region imports
import { FC, ReactNode, SVGProps } from 'react';
import { PropTypes, StandardProps, SvgIcon } from '@material-ui/core';

import { IconAlign, IconSize, IconColor, IconColorClasses } from 'app/models/model.icons';
//#endregion

export type IconTextAlign = Extract<PropTypes.Alignment, IconAlign>;

export type IconTextColor = Exclude<IconColor, 'inherit'>;

export type SVGR = FC<SVGProps<SVGSVGElement>>;

export const EIconTextClassKey = {
  root: 'root',
  icon: 'icon',
  text: 'text',
  iconOnly: 'iconOnly'
} as const;

// const assertions (TS 3.4+)
export type IconTextClasses = typeof EIconTextClassKey[keyof typeof EIconTextClassKey];

export interface IIconTextProps {
  htmlColor?: string;
  color?: IconTextColor;
  /**
   * Выводимый текст.
   */
  text?: ReactNode;
  /**
   * Иконка unicode.
   */
  iconComponent?: typeof SvgIcon | SVGR;
  /**
   * Показывать только иконку.
   */
  iconOnly?: boolean;
  align?: IconTextAlign;
  size?: IconSize;
}

export type IconTextProps = StandardProps<IIconTextProps, IconTextClasses | IconColorClasses>;

export type IconTextStylesProps = Pick<IconTextProps, 'classes'>;
