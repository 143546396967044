export const enum EProtocol {
  HTTP = 'http',
  HTTPS = 'https'
}

export enum EMenuItemType {
  ANCHOR = 'ANCHOR',
  URL = 'URL'
}

export enum EDialogConfirmType {
  ADULT = 'ADULT',
  LOGOUT = 'LOGOUT',
  UNSUBSCRIBE = 'UNSUBSCRIBE'
}
