//#region imports
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StandardProps } from '@material-ui/core';
import { ArrowDownwardOutlined, ArrowUpwardOutlined, CheckOutlined } from '@material-ui/icons';

import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Paragraph } from '@megafon/ui-core';

import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import {
  ContainerDietIndividualProgram,
  IContainerDietIndividualProgramProps
} from 'app/containers/container.diet.program';

import IconStar from 'app/components/shared/icons/star/icon.star';

import { ELoadStatus } from 'app/models/shared.model';
import { ESex, ETarget, IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';
import { ModalParameters } from 'app/pages/desktop/diet/components/parameters/modal/modal.parameters';
import { stylesParameters } from 'app/pages/shared/diet/parameters/parameters.styles';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

interface IParameters {
  containerUser?: IUserContainerProps;
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  isNoIcon: boolean;
  isNoCard: boolean;
  isNoParametersInfo: boolean;
  className?: string;
}

export const EParametersClassKey = {
  wrapper: 'wrapper',
  table: 'table',
  title: 'title',
  desc: 'desc',
  icon: 'icon',
  actionButton: 'actionButton'
} as const;

export type ParametersClasses = typeof EParametersClassKey[keyof typeof EParametersClassKey];
export type ParametersProps = StandardProps<IParameters, ParametersClasses>;

export interface IParametersStylesProps {
  classes?: ParametersProps['classes'];
  mobile?: boolean;
  card?: boolean;
  tablet?: boolean;
}

const ParametersIndex = memo<ParametersProps>(props => {
  const { className = '' } = props;
  const { t } = useTranslation();
  const { isNoIcon, isNoCard, isNoParametersInfo, classes } = props;
  const { data: { authenticated } } = props.containerUser;
  const {
    actions: { fetchDietItem, saveDietItem },
    itemUserAttributes,
    progressStatus
  } = props.containerDietIndividualProgram;
  const { isMobile, isNotDesktop } = useBreakpoints();
  const styles = stylesParameters({ classes, mobile: isMobile, tablet: isNotDesktop, card: !isNoCard });
  const [isShowModal, setShowModal] = useState(false);

  useEffect(() => {
    if (authenticated && progressStatus === ELoadStatus.not_ready) {
      fetchDietItem();
    }
  }, [authenticated, progressStatus]);

  enum EHeader {
    HEIGHT = 'height',
    WEIGHT = 'weight',
    AGE = 'age',
    SEX = 'sex',
    BREAST_FEEDING = 'breastFeeding',
    TARGET = 'target'
  }

  const renderMobileValue = parameter => {
    switch (parameter) {
      case 'sex':
        return itemUserAttributes[parameter] === ESex.FEMALE ? t('diet.parameters.woman') : t('diet.parameters.man');
      case 'target':
        let text = t('diet.parameters.maintainWeight');
        if (itemUserAttributes[parameter] === ETarget.WEIGHT_DOWN) {
          text = t('diet.parameters.loseWeight');
        } else if (itemUserAttributes[parameter] === ETarget.WEIGHT_UP) {
          text = t('diet.parameters.gainWeight');
        }
        return text;
      case 'breastFeeding':
        return t(`diet.parameters.${itemUserAttributes[parameter] ? 'yes' : 'no'}`);
      default:
        return itemUserAttributes[parameter];
    }
  };

  const renderDesktopValue = parameter => {
    switch (parameter) {
      case 'sex':
        return itemUserAttributes[parameter] === ESex.FEMALE ? t('diet.parameters.womanSymbol') : t('diet.parameters.manSymbol');
      case 'target':
        let icon = <CheckOutlined />;
        if (itemUserAttributes[parameter] === ETarget.WEIGHT_DOWN) {
          icon = <ArrowDownwardOutlined />;
        } else if (itemUserAttributes[parameter] === ETarget.WEIGHT_UP) {
          icon = <ArrowUpwardOutlined />;
        }
        return icon;
      case 'breastFeeding':
        return t(`diet.parameters.${itemUserAttributes[parameter] ? 'yes' : 'no'}`);
      default:
        return itemUserAttributes[parameter];
    }
  };

  const isNullishItemUserAttributes = useMemo(() => {
    return (
      isEmpty(itemUserAttributes) ||
      itemUserAttributes &&
      Object.values(itemUserAttributes).every(value => {
        return value === null || typeof value !== 'number';
      })
    );
  }, [itemUserAttributes]);

  const renderMobileParameters = useMemo(() => (
    <>
    <div className={ `${styles.wrapper} ${className}` }>
    <TableContainer component={ Paper } className={ styles.table }>
      <div className={ styles.title }>
        { !isNoIcon && <IconStar className={ styles.icon } /> }
        { t('diet.parameters.title') }
      </div>
        { progressStatus === ELoadStatus.ready && (
          <>
            {
              isNullishItemUserAttributes ? (
                <>
                  <Paragraph className={ styles.text }>
                    { t('diet.parameters.parametersNotSpecified.title') }<br />
                    { t('diet.parameters.parametersNotSpecified.desc') }
                  </Paragraph>
                </>
              ) : (
                <>
                  <Table size="small">
                    <TableBody>
                      { Object.keys(EHeader).map((parameter, index) =>
                        !(EHeader[parameter] === 'breastFeeding' && itemUserAttributes[EHeader.SEX] === ESex.MALE) ? (
                        <TableRow key={ index }>
                          <TableCell scope="parameter">{ t(`diet.parameters.${EHeader[parameter]}`) }</TableCell>
                          <TableCell align="right">{ itemUserAttributes[EHeader[parameter]] !== null ? renderMobileValue(EHeader[parameter]) : t('diet.notSpecified') }</TableCell>
                        </TableRow>
                      ) : null) }
                    </TableBody>
                  </Table>
                  { !isNoParametersInfo && (
                    <Paragraph className={ styles.desc }>
                      { t('diet.parameters.info') }
                    </Paragraph>
                  ) }
                </>
              )
            }
          </>
        ) }
      </TableContainer>
    </div>
      <Button theme="purple" className={ styles.actionButton } onClick={ () => setShowModal(true) } fullWidth={ isMobile }>
        { isNullishItemUserAttributes ? t('diet.specifyParameters') : t('diet.changeParameters') }
      </Button>
      </>
    ), [isMobile, itemUserAttributes, isNullishItemUserAttributes, progressStatus]);

  const renderDesktopParameters = useMemo(() => (
    <div className={ `${styles.wrapper} ${className}` }>
      <div className={ styles.title }>
        { !isNoIcon && <IconStar className={ styles.icon } /> }
        { t('diet.parameters.title') }
      </div>
      { progressStatus === ELoadStatus.ready && (
        <div>
          {
            isNullishItemUserAttributes ? (
              <>
                <Paragraph className={ styles.text }>
                  { t('diet.parameters.parametersNotSpecified.title') }
                  { t('diet.parameters.parametersNotSpecified.desc') }
                </Paragraph>
              </>
            ) : (
              <>
                <ul className={ styles.list }>
                  { Object.keys(EHeader).map((parameter, index) =>
                    !(EHeader[parameter] === 'breastFeeding' && itemUserAttributes[EHeader.SEX] === ESex.MALE) ? (
                    <li className={ styles.item } key={ `${ parameter } ${ index }` }>
                      <span className={ styles.itemValue }>
                        { itemUserAttributes[EHeader[parameter]] !== null ? renderDesktopValue(EHeader[parameter]) : '?' }
                      </span>
                      <span className={ styles.itemTitle }>{ t(`diet.parameters.${EHeader[parameter]}`) }</span>
                    </li>
                  ) : null) }
                </ul>
                { !isNoParametersInfo && (
                  <Paragraph className={ styles.desc }>
                    { t('diet.parameters.info') }
                  </Paragraph>
                ) }
              </>
            )
          }
        </div>
      ) }
      <Button
        theme="purple"
        className={ styles.actionButton }
        onClick={ () => setShowModal(true) }
      >
        { isNullishItemUserAttributes ? t('diet.specifyParameters') : t('diet.changeParameters') }
      </Button>
    </div>
  ), [isMobile, itemUserAttributes, isNullishItemUserAttributes, progressStatus]);

  const handleSave = (data: IUserAttributes) => {
    saveDietItem(data);
  };

  return (
    <>
      { isMobile ? renderMobileParameters : renderDesktopParameters }
      { isShowModal && (
        <ModalParameters
          isModalParameters={ isShowModal }
          setModalParameters={ setShowModal }
          propsState={ itemUserAttributes }
          save={ handleSave }
          loading={ progressStatus }
        />
      ) }
    </>
  );
});
export default flow([ContainerUser, ContainerDietIndividualProgram])(ParametersIndex);
