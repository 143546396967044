import { ROUTE_ACTIONS } from './route.actions';
import { IRouteInitialState } from './route.model';

const initialState: IRouteInitialState = {
  route: null
};

export type TRouteState = Readonly<typeof initialState>;

export default (state = initialState, action): TRouteState => {
  switch (action.type) {
    case ROUTE_ACTIONS.UPDATE_ROUTE:
      return {
        route: action.payload
      };

    default:
      return state;
  }
};
