import { createStyles, makeStyles } from '@material-ui/core/styles';
import { configTheme } from 'app/configs/theme/config.theme';

interface IProps {
  isMobile?: boolean;
  isNotDesktop?: boolean;
}

export default makeStyles<{}, IProps>(
  createStyles({
    root: {
      minHeight: ({ isMobile }) => isMobile ? 'calc(100vh - 315px)' : 'unset'
    },
    wrapper: {
      maxWidth: 'unset',
      margin: ({ isMobile }) => isMobile ? '30px auto' : '50px auto',
      padding: ({ isMobile, isNotDesktop }) => isNotDesktop ? '30px 20px' : '40px 50px 50px 50px',
      backgroundColor: configTheme.megafonColors.basic.white
    },
    item: {
      '&:last-child': {
        marginBottom: 0,
        marginTop: 0
      },
      marginTop: 36,
      marginBottom: 28
    },
    lower: { marginTop: 'auto' },
    exitButton: {
      cursor: 'pointer',
      padding: '0 75px',
      margin: ({ isMobile }) => isMobile ? '10px auto 50px auto' : '0px auto 90px auto',
      fontSize: ({ isMobile }) => isMobile ? 16 : 19,
      maxWidth: ({ isMobile }) => isMobile ? 290 : 'unset',
      '& .mfui-button__inner': {
        height: ({ isMobile }) => isMobile ? 43 : 60
      }
    },

    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },

    header: {
      margin: ({ isMobile }) => isMobile ? '0 0 15px 0' : '0 0 30px 0',
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      fontSize: ({ isMobile }) => isMobile ? 24 : 36,
      fontWeight: 600,
      color: configTheme.megafonColors.basic.black.normal
    },

    data: {
      display: 'flex',
      flexFlow: 'row nowrap',
      fontSize: ({ isMobile }) => isMobile ? 16 : 19,
      color: configTheme.megafonColors.basic.black.normal,
      lineHeight: '22px',
      '& span': {
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
        lineHeight: '22px'
      },
      '& b': {
        fontWeight: 'normal'
      }
    },

    prizeItem: {},

    mediaPlayIcon: {
      width: 45,
      height: 50,
      paddingLeft: 5,
      borderRadius: 50,
      fontSize: 22,
      lineHeight: '60px',
      textAlign: 'center',
      color: configTheme.megafonColors.basic.black.normal,
      background: configTheme.colors.background.light.neutral
    },

    primarySection: {
      margin: ({ isMobile }) => isMobile ? '40px 0 10px 0' : '70px 0 15px 0'
    },
    secondarySection: { },
    floorSection: {
      display: 'flex',
      justifyContent: 'center'
    },

    parametersWrapper: {
      maxWidth: 1340
    },
    parametersTable: {
      padding: 0
    },
    parametersTitle: {
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      fontSize: ({ isMobile }) => isMobile ? 24 : 36
    },
    parametersBtn: {
      margin: ({ isMobile }) => isMobile ? '30px auto 20px auto' : '40px 0 0 0',
      fontSize: ({ isMobile }) => isMobile ? 16 : 19,
      '& .mfui-button__inner': {
        height: ({ isMobile }) => isMobile ? 43 : 60
      }
    },

    subscriptions: {
      maxWidth: 1340,
      margin: '0 auto',
      paddingBottom: ({ isMobile }) => isMobile ? 45 : 0
    },
    subscriptionMobile: {
      '&:not(:last-child)': {
        marginBottom: 30
      }
    },
    subscriptionContainer: {
      margin: '0 auto'
    },
    subscriptionDesktop: {
      width: 392,
      backgroundColor: configTheme.colors.background.light.default
    }

  }), { name: 'Profile' }
);
