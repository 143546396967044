//#region imports

import { connect } from 'app/containers/connect';
import {
  getDietIndividualProgramDayById,
  getDietIndividualProgram,
  getDietStatus,
  fetchDietItem,
  saveDietItem,
  postNextWeek
} from 'app/entities/diet/program/diet.program.actions';
import { IDietIndividualProgramState, IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';

import { IRootState } from 'app/shared/reducers/root.reducer';

import { ThunkDispatch } from 'redux-thunk';

//#endregion

export type IContainerDietIndividualProgramProps = IDietIndividualProgramState & {
  actions: {
    getDietIndividualProgram: () => void,
    getDietIndividualProgramDayById: (id: number) => void,
    getDietStatus: () => void,
    fetchDietItem: () => void
    saveDietItem: (props: IUserAttributes) => void,
    postNextWeek: (data) => void
  };
};

const mapStateToProps = (state: IRootState): IDietIndividualProgramState => state.dietIndividualProgram;
const mapDispatchToProps = (dispatch: ThunkDispatch<IDietIndividualProgramState, undefined, any>): IContainerDietIndividualProgramProps['actions'] => ({
  getDietIndividualProgram: () => dispatch(getDietIndividualProgram()),
  getDietIndividualProgramDayById: id => dispatch(getDietIndividualProgramDayById(id)),
  getDietStatus: () => dispatch(getDietStatus()),
  fetchDietItem: () => dispatch(fetchDietItem()),
  saveDietItem: props => dispatch(saveDietItem(props)),
  postNextWeek: props => dispatch(postNextWeek(props))
});

export const ContainerDietIndividualProgram = connect<IContainerDietIndividualProgramProps>('containerDietIndividualProgram', mapStateToProps, mapDispatchToProps);
