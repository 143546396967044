import { makeStyles, createStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

interface IProps {
  isMobile?: boolean;
  isNotDesktop?: boolean;
}

export const stylesDesktopDiet = makeStyles<{}, IProps>(
  createStyles({
    root: {
      margin: ({ isMobile }) => isMobile ? '25px auto 45px auto' : '45px auto 70px auto',
      backgroundColor: configTheme.megafonColors.basic.white,
      border: `1px solid ${configTheme.megafonColors.border.grey}`,
      boxShadow: '0px 5px 4px rgba(0, 0, 0, 0.05)',
      borderRadius: 10
    },
    title: {
      margin: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? '0 20px 20px 20px' : '25px 30px') : '0 40px 20px 40px',
      fontSize: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 16 : 19) : 24,
      lineHeight: ({ isNotDesktop }) => isNotDesktop ? '23px' : '30px',
      color: configTheme.megafonColors.basic.black.normal,
      ...configTheme.fontFamily.MegaFonGraphikLCRegular
    },
    banner: {
      padding: ({ isMobile }) => isMobile ? 15 : '40px 40px 30px',
      '& img': {
        width: '100%',
        height: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 209 : 262) : 416,
        objectFit: 'cover',
        borderRadius: 4
      }
    },
    button: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      margin: ({ isMobile }) => isMobile ? '30px 0 50px 0' : '10px 0 55px 0',
      '& .mfui-button__inner': {
        height: ({ isMobile }) => isMobile ? 43 : 60,
        fontSize: ({ isMobile }) => isMobile ? 16 : 19,
        padding: ({ isMobile }) => isMobile ? '0 5px' : '0 35px',
        ...configTheme.fontFamily.MegaFonGraphikLCSemibold
      }
    },
    steps: {
      margin: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? '0 20px' : '0 30px') : '0 40px',
      listStyleType: 'none',
      paddingInlineStart: 0
    },
    step: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: ({ isNotDesktop, isMobile }) => isNotDesktop ? 20 : 32
    },
    stepIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: ({ isMobile }) => isMobile ? 47 : 55,
      height: ({ isMobile }) => isMobile ? 47 : 55,
      backgroundColor: configTheme.megafonColors.basic.white,
      border: `1px solid ${configTheme.megafonColors.basic.green.default}`,
      borderRadius: '50%',
      '& svg': {
        width: 'auto',
        height: 'auto'
      }
    },
    stepNumber: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 37,
      height: 37,
      borderRadius: '50%',
      backgroundColor: configTheme.megafonColors.basic.green.default,
      color: configTheme.megafonColors.basic.white,
      fontSize: 19,
      lineHeight: 30
    },
    stepTitle: {
      marginLeft: ({ isNotDesktop }) => isNotDesktop ? 20 : 25,
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 16 : 19,
      color: configTheme.megafonColors.basic.black.normal
    }
  })
);
