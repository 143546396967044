import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconUser: FC<SvgIconProps> = props => (
  <SvgIcon width="28" height="28" viewBox="0 0 30 30" { ...props }>
    <path d="M6.53335 25.2667V26.2667H8.53335V25.2667H6.53335ZM21.4667 25.2667V26.2667H23.4667V25.2667H21.4667ZM8.53335 25.2667V24.3331H6.53335V25.2667H8.53335ZM13.1333
    19.7333H16.8667V17.7333H13.1333V19.7333ZM21.4667 24.3331V25.2667H23.4667V24.3331H21.4667ZM16.8667 19.7333C19.4073 19.7333 21.4667 21.7926 21.4667 24.3331H23.4667C23.4667
    20.6879 20.5117 17.7333 16.8667 17.7333V19.7333ZM8.53335 24.3331C8.53335 21.7926 10.5928 19.7333 13.1333 19.7333V17.7333C9.48833 17.7333 6.53335 20.6879 6.53335
    24.3331H8.53335ZM15 6.53333C12.3859 6.53333 10.2667 8.65252 10.2667 11.2667H12.2667C12.2667 9.75709 13.4904 8.53333 15 8.53333V6.53333ZM19.7333 11.2667C19.7333
    8.65252 17.6142 6.53333 15 6.53333V8.53333C16.5096 8.53333 17.7333 9.75709 17.7333 11.2667H19.7333ZM15 16C17.6142 16 19.7333 13.8808 19.7333 11.2667H17.7333C17.7333
    12.7762 16.5096 14 15 14V16ZM15 14C13.4904 14 12.2667 12.7762 12.2667 11.2667H10.2667C10.2667 13.8808 12.3859 16 15 16V14ZM15 27.0667C8.33578 27.0667 2.93335 21.6642
    2.93335 15H0.93335C0.93335 22.7688 7.23121 29.0667 15 29.0667V27.0667ZM27.0667 15C27.0667 21.6642 21.6643 27.0667 15 27.0667V29.0667C22.7688 29.0667 29.0667 22.7688
    29.0667 15H27.0667ZM15 2.93333C21.6643 2.93333 27.0667 8.33576 27.0667 15H29.0667C29.0667 7.2312 22.7688 0.933334 15 0.933334V2.93333ZM15 0.933334C7.23121 0.933334
    0.93335 7.2312 0.93335 15H2.93335C2.93335 8.33576 8.33578 2.93333 15 2.93333V0.933334Z"/>
  </SvgIcon>
);

export default IconUser;
