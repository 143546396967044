import { IRootState } from 'app/shared/reducers/root.reducer';
import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';
import { TUserState } from 'app/entities/user/user.reducer';
import {
  IUserActions,
  checkUser,
  request,
  confirm,
  toggleModalLogin,
  toggleModalLogout,
  resetFields,
  IModalLoginProps
} from 'app/entities/user/user.actions';

export type IUserContainerProps = TUserState & {
  actions: IUserActions;
};

const mapStateToProps = (state: IRootState) => state.user;

const mapDispatchToProps = (dispatch: Dispatch): IUserContainerProps['actions'] => ({
  confirm: data => dispatch(confirm(data)),
  request: data => dispatch(request(data)),
  checkUser: () => dispatch(checkUser()),
  resetFields: data => dispatch(resetFields(data)),
  toggleModalLogin: (data?: IModalLoginProps) => dispatch(toggleModalLogin(data)),
  toggleModalLogout: () => dispatch(toggleModalLogout())
});

export const ContainerUser = connect<IUserContainerProps>('containerUser', mapStateToProps, mapDispatchToProps);
