import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

export default makeStyles<Theme, { isTabletMenu?: boolean }>(theme =>
  createStyles({
    root: {
      backgroundColor: configTheme.megafonColors.basic.green.default,
      width: '100%',
      maxWidth: 'unset'
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: ({ isTabletMenu }) => isTabletMenu ? 'unset' : 1340,
      height: ({ isTabletMenu }) => isTabletMenu ? 42 : 50,
      padding: ({ isTabletMenu }) => isTabletMenu ? '0 20px' : '0 50px',
      margin: '0 auto',
      '& >:first-child': {
        flex: '0 0 calc( 100% - 35px )',
        width: 'calc( 100% - 35px )',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: configTheme.megafonColors.basic.white,
        fontSize: ({ isTabletMenu }) => isTabletMenu ? 19 : 24,
        ...configTheme.fontFamily.MegaFonGraphikLCRegular,
        fontWeight: 400
      }
    },
    button: {
      cursor: 'pointer',
      color: configTheme.megafonColors.basic.black.normal,
      borderRadius: '50%'
    },
    icon: {
      width: 20,
      height: 20
    }
  }),
  { name: 'HeadTitle' }
);
