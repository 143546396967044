import { makeStyles } from '@material-ui/core/styles';
import { configTheme } from 'app/configs/theme/config.theme';

export default makeStyles(theme => ({
  root: {
    display: 'block',
    overflowY: 'auto',
    flex: '1 0',
    padding: '0 20px 20px',
    margin: 0,
    fontSize: 16,
    lineHeight: 1
  },
  listItem: {
    listStyle: 'none',
    display: 'flex',
    ...configTheme.fontFamily.MegaFonGraphikLCSemibold
  },
  listItemLink: {
    padding: '12px 0',
    letterSpacing: '-0.3px',
    width: '100%',
    color: configTheme.megafonColors.basic.black.normal,
    ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
    textDecoration: 'none',
    '&.exact': {
      color: configTheme.megafonColors.basic.green.default
    }
  }
}));
