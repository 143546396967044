import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { BASENAME } from 'app/configs/const';
import { Dispatch } from 'redux';

export const VIDEO_ACTIONS = {
  FETCH: 'VIDEO::FETCH',
  RESET: 'VIDEO::RESET'
};

export const fetchVideoItem = (contentId: number | string) => async (dispatch: Dispatch) => {
  // console.log('FETCH VIDEO ITEM');
  const computedPath = `get?contentId=${contentId}`;
  dispatch({ type: REQUEST(VIDEO_ACTIONS.FETCH) });
  try {
    const result = await axios.get(`${BASENAME}/api/content/${computedPath}`);
    // console.log('RESULT -> ', result);
    if (result.data.sectionDetails.distribution === 'DRM') {
      const computedTokenPath = `token?contentId=${contentId}`;
      const tokenResult = await axios.get(`${BASENAME}/api/drm/${computedTokenPath}`);
      // console.log('TOKEN -> ', tokenResult);
      dispatch({
        type: SUCCESS(VIDEO_ACTIONS.FETCH),
        payload: {
          src: result.data.content.url,
          token: tokenResult.data.token
        }
      });
      return;
    }
    dispatch({
      type: SUCCESS(VIDEO_ACTIONS.FETCH),
      payload: {
        src: result.data.content.url
      }
    });
  } catch (e) {
    dispatch({
      type: FAILURE(VIDEO_ACTIONS.FETCH),
      payload: e
    });
    return;
  }
};

export const resetVideoItem = () => ({
  type: VIDEO_ACTIONS.RESET
});
