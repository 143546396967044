//#region imports
import React from 'react';
import { createStyles, withStyles, Slider } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

export default withStyles(
  createStyles({
    root: {
      boxSizing: 'border-box',
      height: 6,
      padding: 0,
      color: configTheme.megafonColors.background.light
    },

    thumb: {
      height: 6,
      width: 6,
      marginTop: 0,
      marginLeft: -3,
      border: '0 none',
      willChange: 'width, left',
      transition: 'all 0.3s linear',
      '&:focus, &:hover, &$active': { boxShadow: 'none' }
    },

    active: {},

    track: {
      height: 6,
      borderRadius: 6,
      willChange: 'width, left',
      transition: 'all 0.3s linear'
    },

    rail: {
      height: 6,
      borderRadius: 6,
      color: configTheme.megafonColors.basic.black.neutral,
      opacity: 1
    }
  }),
  { name: 'PlayerControlsSlider' }
)(Slider);
