//#region imports
import React, { FC, useState, LegacyRef, useRef, useEffect } from 'react';
import { ButtonBase } from '@material-ui/core';
import { FiberManualRecord, RadioButtonUnchecked } from '@material-ui/icons';
import { FixedSizeList } from 'react-window';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import classnames from 'classnames';

import { TListHorizontalProps } from './list.horizontal.models';
import stylesListHorizontal from './list.horizontal.styles';
import ListHorizontalScroll from './list.horizontal.scroll';
//#endregion

export const getItemsWidth = (cardSize, cardAlign) => {
  if (cardAlign === 'central') {
    switch (cardSize) {
      case 'xxs': return 150;
      case 'xs': return 150;
      case 'sm': return 160;
      case 'md': return 214;
      case 'lg': return 244;
      case 'xl': return 264;
      default: return 214;
    }
  }
  if (cardAlign === 'left') {
    switch (cardSize) {
      case 'xxs': return 278;
      case 'xs': return 333;
      case 'sm': return 362;
      case 'md': return 424;
      case 'lg': return 545;
      case 'xl': return 742;
      default: return 424;
    }
  }
  if (cardAlign === 'picture') {
    switch (cardSize) {
      case 'xs': return 153;
      default: return 390;
    }
  }
};

const ListHorizontal: FC<TListHorizontalProps> = props => {
  const {
    className,
    size,
    cardAlign,
    cardsPadding,
    listItems,
    loadMore,
    canLoadMore,
    isTransparent
  } = props;

  const styles = stylesListHorizontal({ cardAlign, size, isTransparent });

  const [activeBtn, setActiveBtn] = useState<number>(0);
  const [containerWidth, setContainerWidth] = useState<number>(1000);
  const [buttonsAmount, setButtonsAmount] = useState<number>(0);

  const listRef: LegacyRef<FixedSizeList<Array<{ id: string; }>>> | undefined = useRef(null);

  useEffect(() => {
    const newButtonsAmount: number = Math.ceil(listItems.length * (getItemsWidth(size, cardAlign) + cardsPadding) / containerWidth);
    setButtonsAmount(newButtonsAmount);
  }, [containerWidth, listItems, size, cardAlign, cardsPadding]);

  const handleScrollToItem = (el: number) => {
    if (listRef.current) {
      listRef.current.scrollToItem(listItems.length / buttonsAmount * el, 'start');
    }
  };

  const handleGetResize = debounce((e: any) => {
    setContainerWidth(e.width);
  }, 50);

  const handleItemsRerender = debounce((e: any) => {
    if (e.visibleStopIndex === listItems?.length - 1 && canLoadMore) {
      if (isFunction(loadMore)) loadMore();
    }
    const newBtn = Math.ceil(e.visibleStartIndex / (listItems.length / buttonsAmount));
    setActiveBtn(newBtn);
  }, 50);

  const handleItemsScroll = debounce((e: any) => {
    if (e.scrollOffset === (listItems.length * (getItemsWidth(size, cardAlign) + cardsPadding)) - containerWidth) {
      setActiveBtn(Array(buttonsAmount)?.length - 1);
    } else if (e.scrollOffset === 0) {
      setActiveBtn(0);
    }
  }, 50);

  const buttonsList = map(Array(buttonsAmount),
    (_, el: number) => {
      return (
        <ButtonBase
          className={ `${styles.dot} ${el === activeBtn ? styles.dotActive : ''}` }
          key={ el + Math.random() }
          onClick={ () => handleScrollToItem(el) }
        />
      );
    });

  return (
    <>
      <div className={ classnames(className, styles.root, {
        [styles[size]]: size
      }) }>
        <ListHorizontalScroll
          listItems={ listItems }
          size={ size }
          cardAlign={ cardAlign }
          cardsPadding={ cardsPadding }
          itemWidth={ getItemsWidth(size, cardAlign) }
          listRef={ listRef }
          handleGetResize={ handleGetResize }
          handleItemsRerender={ handleItemsRerender }
          isTransparent={ isTransparent }
          handleItemsScroll={ handleItemsScroll }
        />
      </div>
      { listItems.length * getItemsWidth(size, cardAlign) > containerWidth &&
          <div className={ styles.dots }>
            { buttonsList }
          </div> }
    </>
  );
};

ListHorizontal.displayName = 'ListHorizontal';

ListHorizontal.defaultProps = {
  size: 'sm',
  cardAlign: 'central',
  cardsPadding: 25
} as TListHorizontalProps;

export default ListHorizontal;
