import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import Wrapper from 'app/components/shared/wrapper/wrapper.index';
import NavbarMenu, { INavbarProps } from './navbar/menu.navbar';
import { stylesMenu } from './menu.styles';

export interface IMenuDesktopProps extends INavbarProps {
  className?: string;
  toolbar: ReactNode;
}

const MenuDesktop: FC<IMenuDesktopProps> = ({ toolbar, className, ...navbarMenuProps }) => {

  const classes = stylesMenu();

  return (
    <nav className={ classNames(classes.root, className) }>
      <Wrapper className={ classes.container }>
        <NavbarMenu { ...navbarMenuProps } />
        <div className={ classes.toolbar }>
          { toolbar }
        </div>
      </Wrapper>
    </nav>
  );
};

MenuDesktop.displayName = 'MenuDesktop';

export default MenuDesktop;
