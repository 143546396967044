export const megafonColors = {
  basic: {
    green: {
      default: '#00B956',
      transparent: 'rgba(0, 184, 88, 0.7)'
    },
    purple: '#731982',
    white: '#FFFFFF',
    black: {
      default: '#000000',
      normal: '#1D1D1D',
      medium: '#333333',
      neutral: '#292929'
    },
    grey: {
      light: '#F6F6F6',
      neutral: '#EDEDED',
      normal: '#D8D8D8',
      dark: '#333333',
      default: '#8F8F8F'
    }
  },
  secondary: {
    red: '#EB5A40',
    orange: '#FFA717',
    blue: {
      light: '#5BD9E5',
      dark: '#444189'
    }
  },
  systems: {
    fury: '#DA0505',
    link: '#34AAF2',
    button: {
      hover: {
        green: '#10E272',
        purple: '#A500BF'
      },
      down: '#404D46'
    }
  },
  background: {
    grey: {
      dark: '#343434',
      default: '#CBCBCB',
      light: '#EAEAEA',
      medium: '#4D4D4D',
      neutral: '#C4C4C4'
    },
    light: '#E9E8EE'
  },
  shadow: {
    translucent: 'rgba(0, 0, 0, 0.08)',
    default: 'rgba(0, 0, 0, 0.05)',
    dark: 'rgba(0, 0, 0, 0.12)'
  },
  border: {
    grey: '#D6D6D6',
    neutral: '#DFDFDF',
    blue: '#93B8FE',
    default: '#C6C9C3'
  }
};

export const colors = {
  basic: {
    primary: '#000000',
    secondary: '#ffffff'
  },
  default: {
    mobile: {
      default: '#C882FF',
      hover: '#C882FF'
    },
    refill: {
      default: '#38FFA6',
      hover: '#84CA02'
    },
    service: {
      default: '#D7FF38',
      hover: '#07CB5C'
    },
    shop: {
      default: '#FF59A3',
      hover: '#FF59A3'
    },
    help: {
      default: '#3FCBFF',
      hover: '#02A6E3'
    }
  },
  border: {
    primary: {
      dark: '#1F2229',
      light: '#D1DBD6',
      neutral: '#fff',
      translucent: 'rgba(31, 34, 41, .7)',
      disabled: '#CDCFD2'
    },
    secondary: {
      default: '#7A7A7A',
      hover: '#7A7A7A',
      disabled: '#CDCFD2'
    },
    default: {
      shop: {
        default: '#BA487D',
        hover: '#BA487D',
        disabled: '#CDCFD2'
      },
      help: {
        default: '#38A7D1',
        hover: '#38A7D1',
        disabled: '#CDCFD2'
      }
    }
  },
  text: {
    dark: {
      default: '#474747',
      neutral: 'rgba(31, 34, 41, .5)',
      translucent: 'rgba(31, 34, 41, .7)'
    },
    light: {
      default: '#ffffff',
      light: '#F0F1F2',
      neutral: '#8F9399',
      medium: '#C4C4C4',
      translucent: '#8F8F8F'
    },
    disabled: {
      default: '#CDCFD2',
      translucent: 'rgba(205, 207, 210, .3)'
    }
  },
  background: {
    black: {
      default: '#202229',
      translucent: 'rgba(32, 34, 41, .7)'
    },
    white: {
      default: '#ffffff',
      translucent: 'rgba(255, 255, 255, .7)'
    },
    dark: {
      default: '#1F2229',
      neutral: '#8F9399',
      medium: 'rgba(0, 0, 0, 0.5)',
      translucent: 'rgba(31, 34, 41, .3)'
    },
    light: {
      default: '#F1F2F3',
      neutral: '#F9F9FA',
      translucent: 'rgba(249, 249, 250, .3)'
    },
    neutral: {
      default: '#F0F1F2',
      neutral: '#D1D6DB',
      translucent: 'rgba(240, 241, 242, .3)'
    },
    disabled: {
      default: '#CDCFD2',
      translucent: 'rgba(205, 207, 210, .3)'
    },
    dashboard: {
      default: '#F5F5FA',
      neutral: '#E8E8ED',
      translucent: '#E9E8EE'
    },
    promo: {
      default: '#FFDA57',
      translucent: 'rgba(255, 218, 87, .3)'
    },
    skeleton: {
      default: 'rgba(232, 232, 237, .3)'
    },
    badge: {
      primary: '#DA0505',
      secondary: '#FFE538'
    }
  },
  notification: {
    promo: {
      default: '#FFDA57',
      translucent: 'rgba(255, 218, 87, .3)'
    },
    invalid: {
      default: '#D72F4B',
      translucent: 'rgba(215, 47, 75, .3)',
      neutral: '#F3C0C9'
    },
    valid: {
      default: '#41A674',
      translucent: 'rgba(65, 166, 116, .3)',
      neutral: '#C5E5D5'
    },
    warning: {
      default: '#FF002A',
      translucent: 'rgba(255, 0, 42, .3)'
    },
    neutral: {
      default: '#E8E8ED',
      translucent: 'rgba(232, 232, 237, .3)'
    },
    hover: {
      default: '#ffffff',
      translucent: 'rgba(255, 255, 255, .3)'
    }
  },
  shadow: {
    translucent: 'rgba(31, 34, 41, .1)'
  }
};

export const colorsNew = {
  basic: {
    primary: {
      default: '#F07F15'
    },
    secondary: {
      default: '#FFFFFF'
    }
  },
  text: {
    neutral: {
      default: '#474747'
    },
    black: {
      default: '#000000'
    },
    dark: {
      default: '#474747'
    },
    light: {
      default: '#8F8F8F'
    },
    grey: {
      default: '#C6C9C3'
    },
    white: {
      default: '#FFFFFF'
    },
    error: {
      default: '#DA0505'
    }
  },
  background: {
    primary: {
      default: '#F07F15'
    },
    white: {
      default: '#FFFFFF'
    },
    light: {
      default: '#F8F9FA'
    },
    neutral: {
      default: '#C4C4C4'
    },
    dark: {
      default: '#8A9199'
    },
    grey: {
      default: '#E5E5E5',
      dark: '#292929',
      light: '#4D4D4D'
    },
    yellow: {
      light: '#F2F4B5'
    },
    surface: {
      default: '#F8F9FA',
      dark: 'rgba(0, 0, 0, 0.5)'
    }
  },
  border: {
    default: '#EDEFF5',
    input: '#93B8FE'
  },
  extra: {
    red: {
      default: '#DA0505'
    },
    blue: {
      default: '#93B8FE'
    }
  }
};

export const iconColors = {
  primary: colors.text.dark,
  disabled: colors.text.disabled.default,
  light: colors.basic.secondary,
  secondary: colors.text.light.neutral,
  error: colors.notification.invalid.default,
  success: colors.notification.valid.default,
  white: colors.basic.secondary

};
