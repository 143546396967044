import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProps } from 'app/models/model.icons';

const IconBasket: FC<IIconProps> = ({ width= 36, height= 36, ...props }) => (
  /* tslint:disable */
  <SvgIcon width={ width } height={ height } viewBox={ `0 0 ${ width } ${ height }` } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <path d="M5.87774 34.555L6.54856 34.2196H6.54856L5.87774 34.555ZM30.1227 34.555L30.7935 34.8904L30.7935 34.8904L30.1227 34.555ZM1.68787 13.95H34.3125V12.45H1.68787V13.95ZM34.0502 13.6876V14.7407H35.5502V13.6876H34.0502ZM29.7264 34.0499H6.27402V35.5499H29.7264V34.0499ZM1.9502 14.7407V13.6876H0.450195V14.7407H1.9502ZM6.54856 34.2196C3.52454 28.1716 1.9502 21.5026 1.9502 14.7407H0.450195C0.450195 21.7354 2.07876 28.6341 5.20691 34.8904L6.54856 34.2196ZM6.27402 34.0499C6.39028 34.0499 6.49656 34.1156 6.54856 34.2196L5.20691 34.8904C5.40901 35.2946 5.82212 35.5499 6.27402 35.5499V34.0499ZM29.4518 34.2196C29.5038 34.1156 29.6101 34.0499 29.7264 34.0499V35.5499C30.1783 35.5499 30.5914 35.2946 30.7935 34.8904L29.4518 34.2196ZM34.0502 14.7407C34.0502 21.5026 32.4758 28.1716 29.4518 34.2196L30.7935 34.8904C33.9216 28.6341 35.5502 21.7354 35.5502 14.7407H34.0502ZM34.3125 13.95C34.1676 13.95 34.0502 13.8325 34.0502 13.6876H35.5502C35.5502 13.0041 34.9961 12.45 34.3125 12.45V13.95ZM1.68787 12.45C1.00432 12.45 0.450195 13.0041 0.450195 13.6876H1.9502C1.9502 13.8325 1.83275 13.95 1.68787 13.95V12.45ZM9.04331 13.5858L16.2433 1.58582L14.9571 0.814079L7.75708 12.8141L9.04331 13.5858ZM19.7571 1.58582L26.9571 13.5858L28.2433 12.8141L21.0433 0.814079L19.7571 1.58582Z" fill="#721983"/>
  </SvgIcon>
  /* tslint:enable */
);

export default IconBasket;
