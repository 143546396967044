import React from 'react';
import { createStyles, makeStyles, Theme, Paper } from '@material-ui/core';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { ArrowForwardIosOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { configTheme } from 'app/configs/theme/config.theme';

export const useStylesAutocomplete = makeStyles((theme: Theme) =>
  createStyles({
    autocomplete: {
      '& .MuiFilledInput-root': {
        backgroundColor: configTheme.colors.background.light.default,
        border: `1px solid ${ configTheme.megafonColors.border.default }`,
        borderRadius: 0
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      },
      '& .MuiIconButton-label': {
        transform: 'rotate(90deg)'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: configTheme.megafonColors.border.default,
        borderRadius: 0,
        '& legend': {
          display: 'none'
        }
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: configTheme.megafonColors.border.default,
        borderWidth: 1,
        borderRadius: 0
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: configTheme.megafonColors.border.default,
        borderWidth: 1,
        borderRadius: 0
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: '0 4px 2px'
      },
      '& .MuiAutocomplete-inputRoot': {
        height: 38,
        padding: '0 10px'
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 12px) scale(1)',
        color: configTheme.colors.text.light.default,
        ...configTheme.fontFamily.MegaFonGraphikLCRegular
      },
      '& .MuiAutocomplete-popupIndicator': {
        padding: 0
      }
    },
    backdrop: {
      marginTop: 5,
      border: `1px solid ${ configTheme.megafonColors.border.default }`,
      boxShadow: 'none',
      borderRadius: 0
    },
    icon: {
      margin: 5,
      color: configTheme.megafonColors.basic.green.default,
      fontSize: 17
    }
  })
);

interface IAutocomplete extends Omit<AutocompleteProps<any, boolean, boolean, any>, 'renderInput'> {
  onChange?: (props: any) => void;
}

export const CustomAutocomplete = (props: IAutocomplete) => {
  const { onChange, options, value, defaultValue, ...rest } = props;
  const styles = useStylesAutocomplete();
  const { t } = useTranslation();

  return (
    <Autocomplete
      { ...rest }
      PaperComponent={ ({ children }) => <Paper className={ styles.backdrop }>{ children }</Paper> }
      className={ styles.autocomplete }
      disableClearable
      defaultValue={ defaultValue }
      getOptionLabel={ option => option.title }
      onChange={ (event, newValue) => {
        onChange(newValue);
      } }
      options={ options }
      renderInput={ ({ inputProps, ...children }) => (
        <TextField
          { ...children }
          placeholder={ t('diet.weekendProgram.yourGoal') }
          onClick={ e => {
          e.preventDefault();
          e.stopPropagation();
        } }
          variant="outlined"
          inputProps={ { ...inputProps, readOnly: true } }
          InputLabelProps={ { shrink: false } }
        />
      ) }
      popupIcon={
        <ArrowForwardIosOutlined className={ styles.icon }/>
      }
      noOptionsText={ t('common.noResult') }
    />
  );
};
