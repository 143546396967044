import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProps } from 'app/models/model.icons';

export const IconSquare: FC<IIconProps> = ({ width= 25, height= 25, stroke= '#00B956', ...props }) => (
  /* tslint:disable */
  <SvgIcon width={ width } height={ height } viewBox={ `0 0 ${ width } ${ height }` } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <rect x="2" y="2" width="20" height="20" rx="2.5" fill="none" stroke={ stroke }/>
  </SvgIcon>
  /* tslint:enable */
);
