export const ROUTE_ACTIONS = {
  UPDATE_ROUTE: 'ROUTE::UPDATE_ROUTE'
};

export interface IRouteActions {
  updateRoute: (route: string) => void;
}

export const updateRoute: IRouteActions['updateRoute'] = (route: string) => dispatch =>
  dispatch({
    type: ROUTE_ACTIONS.UPDATE_ROUTE,
    payload: route
  });
