import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconArrow: FC <SvgIconProps> = props => (
  /* tslint:disable */
  <SvgIcon width="16" height="25" viewBox={ '0 0 16 25' } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8399 12.4999L1.41992 3.07987L3.24661 1.25317L14.4933 12.4999L3.24661 23.7465L1.41992 21.9198L10.8399 12.4999Z" fill="#1D1D1D" stroke="#1D1D1D"/>
  </SvgIcon>
  /* tslint:enable */
);

export default IconArrow;
