//#region imports
import { StandardProps } from '@material-ui/core';

import { IBanner } from 'app/models/model.banner';
import { IBannerContainer } from 'app/containers/banner.container';
//#endregion

export interface IBannerProps {
  data?: IBanner[];
  loading?: boolean;
  /**
   * Рандомная последовательность баннеров
   */
  shuffled?: boolean;
  masked?: boolean;
}

export const EBannerClassKey = {
  root: 'root',
  banner: 'banner',
  title: 'title',
  carousel: 'carousel',
  button: 'button',
  content: 'content',
  image: 'image'
} as const;

// const assertions (TS 3.4+)
export type BannerClasses = typeof EBannerClassKey[keyof typeof EBannerClassKey];

export type BannerKeyframes = '@keyframes appear' | '@keyframes disappear';

export type BannerProps = StandardProps<IBannerProps, BannerClasses>;

export interface IConnectedBannerProps {
  bannerContainer: IBannerContainer;
}

export interface IBannerStylesProps {
  classes?: BannerProps['classes'];
  mobile?: boolean;
  tablet?: boolean;
}

export type ConnectedBannerProps = IConnectedBannerProps & BannerProps;
