import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { I18nextProvider } from 'react-i18next';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import { BrowserRouter as Router } from 'react-router-dom';

import { jssPreset, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { create } from 'jss';

import { JssProvider } from 'react-jss';
import DevTools from 'app/configs/devtools';

import ErrorBoundary from 'app/components/boundary/error.boundary';
import i18n from 'app/configs/locales/include';

import setupAxiosInterceptors from 'app/configs/axios.interceptor';

import initStore, { history } from 'app/configs/store';
import { DEVELOPMENT } from 'app/configs/const';
import App from 'app/app';
import Favicon from 'react-favicon';
import faviconIco from 'app/assets/favicon.ico';

const rootEl = document.getElementById('root');

const store = initStore();

setupAxiosInterceptors();

const theme = createTheme();

const jss = create({ plugins: [...jssPreset().plugins] });

const render = () =>
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <ErrorBoundary>
          <AppContainer>
            <Provider store={ store }>
              { DEVELOPMENT ? <DevTools /> : null }
              <I18nextProvider i18n={ i18n }>
                <ConnectedRouter history={ history }>
                  <JssProvider jss={ jss }>
                    <ThemeProvider theme={ theme }>
                      <LastLocationProvider>
                        <Favicon url={ faviconIco } />
                        <App/>
                      </LastLocationProvider>
                    </ThemeProvider>
                  </JssProvider>
                </ConnectedRouter>
              </I18nextProvider>
            </Provider>
          </AppContainer>
        </ErrorBoundary>
      </Router>
    </React.StrictMode>,
    rootEl
  );

render();
