import React, { FC } from 'react';

import { ILayoutProps } from './layout.model';
import styles from './layout.styles';

const Layout: FC<ILayoutProps> = ({ renderHeader, renderFooter, children }) => {
  const classes = styles();

  return(
    <>
      <header className={ classes.root }>{ renderHeader }</header>
      <main className={ classes.main }>{ children }</main>
      <footer>{ renderFooter }</footer>
    </>
  );
};

export default Layout;
