import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconAvailable: FC<SvgIconProps> = ({ width= 17, height= 17, ...props }) => (
  /* tslint:disable */
  <SvgIcon width={ width } height={ height } viewBox={ `0 0 ${ width } ${ height }` } fill="none" { ...props }>
    <path d="M4.75 8.5L7.96429 11.1786L12.25 5.82143M8.5 16C4.35786 16 1 12.6421 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5C16 12.6421 12.6421 16 8.5 16Z" stroke="#00B956" fill="none"/>
  </SvgIcon>
  /* tslint:enable */
);

export default IconAvailable;
