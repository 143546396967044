//#region imports
import React, { FC, ReactHTML, SyntheticEvent, forwardRef, useRef, useState, useCallback, useEffect } from 'react';
import { useForkRef } from '@material-ui/core';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';

import { PostcardProps, EPostcardLayout, EPostcardClassKey, EImageStatus, ImageStatus } from './postcard.models';
import { usePostcardStyles } from './postcard.styles';
//#endregion

export const Postcard: FC<PostcardProps> = forwardRef<HTMLDivElement, PostcardProps>((props, ref) => {
  const {
    className,
    style,
    classes,
    layout,
    width,
    height,
    src,
    srcSet,
    imageStyle,
    link,
    extraTop,
    extraMiddle,
    extraBottom,
    placeholder,
    fallback,
    mask,
    children,
    onClick,
    onPictureClick,
    onImageLoad,
    onImageError
  } = props;
  const styles = usePostcardStyles({ classes });

  const rootRef = useRef(null);
  const handleRef = useForkRef(rootRef, ref);

  const hasSource = src?.length || srcSet?.length;
  const hasPlaceholder = placeholder && placeholder !== true;
  const hasExtra = extraTop || extraMiddle || extraBottom;
  const hasMask = mask && mask !== true;

  const [status, setStatus] = useState<ImageStatus>(hasPlaceholder ? EImageStatus.loading : EImageStatus.normal);
  const isError = status === EImageStatus.error;

  const onLoad = useCallback((e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (isFunction(onImageLoad)) onImageLoad(e);
    setStatus(EImageStatus.normal);
  }, [onImageLoad]);

  const onError = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      if (isFunction(onImageError)) onImageError(e);
      setStatus(EImageStatus.error);
    },
    [onImageError]
  );

  const grabImageRef = (node?: HTMLImageElement) => {
    if (status !== 'loading') return;
    if (node?.complete && (node.naturalWidth || node.naturalHeight)) {
      setStatus(EImageStatus.normal);
    }
  };

  useEffect(() => {
    if (!hasSource) setStatus(EImageStatus.error);
    else if (isError) setStatus(EImageStatus.normal);
    else if (hasPlaceholder) setStatus(EImageStatus.loading);
  }, [src, srcSet]);

  const PictureNode: keyof ReactHTML = link?.length > 0 ? `a` : `div`;
  return (
    <div ref={ handleRef } className={ classnames(className, styles[EPostcardClassKey.root], styles[EPostcardLayout[layout]]) } style={ style } onClick={ onClick }>
      <PictureNode className={ styles[EPostcardClassKey.picture] } style={ { width } } href={ link } onClick={ onPictureClick }>
        <div className={ styles[EPostcardClassKey.pictureFigure] } style={ { width, height } }>
          <img
            key="image"
            ref={ grabImageRef }
            className={ styles[EPostcardClassKey.pictureImage] }
            style={ { height, ...imageStyle } }
            { ...(isError && fallback ? { src: fallback } : { onLoad, onError, src, srcSet }) }
          />

          { hasPlaceholder && status === EImageStatus.loading && (
            <div aria-hidden="true" className={ styles[EPostcardClassKey.picturePlaceholder] }>
              { placeholder }
            </div>
          ) }
        </div>

        { hasExtra && (
          <div className={ classnames(styles[EPostcardClassKey.pictureExtra], styles[EPostcardClassKey.extra]) }>
            { extraTop && <div className={ styles[EPostcardClassKey.extraTop] }>{ extraTop }</div> }
            { extraMiddle && <div className={ styles[EPostcardClassKey.extraMiddle] }>{ extraMiddle }</div> }
            { extraBottom && <div className={ styles[EPostcardClassKey.extraBottom] }>{ extraBottom }</div> }
          </div>
        ) }

        { hasMask && status === EImageStatus.normal && <div className={ styles[EPostcardClassKey.pictureMask] }>{ mask }</div> }
      </PictureNode>
      <div className={ styles[EPostcardClassKey.descr] }>{ children }</div>
    </div>
  );
});

Postcard.defaultProps = {
  layout: EPostcardLayout.vertical
} as PostcardProps;
