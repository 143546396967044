import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProps } from 'app/models/model.icons';

const IconThumb: FC<IIconProps> = ({ width= 30, height= 30, fill, ...props }) => (
  /* tslint:disable */
  <SvgIcon width={ width } height={ height } viewBox={ `0 0 ${ width } ${ height }` } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <path d="M7 10.9999L6.36725 10.5973L6.25 10.7815V10.9999H7ZM12.3952 2.52174L13.028 2.9244L12.3952 2.52174ZM17.7291 5.54173L17.0583 5.20632V5.20632L17.7291 5.54173ZM15 10.9999L14.3292 10.6645C14.2129 10.897 14.2254 11.1731 14.362 11.3942C14.4987 11.6153 14.7401 11.7499 15 11.7499V10.9999ZM29 20.9999L29.6 21.4499L29.75 21.2499V20.9999H29ZM24.2 27.3999L24.8 27.8499L24.2 27.3999ZM16.5632 1.53782L16.949 0.894701V0.8947L16.5632 1.53782ZM0.25 9.99993V29.9999H1.75V9.99993H0.25ZM7.63275 11.4026L13.028 2.9244L11.7625 2.11908L6.36725 10.5973L7.63275 11.4026ZM17.0583 5.20632L14.3292 10.6645L15.6708 11.3353L18.3999 5.87714L17.0583 5.20632ZM15 11.7499H25V10.2499H15V11.7499ZM28.25 14.9999V20.9999H29.75V14.9999H28.25ZM28.4 20.5499L23.6 26.9499L24.8 27.8499L29.6 21.4499L28.4 20.5499ZM21 28.2499H11V29.7499H21V28.2499ZM7.75 24.9999V10.9999H6.25V24.9999H7.75ZM25 11.7499C26.7949 11.7499 28.25 13.205 28.25 14.9999H29.75C29.75 12.3766 27.6234 10.2499 25 10.2499V11.7499ZM11 28.2499C9.20507 28.2499 7.75 26.7948 7.75 24.9999H6.25C6.25 27.6233 8.37665 29.7499 11 29.7499V28.2499ZM16.1773 2.18094C17.2163 2.80434 17.6002 4.12257 17.0583 5.20632L18.3999 5.87714C19.2923 4.09234 18.6601 1.92136 16.949 0.894701L16.1773 2.18094ZM23.6 26.9499C22.9862 27.7683 22.023 28.2499 21 28.2499V29.7499C22.4951 29.7499 23.9029 29.046 24.8 27.8499L23.6 26.9499ZM13.028 2.9244C13.7028 1.86401 15.0995 1.53428 16.1773 2.18094L16.949 0.8947C15.1741 -0.170278 12.8738 0.37275 11.7625 2.11908L13.028 2.9244Z" fill={ fill ? fill : "#721983"}/>
  </SvgIcon>
  /* tslint:enable */
);

export default IconThumb;
