export const breakpoints = {
  values: {
    xxs: 375,
    xs: 450,
    sm: 768,
    md: 960,
    mdx: 1023,
    lg: 1280,
    xl: 1440,
    xxl: 1920
  }
};
