export enum ESubscriptionStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
  NO_MONEY = 'NO_MONEY'
}

export enum ESubscribeStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  NO_MONEY = 'NO_MONEY'
}

export enum ESubscribeErrors {
  NOT_FOUND = 'NOT_FOUND'
}

export interface ISubscription {
  age: string;
  description?: string;
  id: number;
  name: string;
  price: string;
  archive: boolean;
  image: string;
  status: ESubscriptionStatus;
  subscribeCondition: string;
  successMessage: string;
  unsubscribeCondition: string;
}

export interface ISubscriptionCardProps {
  title: string;
  desc: string;
  price: string;
  isSubscribe: boolean;
  toggleSubscribe?: () => void;
  className?: string;
}
