import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProps } from 'app/models/model.icons';

export const IconExclamation: FC<IIconProps> = ({ width= 26, height= 26, ...props }) => (
  /* tslint:disable */
  <SvgIcon width={ width } height={ height } viewBox={ `0 0 ${ width } ${ height }` } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <path d="M13.5 18.2V17.7H12.5V18.2H13.5ZM12.5 18.2174V18.7174H13.5V18.2174H12.5ZM12.5 6.93337V13.8667H13.5V6.93337H12.5ZM12.5 18.2V18.2174H13.5V18.2H12.5ZM13 24.6334C6.57512 24.6334 1.3667 19.4249 1.3667 13H0.366699C0.366699 19.9772 6.02284 25.6334 13 25.6334V24.6334ZM24.6334 13C24.6334 19.4249 19.4249 24.6334 13 24.6334V25.6334C19.9772 25.6334 25.6334 19.9772 25.6334 13H24.6334ZM13 1.3667C19.4249 1.3667 24.6334 6.57512 24.6334 13H25.6334C25.6334 6.02284 19.9772 0.366699 13 0.366699V1.3667ZM13 0.366699C6.02284 0.366699 0.366699 6.02284 0.366699 13H1.3667C1.3667 6.57512 6.57512 1.3667 13 1.3667V0.366699Z" fill="#721983"/>
  </SvgIcon>
  /* tslint:enable */
);
