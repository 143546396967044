import React, { FC } from 'react';

import { IWrapperProps } from './wrapper.model';

import styles from './wrapper.styles';

const Wrapper: FC<IWrapperProps> = ({ children, className = '' }) => {
  const classes = styles();

  return <div className={ `${classes.root} ${className}` }>{ children }</div>;
};

export default Wrapper;
