import { EMePageContent, EMenuItem, EUnsubscribeButtons } from 'app/configs/theme/config.theme.const';
import { ESubscriptionStatus } from 'app/models/model.subscription';

export const uiSetup = {
  isAuthModal: false,
  bookAuthor: true,
  infoBlockHeader: false,
  maxNotificationsInfo: true,
  isAuthExternal: true,
  afterAuthExternalLink: '/',
  sameWidthButton: false,
  contentHeaderWithClose: true,
  showDietTargetInCategory: true,
  showAccessTypeInCategory: true,
  catalogueInSingleLayout: true,
  downloadBeforeDescription: true,
  msisdnInMyData: true,
  dataNotEnteredPlaceholder: true,
  subscribeWithHeader: true,
  floatLabel: true,
  separateErrors: true,
  withGame: true,
  withLogging: true,
  dietTargetFilter: true,
  preInfo: true,
  dietErrorAfterHeader: true,
  showAsterisk: false,
  defaultAttrsEmpty: true,
  maxNotificationsInfoText: true,
  maxNotificationsInErrorModal: true,
  pages: {
    error: {
      isVisibleFooter: false
    },
    me: {
      showNotifications: [ESubscriptionStatus.ACTIVE, ESubscriptionStatus.NO_MONEY],
      editAttributes: [ESubscriptionStatus.ACTIVE, ESubscriptionStatus.NO_MONEY],
      showAttributes: [ESubscriptionStatus.ACTIVE, ESubscriptionStatus.NO_MONEY],
      content: [EMePageContent.DATA, EMePageContent.SUBSCRIPTIONS, EMePageContent.NOTIFICATIONS],
      subscribePopup: true
    }
  },
  blocks: {
    banner: {
      bannerLink: '/category/1',
      isBannerSubtitle: true
    }
  },
  common: {
    redirect: {
      on: deviceDetect => !deviceDetect.isMobile,
      link: 'https://tele2.ru'
    }
  },
  menu: {
    languagePicker: false,
    logoText: true,
    mobileCloseButton: false,
    mobileLegalInfo: false,
    items: [EMenuItem.GAME]
  },
  modal: {
    subscribe: {
      captcha: false,
      customText: true,
      cancelButton: {
        show: false,
        outlined: false,
        center: false
      }
    },
    unsubscribe: {
      buttons: [EUnsubscribeButtons.CANCEL, EUnsubscribeButtons.UNSUBSCRIBE]
    }
  }
};
