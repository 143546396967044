import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconRefresh: FC <SvgIconProps> = props => (
  <SvgIcon width="15" height="15" viewBox={ '0 0 15 15' } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <path d="M0.5 7.5C0.5 3.63401 3.63401 0.5 7.5 0.5C9.73096 0.5 11.7182 1.54367 13 3.1694M14.5 7.5C14.5 11.366 11.366
    14.5 7.5 14.5C5.26904 14.5 3.28183 13.4563 2 11.8306M5 11.5H1.5V15M13.5 0V3.5L10 3.5" stroke="#00B956" fill="none"
    />
  </SvgIcon>
);

export default IconRefresh;
