import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

export const useStylesProgramLayout = makeStyles<{}, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    wrapper: {
      marginLeft: '162px',
      marginTop: '54px',
      width: 'auto'
    },
    titleDay: {
      fontSize: '14px',
      color: configTheme.colors.text.dark.neutral,
      marginRight: '27px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    button: {
      minWidth: ({ isMobile }) => isMobile ? 290 : 327,
      fontSize: ({ isMobile }) => isMobile ? 16 : 19,
      padding: ({ isMobile }) => isMobile ? '0 20px' : '0 30px',
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      '& .mfui-button__inner': {
        height: ({ isMobile }) => isMobile ? 43 : 60
      }
    },
    btnNextDay: {
      margin: ({ isMobile }) => isMobile ? '30px 0 18px 0' : '54px 0 18px 0'
    },
    setReminders: {
      marginBottom: ({ isMobile }) => isMobile ? 45 : 72
    },
    active: {
      color: configTheme.megafonColors.basic.black.normal
    }
  })
);
