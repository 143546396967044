import { ILoggingInitialState } from 'app/entities/logging/logging.reducer';

export const getLoggingData = (pathname = ''): ILoggingInitialState => {
  const regexId = /\d{3,}/gi;
  const newState: ILoggingInitialState = {
    position: pathname,
    categoryId: null,
    subcategoryId: null,
    sectionId: null,
    subsectionId: null,
    contentId: null,
    subscribeId: null
  };
  if (pathname.includes('section')) {
    const ids = pathname.match(regexId) ?? [];
    newState.categoryId = Number(ids[0]);
    newState.subcategoryId = Number(ids[1]);
  }
  if (pathname.includes('content/list')) {
    const ids = pathname.match(regexId) ?? [];
    newState.categoryId = Number(ids[0]);
    newState.sectionId = Number(ids[1]);
  }
  return newState;
};
