//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';
import { Button, Header } from '@megafon/ui-core';

import {
  TCardVerticalProps
} from '../card.vertical.models';
import stylesCardVerticalCentral from './card.vertical.central.styles';
import Likes from 'app/components/shared/likes/likes.index';
import Badge from 'app/components/shared/badge/badge.index';
import { placeholder, cloneEl } from 'app/utils/utils.accessories';
//#endregion

const CardVerticalCentral: FC<TCardVerticalProps> = props => {
  const {
    className,
    size,
    title,
    button = {},
    badge = {},
    likes = {},
    src,
    overlay,
    onClick,
    isBillet
  } = props;

  const { visible: btnVisible = true, ...btnProps } = button;
  const { visible: likesVisible = true, ...likesProps } = likes;
  const { visible: badgeVisible = true, ...badgeProps } = badge;

  const { t } = useTranslation();
  const styles = stylesCardVerticalCentral();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isFunction(onClick)) onClick(e);
  };

  if (isBillet) {
    return (
      <ButtonBase>
        <div className={ classnames(className, styles.billet, {
          [styles[size]]: size
        }) }
          onClick={ handleClick }
        >
          <Header as="h5">
            { title ?? t('components.shared.card.titleBillet') }
          </Header>
        </div>
      </ButtonBase>
    );
  }

  return (
    <div className={ classnames(className, styles.root, {
      [styles[size]]: size
    }) }
         onClick={ handleClick }
    >
      <div className={ styles.picture }>
        <img
          src={ src ?? placeholder }
          alt={ title ?? t('components.shared.card.imgAltDefault') }
        />
        { overlay && <div className={ styles.overlay }>{ cloneEl(overlay, size) }</div> }
      </div>
      <Header as="h5">
        { title ?? t('components.shared.card.titleDefault') }
      </Header>
      <div className={ styles.button }>
        { btnVisible &&
          <Button>
            { button?.children }
        </Button> }
      </div>
      <div className={ styles.footer }>
        <span className={ styles.badge }>{ badgeVisible && <Badge size={ size } { ...badgeProps } /> }</span>
        <span className={ styles.likes }>{ likesVisible && <Likes size={ size } { ...likesProps } /> }</span>
      </div>
    </div>
  );
};

CardVerticalCentral.displayName = 'CardVerticalCentral';

export default CardVerticalCentral;
