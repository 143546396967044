import { IRootState } from 'app/shared/reducers/root.reducer';
import { IContentListMainState } from 'app/entities/content/content.list.main.reducer';

import { fetchContentMain, resetContentMain, IFetchContentListProps } from 'app/entities/content/content.actions';

import { Dispatch, Action } from 'redux';
import { connect } from 'app/containers/connect';

export type IContainerContentListMainProps = IContentListMainState & {
  actions: {
    fetchContentMain: (props: IFetchContentListProps) => void;
    resetContentMain: typeof resetContentMain;
  }
};

const mapStateToProps = (state: IRootState) => state.contentListMain;
const mapDispatchToProps = (dispatch: Dispatch): IContainerContentListMainProps['actions'] => ({
  fetchContentMain: props => dispatch(fetchContentMain(props) as unknown as Action),
  resetContentMain: () => dispatch(resetContentMain())
});

export const ContainerContentListMain =
  connect<IContainerContentListMainProps>('containerContentListMain', mapStateToProps, mapDispatchToProps);
