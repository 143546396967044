import React, { useCallback, useRef, useState, MouseEvent } from 'react';

type TCallback = (e?: MouseEvent) => void;
type TDragMove = (e: MouseEvent, cb: (posDif: number) => void) => void;

export const useDrag = () => {
  const [clicked, setClicked] = useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);
  const position = useRef<number>(0);

  const dragStart = useCallback<TCallback>(e => {
    position.current = e.clientX;
    setClicked(true);
  }, []);

  const dragStop = useCallback<TCallback>(() =>
      window.requestAnimationFrame(() => {
        setDragging(false);
        setClicked(false);
      }),
    []
  );

  const dragMove: TDragMove = (e, cb) => {
    const newDiff = position.current - e.clientX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setDragging(true);
    }

    if (dragging && movedEnough) {
      position.current = e.clientX;
      cb(newDiff);
    }
  };

  return {
    dragStart,
    dragStop,
    dragMove,
    dragging,
    position,
    setDragging
  };
};
