//#region imports
import React, { FC, useContext } from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-next';
import classnames from 'classnames';
import { Button } from '@megafon/ui-core';

import stylesNavbarInifinity from './navbar.infinity.styles';
//#endregion

export interface INavbarInfinityItem {
  name: string;
  link: string;
  itemId?: string;
}

export interface INavbarInfinityItemProps extends INavbarInfinityItem {
  isActive: boolean;
  onSelect: (itemId: INavbarInfinityItem['itemId']) => void;
  buttons?: boolean;
  mobile?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const NavbarInfinityItem: FC<INavbarInfinityItemProps> = ({ name, itemId, isActive, onSelect, buttons, mobile, isFirst, isLast }) => {
  const visibility = useContext(VisibilityContext);
  visibility?.isItemVisible(itemId);
  const classes = stylesNavbarInifinity({ buttons, mobile, isFirst, isLast });

  if (buttons) {
    return (
      <div className={ classnames(classes.item, { exact: isActive }) } key={ itemId } onClick={ () => onSelect(itemId) }>
        <Button className={ `${classes.itemButton}` } type={ isActive ? 'primary' : 'outline' }>
          { name }
        </Button>
      </div>
      );
  }

  return (
    <div className={ classnames(classes.item, classes.itemLink, { exact: isActive }) } key={ itemId } onClick={ () => onSelect(itemId) }>
      { name }
    </div>
  );
};

export default NavbarInfinityItem;
