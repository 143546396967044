import axios from 'axios';
import { BASENAME } from 'app/configs/const';
import { IConfirmBody } from './user.model';

export const USER_ACTIONS = {
  CONFIRM: 'USER::CONFIRM',
  REQUEST: 'USER::REQUEST',
  CHECK: 'USER::CHECK',
  TOGGLE_MODAL_LOGIN: 'USER::TOGGLE_MODAL_LOGIN',
  TOGGLE_MODAL_LOGOUT: 'USER::TOGGLE_MODAL_LOGOUT',
  RESET_FIELDS: 'USER::RESET_FIELDS'
};

export interface IModalLoginProps {
  isExtended?: boolean;
}

export interface IUserActions {
  request: (msisdn: string) => any;
  confirm: (data: IConfirmBody) => any;
  checkUser: () => any;
  resetFields: (data: string[]) => any;
  toggleModalLogin: (data?: IModalLoginProps) => any;
  toggleModalLogout: () => any;
}

export const request: IUserActions['request'] = msisdn => ({
  type: USER_ACTIONS.REQUEST,
  payload: axios.post(`${BASENAME}/api/auth/request`, { msisdn })
});

export const confirm: IUserActions['confirm'] = data => ({
  type: USER_ACTIONS.CONFIRM,
  payload: axios.post(`${BASENAME}/api/auth/confirm`, data),
  meta: { msisdn: data.msisdn }
});

export const checkUser: IUserActions['checkUser'] = () => ({
  type: USER_ACTIONS.CHECK,
  payload: axios.get(`${BASENAME}/api/user`)
});

export const toggleModalLogin: IUserActions['toggleModalLogin'] = data => ({
  type: USER_ACTIONS.TOGGLE_MODAL_LOGIN,
  meta: { extended: data?.isExtended }
});

export const toggleModalLogout: IUserActions['toggleModalLogout'] = () => ({ type: USER_ACTIONS.TOGGLE_MODAL_LOGOUT });

// ### Reset change fields
export const resetFields: IUserActions['resetFields'] = fields => dispatch =>
  dispatch({
    type: USER_ACTIONS.RESET_FIELDS,
    payload: [...fields]
  });
// ###
