//#region imports
import { makeStyles, createStyles, Theme } from '@material-ui/core';
//#endregion

interface IProps {
  isNotDesktop?: boolean;
  isMobile?: boolean;
  isTabletMenu?: boolean;
}

export const stylesCategoryPage = makeStyles<Theme, IProps>(theme =>
  createStyles({
    root: {
      paddingTop: ({ isTabletMenu }) => isTabletMenu ? 0 : 28,
      paddingBottom: ({ isTabletMenu }) => isTabletMenu ? 50 : 80,
      minHeight: ({ isTabletMenu }) => isTabletMenu ? 'calc(100vh - 227px)' : 'calc(100vh - 333px)'
    },
    container: {
      maxWidth: 1274,
      margin: '0 auto',
      [theme.breakpoints.down(1455)]: {
        maxWidth: 824
      }
    },
    list: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down(955)]: {
        flexDirection: 'column'
      }
    },
    cardItem: {
      maxWidth: ({ isMobile }) => (isMobile ? 335 : 424),
      marginTop: ({ isMobile }) => isMobile ? 16 : 30,
      marginRight: 34,
      '&:first-child': {
        marginTop: ({ isMobile }) => isMobile ? 25 : 30
      },
      [theme.breakpoints.up(1455)]: {
        '&:nth-child(3n)': {
          marginRight: 0
        }
      },
      [theme.breakpoints.down(1455)]: {
        marginLeft: 'calc((100% - 426px * 2)/4)',
        marginRight: 'calc((100% - 426px * 2)/4)'
      },
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginTop: 16,
        '&:first-child': {
          marginTop: 20
        }
      }
    }
  }),
  { name: 'CategoryPage' }
);
