//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
import { EBreakPointSize } from 'app/models/model.theme';
//#endregion

interface IModalSize {
  size?: Exclude<keyof typeof EBreakPointSize, 'md' | 'lg' | 'xl'>;
}

export default makeStyles(
  createStyles({
    root: (props: IModalSize) => ({
      '& .MuiPaper-root': {
        color: configTheme.megafonColors.basic.black.normal
      },
      '& .MuiPaper-rounded': {
        borderRadius: 5
      },
      '& .MuiDialog-container': {
        height: 'auto'
      },
      '& .MuiDialog-paper': {
        minHeight: 200,
        boxSizing: 'border-box',
        maxWidth: props.size === 'sm' ? 375 : 335,
        width: '100%',
        margin: '114px 20px 10px 20px',
        overflowY: 'visible',
        overflowX: 'hidden'
      },
      '& .MuiDialogContent-root': {
        padding: props.size === 'sm' ? '8px 40px' : '8px 20px'
      },
      '& .MuiDialogTitle-root': {
        padding: props.size === 'sm' ? '0px 40px' : '0px 20px',
        backgroundColor: configTheme.megafonColors.basic.green.default
      }
    }),
    title: (props: IModalSize) => ({
      overflow: 'hidden',
      overflowWrap: 'anywhere',
      padding: '8px 10px',
      height: props.size === 'sm' ? 76 : 56,
      '& h2': {
        padding: props.size === 'sm' ? '25px 0 10px 0' : '15px 0 0 0',
        fontSize: props.size === 'sm' ? 19 : 16,
        lineHeight: '1.1em',
        ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
        color: configTheme.megafonColors.basic.white
      }
    }),
    renderInfo: {
      maxWidth: 'fit-content',
      marginTop: 10,
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical'
    },
    close: (props: IModalSize) => ({
      position: 'absolute',
      top: props.size === 'sm' ? 15 : 5,
      right: props.size === 'sm' ? 30 : 8,
      '& svg': {
        width: 20,
        height: 20
      }
    }),
    xs: {
      '& $desc': {
        fontSize: 14
      }
    },
    desc: (props: IModalSize) => ({
      padding: props.size === 'sm' ? '20px 0 0 0' : '20px 0 0 0',
      overflow: 'hidden',
      overflowWrap: 'anywhere',
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      fontSize: props.size === 'sm' ? 17 : 14,
      '& strong': {
        ...configTheme.fontFamily.MegaFonGraphikLCSemibold
      }
    }),
    content: (props: IModalSize) => ({
      padding: props.size === 'sm' ? '30px 40px' : '20px 20px'
    }),
    buttons: (props: IModalSize) => ({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginBottom: 10,
      '& .mfui-button': {
        fontSize: props.size === 'sm' ? 17 : 16,
        ...configTheme.fontFamily.MegaFonGraphikLCSemibold
      },
      '& .mfui-button__inner': {
        height: 43
      },
      '& button:not(:first-child)': {
        marginTop: 20
      }
    })
  }),
  { name: 'Modal' }
);
