//#region imports
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel } from '@material-ui/core';
import flow from 'lodash/flow';
import { Button } from '@megafon/ui-core';

import { INextWeek } from 'app/entities/diet/program/diet.program.reducer';
import { ContainerNotification } from 'app/containers/container.notification';

import ProgramMenuByDayBlock from 'app/pages/shared/diet/program/menu.day/menu.day';
import MyProgramWorkout from 'app/pages/shared/diet/program/workout/workout';
import AdviceDiet from 'app/pages/shared/diet/program/dietolog/dietolog';

import ModalNotifications from 'app/pages/shared/notification/modal.notifications';
import ModalWeekendProgram from './modal/modal.weekend.program';
import { ModalProgramLoader } from 'app/pages/desktop/diet/program/modal/modal.program.loader';
import { useStylesProgramLayout } from './program.layout.styles';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

const ProgramLayout = ({
  dataDays,
  defaultDayId,
  authenticated,
  itemsDay,
  getDietIndividualProgramDayById,
  dietDays,
  setDietDays,
  activeDayId,
  setActiveDayId,
  getDietIndividualProgram,
  postNextWeek,
  last,
  itemUserAttributes,
  containerNotification
}) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const styles = useStylesProgramLayout({ isMobile });

  const lastDayId = dietDays[dietDays.length - 1]?.id;

  const [isModalNotifications, setIsModalNotifications] = useState(false);
  const [openModalWeekend, setOpenModalWeekend] = useState(false);
  const [openModalProgramLoader, setOpenModalProgramLoader] = useState(false);

  const onCloseModalNotifications = () => setIsModalNotifications(false);
  const onOpenModalNotifications = () => setIsModalNotifications(true);

  useEffect(() => {
    if (itemsDay.length) {
      window.scrollTo(0, 0);
      setDietDays(itemsDay);
      setActiveDayId(defaultDayId || itemsDay[0].id);
    }
  }, [itemsDay, defaultDayId]);

  const handleClickNextDay = () => {
    const activeIndexDay = dietDays.findIndex(item => item.id === activeDayId);
    const curId = dietDays[activeIndexDay + 1]?.id;
    setActiveDayId(prev => (activeIndexDay === dietDays.length - 1 ? prev : curId));
    window.scrollTo(0, 0);
    if (itemsDay.length && curId) {
      getDietIndividualProgramDayById(curId);
    }
  };

  const handleClickCompleteWeek = () => {
    setOpenModalWeekend(true);
  };

  const handleClickNewWeek = async (data: INextWeek) => {
    await postNextWeek(data);
    last && setOpenModalProgramLoader(true);
    getDietIndividualProgram();
    setOpenModalWeekend(false);
  };

  return (
    <>
      <ModalWeekendProgram
        openModalWeekend={ openModalWeekend }
        setOpenModalWeekend={ setOpenModalWeekend }
        onCompleteClick={ postNextWeek }
        onNewWeekClick={ handleClickNewWeek }
        propState={ itemUserAttributes }
      />
      { openModalProgramLoader && (
        <ModalProgramLoader isModalProgramLoader={ openModalProgramLoader } setModalProgramLoader={ setOpenModalProgramLoader } />
      ) }
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <ProgramMenuByDayBlock />
        <MyProgramWorkout activeDayId={ activeDayId }/>
        { dataDays !== null && dataDays.adviceVideoUrl !== undefined && dataDays.adviceVideoUrl !== '' ? (
          <AdviceDiet adviceVideoPreview={ dataDays?.adviceVideoPreview } activeDayId={ activeDayId }/>
        ) : null }

        <Button
          className={ `${ styles.button } ${ styles.btnNextDay }` }
          onClick={ activeDayId === lastDayId ? handleClickCompleteWeek : handleClickNextDay }
        >
          { activeDayId === lastDayId ? t('individualProgram.weekend') : t('individualProgram.nextDay') }
        </Button>
        <Button
          type="outline"
          className={ `${ styles.button } ${ styles.setReminders }` }
          onClick={ onOpenModalNotifications }
        >
          { t(`individualProgram.${containerNotification.items.length > 0 ? 'reminders' : 'setReminders'}`) }{ ' ' }
        </Button>
      </Grid>
      <ModalNotifications
        authenticated={ authenticated }
        open={ isModalNotifications }
        onClose={ onCloseModalNotifications }
        renderCheckBox={ ({ value, checked, control, label, onClick }) => (
          <FormControlLabel value={ value } checked={ checked } control={ control } label={ label } onClick={ onClick } />
        ) }
      />
    </>
  );
};

export default flow([ContainerNotification])(ProgramLayout);
