import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { configTheme } from 'app/configs/theme/config.theme';

export default makeStyles(theme =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
      maxWidth: 1440,
      [theme.breakpoints.up(configTheme.breakpoints.values.mdx)]: {
        paddingLeft: 50,
        paddingRight: 50
      }
    }
  })
);
