//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';
import { Button, Header, Paragraph } from '@megafon/ui-core';

import {
  TCardVerticalProps
} from '../card.vertical.models';
import stylesCardVerticalArticle from './card.vertical.article.styles';
import Likes from 'app/components/shared/likes/likes.index';
import Badge from 'app/components/shared/badge/badge.index';
import { placeholder, cloneEl } from 'app/utils/utils.accessories';
//#endregion

const CardVerticalArticle: FC<TCardVerticalProps> = props => {
  const {
    className,
    size,
    title,
    button = {},
    badge = {},
    likes = {},
    src,
    overlay,
    onClick,
    desc
  } = props;

  const { visible: btnVisible = true, ...btnProps } = button;
  const { visible: likesVisible = true, ...likesProps } = likes;
  const { visible: badgeVisible = true, ...badgeProps } = badge;

  const { t } = useTranslation();
  const styles = stylesCardVerticalArticle();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isFunction(onClick)) onClick(e);
  };

  return (
    <div className={ classnames(className, styles.root, {
      [styles[size]]: size
    }) }
         onClick={ handleClick }
    >
      <div className={ styles.picture }>
        <img
          src={ src ?? placeholder }
          alt={ title ?? t('components.shared.card.imgAltDefault') }
        />
        { overlay && <div className={ styles.overlay }>{ cloneEl(overlay, size) }</div> }
      </div>
      <div className={ styles.content }>
        <div>
          <Header className={ styles.title } as={ 'h5' }>
            { title ?? t('components.shared.card.titleDefault') }
          </Header>
          { desc &&
              <Paragraph className={ styles.desc }>
                { desc }
              </Paragraph>
          }
        </div>
        <div>
          <Button className={ styles.button } fullWidth { ...btnProps }>
            { button?.children }
          </Button>
      <div className={ styles.footer }>
        <span className={ styles.badge }>{ badgeVisible && <Badge size={ size } { ...badgeProps } /> }</span>
        <span className={ styles.likes }>{ likesVisible && <Likes size={ size } { ...likesProps } /> }</span>
      </div>
        </div>
      </div>
    </div>
  );
};

CardVerticalArticle.displayName = 'CardVerticalArticle';

export default CardVerticalArticle;
