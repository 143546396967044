import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from '@megafon/ui-core';
import IconKey from 'app/components/shared/icons/key/icon.key';

import useBreakpoints from 'app/hooks/use.breakpoints';
import styles from './toolbar.styles';
import IconUser from 'app/components/shared/icons/user/icon.user';

interface IToolbarProps {
  isAuthenticate: boolean;
  handleClick: () => void;
}

const Toolbar: FC<IToolbarProps> = ({ isAuthenticate, handleClick }) => {
  const { t } = useTranslation();
  const { isTabletMenu, isMobile } = useBreakpoints();
  const classes = styles({ isMobile });

  return (
    <div className={ classes.container } onClick={ handleClick }>
      { isAuthenticate ? <IconUser className={ classes.icon }/> : <IconKey className={ classes.icon }/> }
      { !isTabletMenu &&
        <Paragraph className={ classes.label }>
          { t('common.profile') }
        </Paragraph>
      }
    </div>
  );
};

Toolbar.displayName = 'Toolbar';

export default Toolbar;
