//#region imports
import React from 'react';
import isFunction from 'lodash/isFunction';
import flow from 'lodash/fp/flow';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ModalsContainer, IModalsContainer } from 'app/containers/modals.container';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';

import ModalSubscribe from 'app/components/shared/modal/subscribe/modal.subscribe';
import ModalNotification from 'app/components/shared/modal/notification/modal.notification';
import ModalConfirm from 'app/components/shared/modal/confirm/modal.confirm';

import { ModalTypes } from 'app/entities/modals/modals.reducer';

import { ESubscribeStatus } from 'app/models/model.subscription';
import { EDialogConfirmType } from 'app/models/enums';
import useBreakpoints from 'app/hooks/use.breakpoints';
import { ContainerLogging, TContainerLoggingProps } from 'app/containers/container.logging';
//#endregion

export interface IModalsProps {
  mobile?: boolean;
  modalsContainer?: IModalsContainer;
  containerSubscribe?: IContainerSubscribeProps;
  containerUser?: IUserContainerProps;
  containerLogging?: TContainerLoggingProps;
}

const Modals = (props: IModalsProps) => {
  const { actions, payload, show, type } = props.modalsContainer;
  const { subscriptions, actions: { setSubscription, resetProp }, status, error } = props.containerSubscribe;
  const { data: { msisdn } } = props.containerUser;
  const { actions: { logSubscriptionChange } } = props.containerLogging;
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const { subscribeId, handleClose, handleCancel } = payload ?? {};
  const currentSubscription = subscribeId && subscriptions?.length && subscriptions.find(s => s.id === subscribeId);
  const name = currentSubscription?.name;
  const { pathname } = useLocation();

  const handleModalCancel = () => {
    if (isFunction(handleCancel)) handleCancel();
    actions.closeModal();
  };

  const handleModalSuccess = () => {
    if (isFunction(handleClose)) handleClose();
    actions.closeModal();
  };

  const onActivateSubscribe = () => {
    logSubscriptionChange(true, currentSubscription.id, pathname);
    setSubscription(true, currentSubscription.id);
    handleModalSuccess();
    actions.successActivateModal(currentSubscription.id);
  };

  const onDeactivateSubscribe = () => {
    logSubscriptionChange(false, currentSubscription.id, pathname);
    setSubscription(false, currentSubscription.id);
    handleModalSuccess();
  };

  const modalUnsubscribeButtons = {
    cancel: { onClick: handleModalCancel, title: t('dialog.confirm.unsubscribe.close') },
    confirm: { onClick: onDeactivateSubscribe, title: t('dialog.confirm.unsubscribe.deactivate') }
  };

  return (
    <>
      <ModalSubscribe
        open={ (error.change !== ESubscribeStatus.SUCCESS) && type === ModalTypes.subscribe && show }
        title={ currentSubscription?.name }
        phone={ msisdn }
        subscribeCondition={ currentSubscription?.subscribeCondition }
        onClose={ handleModalCancel }
        onClick={ onActivateSubscribe }
        size={ isMobile ? 'xs' : 'sm' }
        hasCloseButton
        withCancelButton
      />
      <ModalNotification
        type={ ESubscribeStatus.NO_MONEY }
        open={ type === ModalTypes.no_money && show }
        onClose={ handleModalCancel }
        button={ {
          cancel: { onClick: handleModalCancel, title: t('subscribe.error.no_money.close') }
        } }
        size={ isMobile ? 'xs' : 'sm' }
        title={ t('subscribe.error.no_money.header') }
        desc={ t('subscribe.error.no_money.text') }
        hasCloseButton
      />
      <ModalNotification
        type={ ESubscribeStatus.FAIL }
        open={ error.change === ESubscribeStatus.FAIL }
        onClose={ () => resetProp('change') }
        title={ t('subscribe.error.fail.header') }
        desc={ t('subscribe.error.fail.text') }
        button={ {
          cancel: { onClick: handleModalCancel, title: t('subscribe.error.fail.close') }
        } }
        size={ isMobile ? 'xs' : 'sm' }
        hasCloseButton
      />
      <ModalConfirm
        open={ show && type === ModalTypes.unsubscribe }
        title={ t('dialog.confirm.unsubscribe.header') }
        desc={ t(`dialog.confirm.unsubscribe.text`, { name }) }
        button={ modalUnsubscribeButtons }
        confirmType={ EDialogConfirmType.UNSUBSCRIBE }
        onClose={ handleModalCancel }
        onClick={ onDeactivateSubscribe }
        size={ isMobile ? 'xs' : 'sm' }
        hasCloseButton
      />
    </>
  );
};

export default flow([ContainerUser, ContainerSubscribe, ModalsContainer, ContainerLogging])(Modals);
