//#region imports
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

interface IProps {
  isNotDesktop: boolean;
}

export const stylesHeadline = makeStyles<Theme, IProps>(theme =>
  createStyles({
    container: {
      margin: ({ isNotDesktop }) => isNotDesktop ? 0 : '0 0 12px 0'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 21 : 36,
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold
    },
    icon: {
      height: ({ isNotDesktop }) => isNotDesktop ? 16 : 30,
      margin: ({ isNotDesktop }) => isNotDesktop ? '3px 0 0 10px' : '5px 0 0 30px'
    },
    title: {
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 14 : 17,
      color: configTheme.megafonColors.basic.black.normal,
      textDecoration: 'none',
      cursor: 'pointer'
    }
  }),
  { name: 'Headline' }
);
