import { makeStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

export const stylesMenu = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: configTheme.layers.ahead
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 80
  },
  toolbar: {
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 30,
    listStyle: 'none',
    cursor: 'pointer'
  }
}));
