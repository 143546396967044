import React, { useState, FC } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { InputAdornment, InputProps } from '@material-ui/core';
import classnames from 'classnames';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';

import { configTheme } from 'app/configs/theme/config.theme';

// configTheme
const color = {
  red: '#F53D5C',
  gray: '#D5D8DD',
  dark: '#9297A0'
};

const useStylesInput = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 40,
      border: error => `1px solid ${error ? configTheme.megafonColors.systems.fury : configTheme.megafonColors.border.default}`,
      borderRadius: 0,
      overflow: 'hidden',
      fontFamily: 'inherit',
      backgroundColor: configTheme.colors.basic.secondary,
      '& .MuiFormLabel-root.Mui-focused': {
        color: color.dark
      },
      '&:hover': {
        backgroundColor: configTheme.colors.basic.secondary
      },
      '&$focused': {
        backgroundColor: configTheme.colors.basic.secondary,
        boxShadow: configTheme.colors.basic.secondary
      },
      '& input::-webkit-inner-spin-button': {
        display: 'none'
      },
      '& .MuiFilledInput-input': {
        marginTop: 3,
        padding: '10px 0px 10px 12px'
      },
      '& .MuiInputAdornment-positionEnd': {
        marginTop: 6,
        marginLeft: 0
      }
    },
    label: {
      '& .MuiFormLabel-root': {
        marginTop: 2,
        color: configTheme.colorsNew.text.light.default,
        fontFamily: 'inherit',
        transform: 'translate(12px, 12px) scale(1)'
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: `${color.dark}!important`,
        display: 'none'
      },
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        display: 'none'
      },
      '& .MuiFormHelperText-root': {
        width: 310,
        marginTop: 10,
        fontSize: 14,
        letterSpacing: '-0.3px',
        color: configTheme.colorsNew.text.error.default,
        fontFamily: 'inherit'
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginLeft: 0
      },
      '& .MuiFormHelperText-contained': {
        marginRight: 0,
        marginLeft: 0
      }
    },
    focused: {}
  })
);

type TInputProps = Partial<OutlinedInputProps> | Partial<InputProps>;

type ITextFieldProps = TextFieldProps & {
  unit?: string
};

export const CustomTextField: FC<ITextFieldProps> = props => {
  const { error, className, InputProps: inputProps = {}, unit, ...rest } = props;
  const classes = useStylesInput(error);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextField
      className={ classnames(className, classes.label) }
      InputProps={ {
        ...inputProps,
        classes,
        disableUnderline: true,
        endAdornment: <InputAdornment position="end">{ !isFocused && props.value ? unit : '' }</InputAdornment>
      } as TInputProps }
      error={ error }
      onFocus={ () => setIsFocused(true) }
      onBlur={ () => setIsFocused(false) }
      { ...rest }
    />
  );
};
