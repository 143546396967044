//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@material-ui/core';
import isFunction from 'lodash/isFunction';
import classnames from 'classnames';
import { Button } from '@megafon/ui-core';

import stylesModal from 'app/components/shared/modal/modal.styles';
import Modal from 'app/components/shared/modal/modal.index';
import { IModalProps } from 'app/components/shared/modal/modal.models';
import { ESubscribeStatus } from 'app/models/model.subscription';
import { EDialogConfirmType } from 'app/models/enums';
//#endregion

export interface IModalButton {
  title?: string;
  type?: ESubscribeStatus;
  onClick?: () => void;
  onClose?: () => void;
}

export interface IModalConfirmProps extends IModalProps {
  type?: ESubscribeStatus;
  confirmType?: EDialogConfirmType;
  desc?: string;
  title?: string;
  name?: string;
  button?: {
    cancel?: IModalButton;
    confirm?: IModalButton;
  };
}

const ModalConfirm: FC<IModalConfirmProps> = props => {
  const {
    className,
    size,
    desc,
    button,
    onClose,
    onClick
  } = props;

  const { t } = useTranslation();
  const styles = stylesModal({ size });

  const handleClick = (): void => {
    if (isFunction(onClick)) onClick();
  };

  const handleClose = (): void => {
    if (isFunction(onClose)) onClose();
  };

  return (
    <Modal { ...props }>
      <DialogContent className={ classnames(className, {
        [styles[size]]: size
      }) }>
        <div className={ styles.desc } dangerouslySetInnerHTML={ { __html: desc } }/>
      </DialogContent>
    <DialogActions className={ styles.content }>
      <div className={ styles.buttons }>
        <Button
          onClick={ handleClose }
        >
          { button?.cancel?.title || t('components.shared.modal.confirm.button.cancel') }
        </Button>
        <Button
          type="outline"
          onClick={ handleClick }
        >
          { button?.confirm?.title || t('components.shared.modal.confirm.button.submit') }
        </Button>
      </div>
    </DialogActions>
    </Modal>
  );
};

ModalConfirm.displayName = 'ModalConfirm';

export default ModalConfirm;
