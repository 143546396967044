import React, { FC } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

interface IInputMaskProps extends MaskedInputProps {
  inputRef: any;
}

export const InputMaskMinutes: FC<IInputMaskProps> = props => {
  const { inputRef, mask, ...other } = props;

  const minutesMask = (value: string) => {
    const chars = value.split('');

    const minutes = [ /[0-5]/, /[0-9]/ ];

    return [minutes[0], minutes[1]];
};

  return (
    <MaskedInput
      { ...other }
      ref={ ref => {
        inputRef(ref ? ref.inputElement : null);
      } }
      mask={ minutesMask }
      placeholderChar={ '\u2000' }
    />
  );
};
