//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import flow from 'lodash/flow';

import ModalConfirm from 'app/components/shared/modal/confirm/modal.confirm';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { EDialogConfirmType } from 'app/models/enums';

import { logoutExternal } from 'app/utils/auth.external';
import useBreakpoints from 'app/hooks/use.breakpoints';
import { ContainerLogging, TContainerLoggingProps } from 'app/containers/container.logging';
//#endregion

interface IContainerLoginProps {
  containerUser: IUserContainerProps;
  containerLogging: TContainerLoggingProps;
}

const ModalLogout: FC<IContainerLoginProps> = ({ containerUser, containerLogging }) => {
  const {
    meta: { modal: { logout } },
    actions: authActions
  } = containerUser;
  const { actions: { logLogout } } = containerLogging;

  const history = useHistory();
  const { pathname } = useLocation();
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();

  const handleConfirmLogOff = () => {
    const response = logLogout(pathname);
    response.then(res => {
      if (res.status === 200) {
        authActions.toggleModalLogout();
        logoutExternal();
        history.push('/');
      }
    });
  };

  const handleCancel = () => authActions.toggleModalLogout();

  const modalLogoutButtons = {
    cancel: { onClick: handleCancel, title: t('dialog.confirm.logout.close') },
    confirm: { onClick: handleConfirmLogOff, title: t('dialog.confirm.logout.deactivate') }
  };

  return (
    <ModalConfirm
      open={ logout }
      confirmType={ EDialogConfirmType.LOGOUT }
      button={ modalLogoutButtons }
      onClick={ handleConfirmLogOff }
      onClose={ handleCancel }
      desc={ t('dialog.confirm.logout.text') }
      title={ t('dialog.confirm.logout.header') }
      size={ isMobile ? 'xs' : 'sm' }
      hasCloseButton
    />
  );
};

ModalLogout.displayName = 'ModalLogout';

export default flow([ContainerUser, ContainerLogging])(ModalLogout);
