import { EProtocol, EMenuItemType } from 'app/models/enums';
import { IMenu } from 'app/models/model.menu';

export const isExternalLink = (value: string): boolean => value.includes(EProtocol.HTTP) || value.includes(EProtocol.HTTP);

export const getLink = (value: string, type: EMenuItemType): string => type === EMenuItemType.ANCHOR ? `/#${value}` : value;

export const isActiveLink = (link: string, location: string, hash: string): boolean => {
  if ((hash !== '') && (link !== location)) return `/${hash}` === link;
  return link.split('/')[1] === location.split('/')[1];
};

export const getMenuItem = ({ name, value, menuItemType }: IMenu, location: string, hash: string) => {
  const link = getLink(value, menuItemType);

  return ({
    name,
    link,
    isExternal: isExternalLink(value),
    isBlank: isExternalLink(value),
    isActive: isActiveLink(link, location, hash),
    isAnchor: menuItemType === EMenuItemType.ANCHOR
  });
};
