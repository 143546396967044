//#region imports
import React, { FC, memo, forwardRef } from 'react';
import { isMobile } from 'mobile-device-detect';
import cx from 'classnames';

import stylesPlayerWrapper from './player.wrapper.styles';
import { IPlayerWrapperProps } from './player.wrapper.models';
//#endregion

const PlayerWrapper: FC<IPlayerWrapperProps> = memo(
  forwardRef<HTMLDivElement, IPlayerWrapperProps>((props: IPlayerWrapperProps, ref: any) => {
    const { classes, spacing, children, className } = props;
    const styles = stylesPlayerWrapper({ classes, spacing, mobile: isMobile });

    return (
      <div
        ref={ ref }
        className={ cx(className, styles.root) }
      >
        <div className={ styles.shape }>{ children }</div>
      </div>
    );
  })
);
PlayerWrapper.displayName = 'PlayerWrapper';

PlayerWrapper.defaultProps = {} as IPlayerWrapperProps;

export default PlayerWrapper;
