//#region imports
import React, { FC, memo, forwardRef } from 'react';
import { StandardProps, makeStyles, createStyles } from '@material-ui/core';
import classnames from 'classnames';
import isUndefined from 'lodash/isUndefined';

import { configTheme } from 'app/configs/theme/config.theme';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

export const EWrapperColor = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  surface: 'surface',
  light: 'light'
} as const;

export type WrapperColors = typeof EWrapperColor[keyof typeof EWrapperColor];

export const EWrapperClassKey = {
  root: 'root',
  shape: 'shape',
  mobile: 'mobile',
  colorDefault: 'colorDefault',
  colorPrimary: 'colorPrimary',
  colorSecondary: 'colorSecondary',
  colorSurface: 'colorSurface'
} as const;

export type WrapperClasses = typeof EWrapperClassKey[keyof typeof EWrapperClassKey];

export interface IWrapperProps {
  color?: WrapperColors;
  htmlColor?: string;
  spacing?: number | string;
  offset?: number | string;
}

export type WrapperProps = StandardProps<IWrapperProps, WrapperClasses>;

export interface IWrapperStylesProps {
  classes?: WrapperProps['classes'];
  spacing?: WrapperProps['spacing'];
  offset?: WrapperProps['offset'];
  mobile?: boolean;
  tablet?: boolean;
}

export const stylesWrapper = makeStyles<WrapperClasses, IWrapperStylesProps>(
  createStyles({
    [EWrapperClassKey.root]: {
      width: '100%'
    },
    [EWrapperClassKey.mobile]: {},

    [EWrapperClassKey.colorDefault]: {
      backgroundColor: 'transparent'
    },

    [EWrapperClassKey.colorPrimary]: {
      backgroundColor: configTheme.colorsNew.background.primary.default
    },

    [EWrapperClassKey.colorSecondary]: {
      backgroundColor: configTheme.colorsNew.background.white.default
    },

    [EWrapperClassKey.colorSurface]: {
      backgroundColor: configTheme.colorsNew.background.light.default
    },

    [EWrapperClassKey.shape]: {
      minWidth: ({ mobile, tablet }) => (!mobile ? 728 : 'unset'),
      maxWidth: ({ mobile }) => (!mobile ? 1440 : undefined),
      width: ({ mobile }) => (!mobile ? '100%' : undefined),
      margin: ({ mobile, offset }) => (!isUndefined(offset) ? offset : !mobile ? 'auto' : undefined),
      padding: ({ tablet, mobile, spacing }) => (!isUndefined(spacing) ? spacing : (tablet || mobile) ? '0 20px' : '0 50px'),
      boxSizing: 'border-box'
    }
  }),
  { name: 'Wrapper' }
);

const Wrapper: FC<WrapperProps> = memo<WrapperProps>(
  forwardRef<HTMLDivElement, WrapperProps>((props, ref) => {
    const { className, classes, style, color, htmlColor, spacing, offset, children } = props;
    const { isMobile, isTablet } = useBreakpoints();
    const styles = stylesWrapper({ classes, spacing, offset, mobile: isMobile, tablet: isTablet });

    return (
      <div
        ref={ ref }
        className={ classnames(className, styles[EWrapperClassKey.root], {
          [styles[EWrapperClassKey.colorDefault]]: !htmlColor && color === EWrapperColor.default,
          [styles[EWrapperClassKey.colorPrimary]]: !htmlColor && color === EWrapperColor.primary,
          [styles[EWrapperClassKey.colorSecondary]]: !htmlColor && color === EWrapperColor.secondary,
          [styles[EWrapperClassKey.colorSurface]]: !htmlColor && color === EWrapperColor.surface
        }) }
        style={ { color: htmlColor, ...style } }
      >
        <div className={ styles[EWrapperClassKey.shape] }>{ children }</div>
      </div>
    );
  })
);
Wrapper.displayName = 'Wrapper';

Wrapper.defaultProps = { color: EWrapperColor.default } as WrapperProps;

export default Wrapper;
