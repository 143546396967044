import React, { FC } from 'react';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

import stylesMenuModalList from './menu.modal.list.styles';

import { IMenuItem } from 'app/models/model.menu';
import { IMenuMobileModalProps } from '../menu.modal';

type TProps = Pick<IMenuMobileModalProps, 'items' | 'toggleMenu'>;

const MobileModalList: FC<TProps> = ({ items, toggleMenu }) => {

  const classes = stylesMenuModalList();

  const renderItem = ({ name, link, isExternal, isAnchor, isBlank, isActive }: IMenuItem) => {
    if (isAnchor) return <a className={ classes.listItemLink } href={ link }>{ name }</a>;

    if (isExternal) return <a className={ classes.listItemLink } href={ link } target={ isBlank && '_blank' }>{ name }</a>;
    return <Link className={ classNames(classes.listItemLink, { 'exact': isActive }) } to={ link } onClick={ toggleMenu }>{ name }</Link>;
  };

  return (
    <ul className={ classes.root }>
      {
        Array.isArray(items) && items.length && items.map((item, index) => (
          <li className={ classes.listItem } key={ `${index}-${item.name}` }>
            { renderItem(item) }
          </li>
        ))
      }
    </ul>
  );
};

MobileModalList.displayName = 'MobileModalList';

export default MobileModalList;
