//#region imports
import React, { FC, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header, Button } from '@megafon/ui-core';

import IconDocument from 'app/components/shared/icons/document/icon.document';
import IconMagnifier from 'app/components/shared/icons/magnifier/icon.magnifier';
import IconChicken from 'app/components/shared/icons/chicken/icon.chicken';
import IconThumb from 'app/components/shared/icons/thumb/icon.thumb';
import IconCup from 'app/components/shared/icons/cup/icon.cup';
import HeadTitle from 'app/components/shared/headtitle/head.title.index';

import ModalDietProgram from 'app/pages/desktop/diet/program/program.index';
import userOpportunitiesCheck from 'app/utils/util.opportunities.check';
import { stylesDesktopDiet } from 'app/pages/desktop/diet/diet.styles';
import ImgDietDesktopFull from 'app/assets/img/diet.desktop.full.png';

import { ContainerDietIndividualProgram, IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { IContainerSubscribeProps, ContainerSubscribe } from 'app/containers/container.subscribe';
import Wrapper from 'app/components/wrapper/wrapper.index';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

const DesktopDiet: FC<{
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  containerUser: IUserContainerProps;
  containerSubscribe: IContainerSubscribeProps;
}> = ({ containerDietIndividualProgram, containerUser, containerSubscribe }) => {
  const { meta: { modal: { login: isOpen } }, actions: userActions } = containerUser;
  const {
    itemUserAttributes: userAttributes, programStatus, progressStatus, actions: programActions
  } = containerDietIndividualProgram;
  const { isNotDesktop, isMobile } = useBreakpoints();
  const styles = stylesDesktopDiet({ isNotDesktop, isMobile });
  const [isModalCalcProgram, setModalCalcProgram] = useState(false);
  const [isDeclineSetParams, setIsDeclineSetParams] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const nullishItemUserAttributes = !Object.values(userAttributes)?.length
    || (!userAttributes?.age && !userAttributes?.weight && !userAttributes?.height);

  const desktopItems: Array<{
    number: number;
    title: string;
    icon: any;
  }> = [
    { number: 1, title: t('diet.params'), icon: <IconDocument /> },
    { number: 2, title: t('diet.program'), icon: <IconMagnifier /> },
    { number: 3, title: t('diet.menu'), icon: <IconChicken /> },
    { number: 4, title: t('diet.workout'), icon: <IconThumb fill="#00B858" /> },
    { number: 5, title: t('diet.result'), icon: <IconCup /> }
  ];

  const mobileItems: Array<{
    number: number;
    title: string;
  }> = [
    { number: 1, title: t('diet.paramsMobile') },
    { number: 2, title: t('diet.programMobile') },
    { number: 3, title: t('diet.resultMobile') }
  ];

  useEffect(() => {
    if (containerUser?.data?.authenticated) {
      programActions.fetchDietItem();
    }
  }, [containerUser?.data?.authenticated]);

  useEffect(() => {
    return () => {
      setModalCalcProgram(false);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setModalCalcProgram(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isModalCalcProgram && isDeclineSetParams) {
      userOpportunitiesCheck({
        subscribe: containerSubscribe.subscriptions.find(sub => sub?.id === programStatus.id),
        history,
        containerUser,
        isNullishUserAttributes: nullishItemUserAttributes,
        isDeclineSetParams: true
      });
    }
  }, [isModalCalcProgram]);

  useEffect(() => {
    userOpportunitiesCheck({
      subscribe: containerSubscribe.subscriptions.find(sub => sub?.id === programStatus.id),
      history,
      containerUser,
      isNullishUserAttributes: nullishItemUserAttributes
    });
  }, [window.location]);

  const handleLoginModal = () => {
    userActions.toggleModalLogin({ isExtended: true });
  };

  const handleHeadTitle = () => {
    history.push('/');
  };

  return (
    <>
      { isModalCalcProgram && (
        <ModalDietProgram
          isModalCalcProgram={ isModalCalcProgram }
          setModalCalcProgram={ setModalCalcProgram }
          setIsDeclineSetParams={ setIsDeclineSetParams }
          propsState={ userAttributes }
          loading={ progressStatus }
          save={ programActions.saveDietItem }
          authenticated={ containerUser?.data?.authenticated }
          openLoginModal={ handleLoginModal }
          isNullishUserAttributes={ nullishItemUserAttributes }
          isNullishProgram={ !Object.values(programStatus)?.length }
        />
      ) }
      <HeadTitle callback={ handleHeadTitle }>
        <Header as="h2">{ t('header.diet') }</Header>
      </HeadTitle>
      <Wrapper>
        <Box className={ styles.root }>
          <div className={ styles.banner }>
            <img src={ ImgDietDesktopFull } alt={ t('header.diet') } />
          </div>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Header className={ styles.title }>
              { t('diet.promo') }
            </Header>
            <ul className={ styles.steps }>
              { !isMobile && desktopItems.map((el, i) => (
                <li className={ styles.step } key={ el.number }>
                  <span className={ styles.stepIcon }>{ el.icon }</span>
                  <span className={ styles.stepTitle }>{ el.title }</span>
                </li>
              )) }
              { isMobile && mobileItems.map((el, i) => (
                <li className={ styles.step } key={ el.number }>
                  <span className={ styles.stepNumber }>{ el.number }</span>
                  <span className={ styles.stepTitle }>{ el.title }</span>
                </li>
              )) }
            </ul>
          </Grid>
          <div className={ styles.button }>
            <Button theme="purple" onClick={ e => setModalCalcProgram(true) }>
              { t('common.startNow') }
            </Button>
          </div>
         </Box>
       </Wrapper>
    </>
  );
};

export default ContainerDietIndividualProgram(ContainerSubscribe(ContainerUser(DesktopDiet)));
