//#region imports
import React, {
  forwardRef,
  useRef,
  useEffect,
  useState
} from 'react';
//#endregion

interface IListHorizontalOuterState {
  isScrolling: boolean;
  startX: number;
  scrollLeft: number;
}

const ListHorizontalOuter = forwardRef((props, reactWindowRef: any) => {
  const localRef = useRef<HTMLDivElement>();

  const refSetter = (ref: HTMLDivElement) => {
    reactWindowRef(ref);
    localRef.current = ref;
  };

  const [state, setState] = useState<IListHorizontalOuterState>({
    isScrolling: false,
    startX: 0,
    scrollLeft: 0
  });

  useEffect(() => {
    const list = localRef?.current;
    list.addEventListener('mousedown', handleMouseDown);
    list.addEventListener('mouseup', handleMouseUp);
    list.addEventListener('mousemove', handleMouseMove);
    list.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      list.removeEventListener('mousedown', handleMouseDown);
      list.removeEventListener('mouseup', handleMouseUp);
      list.removeEventListener('mousemove', handleMouseMove);
      list.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  const handleMouseLeave = e => {
    if (!localRef?.current) return;
    e.preventDefault();
    setState({ ...state, isScrolling: false });
  };

  const handleMouseDown = e => {
    if (!localRef?.current) return;
    e.preventDefault();
    setState({ ...state, isScrolling: true, startX: e.clientX - localRef.current.offsetLeft, scrollLeft: localRef.current.scrollLeft });
  };

  const handleMouseUp = e => {
    if (!localRef?.current) return;
    e.preventDefault();
    setState({ ...state, isScrolling: false });
  };

  const handleMouseMove = e => {
    if (!state.isScrolling || !localRef?.current) return;
    e.preventDefault();
    const { isScrolling, startX, scrollLeft } = state;

    if (isScrolling) {
      const x = e.clientX - localRef.current.offsetLeft;
      const step = (x - startX) * 1.5;
      localRef.current.scrollLeft = scrollLeft - step;
    }
  };

  return (
    <div
      onMouseMove={ handleMouseMove }
      onMouseUp={ handleMouseUp }
      onMouseDown={ handleMouseDown }
      onMouseLeave={ handleMouseLeave }
      ref={ refSetter }
      { ...props }
    />
  );
});

export default ListHorizontalOuter;
