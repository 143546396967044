import React, { FC, forwardRef } from 'react';

import { Modal } from '@material-ui/core';

import { stylesModalCalcProgram } from 'app/pages/desktop/diet/program/program.style';
import { ProgramLoader } from 'app/pages/desktop/diet/program/components/program.loader';

interface IModalProgramLoaderProps {
  isModalProgramLoader: boolean;
  setModalProgramLoader: (boolean) => void;
}

export const ModalProgramLoader: FC<IModalProgramLoaderProps> = forwardRef(({ isModalProgramLoader, setModalProgramLoader }, ref) => {
  const styles = stylesModalCalcProgram({});

  return (
    <>
      <div className={ styles.root }>
        <Modal open={ isModalProgramLoader } className={ styles.modal }>
          <ProgramLoader setModalCalcProgram={ setModalProgramLoader }/>
        </Modal>
      </div>
    </>
  );
});
