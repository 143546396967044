import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { ELoadStatus } from 'app/models/shared.model';
import { IUserInitialState, EPostConfirmStatus } from 'app/entities/user/user.model';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';
import get from 'lodash/get';

import { USER_ACTIONS } from './user.actions';

const initialState: IUserInitialState = {
  data: {
    msisdn: null,
    token: null,
    authenticated: false
  },
  meta: {
    status: null,
    modal: {
      login: false,
      logout: false,
      extended: false
    }
  },
  request: {
    status: {
      confirm: ELoadStatus.not_ready,
      request: ELoadStatus.not_ready,
      checkUser: ELoadStatus.not_ready
    },
    error: {
      confirm: null as Error,
      request: null as Error,
      checkUser: null as Error
    }
  }
};

export type TUserState = Readonly<typeof initialState>;
export default (state: TUserState = initialState, action): TUserState => {
  const { type, payload, meta } = action;

  switch (type) {
    case REQUEST(USER_ACTIONS.REQUEST):
      return flow([set('request.status.request', ELoadStatus.loading)])(state);
    case FAILURE(USER_ACTIONS.REQUEST):
      return flow([
        set('request.status.request', ELoadStatus.error),
        set('request.error.request', payload)
      ])(state);
    case SUCCESS(USER_ACTIONS.REQUEST):
      return flow([set('request.status.request', ELoadStatus.ready)])(state);

    case REQUEST(USER_ACTIONS.CONFIRM):
      return flow([set('request.status.confirm', ELoadStatus.loading)])(state);
    case FAILURE(USER_ACTIONS.CONFIRM):
      return flow([
        set('request.status.confirm', ELoadStatus.error),
        set('request.error.confirm', payload)
      ])(state);
    case SUCCESS(USER_ACTIONS.CONFIRM):
      return flow([
        set('request.status.confirm', ELoadStatus.ready),
        set('data.token', payload.data?.token),
        set('data.authenticated', payload.data?.status === EPostConfirmStatus.OK),
        set('data.msisdn', meta?.msisdn),
        set('meta.status', payload.data?.status),
        set('meta.modal.login', payload.data.status !== EPostConfirmStatus.OK)
      ])(state);

    case REQUEST(USER_ACTIONS.CHECK):
      return flow([set('request.status.checkUser', ELoadStatus.loading)])(state);
    case FAILURE(USER_ACTIONS.CHECK):
      return flow([
        set('request.status.checkUser', ELoadStatus.error),
        set('request.error.checkUser', new Error('Не удалось подключиться.'))
      ])(state);
    case SUCCESS(USER_ACTIONS.CHECK):
      return flow([
        set('request.status.checkUser', ELoadStatus.ready),
        set('data', payload.data)
      ])(state);

    case USER_ACTIONS.TOGGLE_MODAL_LOGIN:
      return flow([
        set('meta.modal.login', !state.meta.modal.login),
        set('meta.modal.extended', meta?.extended)
      ])(state);

    case USER_ACTIONS.TOGGLE_MODAL_LOGOUT:
      return flow([set('meta.modal.logout', !state.meta.modal.logout)])(state);

    case USER_ACTIONS.RESET_FIELDS:
      const fields = action.payload.map(f => (f = set(f, get(initialState, f))));
      return flow(fields)(state);

    default:
      return state;
  }
};
