//#region imports
import React, { CSSProperties, memo, FC, LegacyRef } from 'react';
import { FixedSizeList as List, areEqual, FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import ListHorizontalOuter from './list.horizontal.outer';
import stylesListHorizontal from './list.horizontal.styles';
import ColumnCard from './list.horizontal.column';
import { TListHorizontalProps } from './list.horizontal.models';
//#endregion

interface IListHorizontalScrollProps extends TListHorizontalProps {
  itemWidth?: number;
  listRef?: LegacyRef<FixedSizeList<Array<{ id: string; }>>>;
  handleGetResize?: (e) => void;
  handleItemsRerender?: (e) => void;
  handleItemsScroll?: (e) => void;
}

const CompletedList = props => {

  const {
    cardsPadding,
    size,
    cardAlign,
    cardType,
    listItems,
    listRef,
    styles,
    height,
    itemWidth,
    width,
    handleItemsRerender,
    handleItemsScroll,
    isTransparent,
    isScrolling
  } = props;

  const Column = memo(({ index, style }: { index: number, style: CSSProperties }) => {
    return (
      <ColumnCard index={ index } style={ style } padding={ cardsPadding } size={ size } align={ cardAlign } type={ cardType } arr={ listItems } isTransparent={ isTransparent }/>
    );
  }, areEqual);

  return (
    <List
      ref={ listRef }
      className={ styles.list }
      height={ height }
      itemData={ listItems }
      itemCount={ listItems?.length }
      itemSize={ itemWidth + cardsPadding }
      outerElementType={ ListHorizontalOuter }
      width={ width }
      layout="horizontal"
      onItemsRendered={ e => handleItemsRerender(e) }
      onScroll={ e => handleItemsScroll(e) }
    >
      { Column }
    </List>
  );

};

const ListHorizontalScroll: FC<IListHorizontalScrollProps> = ({ handleGetResize, ...rest }) => {

  const styles = stylesListHorizontal({});

  return (
    <>
      <AutoSizer onResize={ handleGetResize }>
        { ({ height, width }) => (
          <CompletedList
            styles={ styles }
            height={ height }
            width={ width }
            { ...rest }
          />
        ) }
      </AutoSizer>
    </>
  );
};

export default ListHorizontalScroll;
