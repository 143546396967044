import React, { FC } from 'react';

export interface INavbarInfinityArrowProps {
  disabled: boolean;
  onClick: () => void;
  className?: string;
}

const NavbarInfinityArrow: FC<INavbarInfinityArrowProps> = ({ children, disabled, onClick, className= '' }) => (
  <button disabled={ disabled } onClick={ onClick } className={ className }>
    { children }
  </button>
);

NavbarInfinityArrow.displayName = 'NavbarInfinityArrow';

export default NavbarInfinityArrow;
