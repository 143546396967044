import { makeStyles, createStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

export default makeStyles<unknown, { mobile?: boolean }>(
  createStyles({
    root: {},
    controlInput: {
      marginTop: 15,
      boxSizing: 'border-box',
      height: 42,
      padding: '10px 12px 8px 16px',
      border: `1px solid ${configTheme.colorsNew.border.input}`,
      borderRadius: 3,
      backgroundColor: configTheme.colorsNew.background.white.default,
      color: configTheme.colorsNew.text.dark.default,
      fontSize: 16,
      lineHeight: '20px',
      letterSpacing: '-0.3px',

      '&::-webkit-input-placeholder': { color: configTheme.colorsNew.text.grey.default, opacity: 1 },
      '&::-ms-input-placeholder': { color: configTheme.colorsNew.text.grey.default, opacity: 1 },
      '&::placeholder': { color: configTheme.colorsNew.text.grey.default, opacity: 1 },

      '&[type="number"]': { appearance: 'none', '-moz-appearance:': 'textfield' },
      '&[type="number"]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0 },
      '&[type="number"]::-webkit-inner-spin-button': { '-webkit-appearance': 'none', margin: 0 }
    },

    controlError: {
      color: configTheme.colorsNew.text.error.default,
      fontSize: 14,
      lineHeight: '17px',
      letterSpacing: '-0.3px'
    },

    resendCodeBtn: {
      cursor: 'pointer',
      display: 'block',
      width: 'auto',
      margin: '0 auto',
      marginTop: 10,
      padding: '10px 0 5px 0',
      outline: 0,
      border: '0 none',
      borderBottom: `1px dashed ${configTheme.colors.text.light.translucent}`,
      backgroundColor: 'transparent',
      color: configTheme.colors.text.light.translucent,
      fontSize: 14,
      lineHeight: '17px',
      letterSpacing: '-0.3px'
    },

    warning: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 30,
      fontSize: ({ mobile }) => mobile ? 12 : 14,
      color: configTheme.colorsNew.text.light.default,
      '& svg': {
        width: ({ mobile }) => mobile ? 20 : 25
      }
    },
    icon: {
      marginRight: 10
    },
    sendCodeAgain: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      margin: '15px 0 10px 0',
      cursor: 'pointer',
      '& svg': {
        marginRight: 5,
        width: 15,
        height: 15
      },
      '& span': {
        position: 'relative',
        fontSize: 14,
        lineHeight: 1,
        color: configTheme.megafonColors.basic.grey.default,
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: -3,
          borderBottom: `1px dashed ${ configTheme.megafonColors.basic.grey.default }`
        }
      }
    },
    desc: {
      fontSize: ({ mobile }) => mobile ? 14 : 17
    },
    footer: {
      marginTop: 20
    }
  }),
  { name: 'ModalAuth' }
);
