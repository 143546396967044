//#region imports
import { createStyles, Theme, makeStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

interface IProps {
  isMobile?: boolean;
  isError?: boolean;
}

export const stylesModalCalcProgram = makeStyles<{}, IProps>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      transform: 'translateZ(0)'
    },
    loading: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    checkBoxLabel: {
      fontFamily: 'inherit!important',
      color: configTheme.megafonColors.basic.black.normal,
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      letterSpacing: '-0.3px',
      '& .MuiTypography-root': {
        fontFamily: 'inherit!important',
        color: configTheme.colors.text.dark.neutral
      }
    },
    checkBox: {
      '& .MuiSvgIcon-root': {
        width: 30,
        height: 30,
        fill: configTheme.megafonColors.basic.green.default
      }
    },
    paper: {
      width: ({ isMobile }) => isMobile ? 335 : 372,
      height: ({ isMobile, isError }) => isMobile ? (isError ? 630 : 600) : (isError ? 710 : 680),
      maxHeight: '100vh',
      backgroundColor: configTheme.megafonColors.basic.white,
      boxSizing: 'border-box',
      borderRadius: 5
    },
    header: {
      display: 'flex',
      height: ({ isMobile }) => isMobile ? 56 : 76,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
      backgroundColor: configTheme.megafonColors.basic.green.default
    },
    paramsText: {
      padding: ({ isMobile }) => isMobile ? '25px 0 0 0' : '30px 0 10px 0',
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      lineHeight: ({ isMobile }) => isMobile ? '17px' : '21px',
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      color: configTheme.megafonColors.basic.black.normal
    },
    container: {
      padding: '0 20px'
    },
    closeIcon: {
      cursor: 'pointer',
      height: 20,
      width: 20
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column'
    },
    inputs: {},
    input: {
      width: ({ isMobile }) => isMobile ? 90 : 100,
      marginRight: ({ isMobile }) => isMobile ? 10 : 15,
      '&:nth-child(3n)': { marginRight: 0 }
    },
    autocomplete: {
      '& .MuiFilledInput-root': {
        width: '100%'
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      }
    },
    wrapperRadioGroup: {
      display: 'flex',
      flexDirection: 'row',
      margin: ({ isMobile }) => isMobile ? '8px 0 30px 0' : '0 0 25px 0',
      '& .MuiTypography-body1': {
        fontFamily: 'inherit'
      }
    },
    containerRadio: {
      position: 'relative',
      width: ({ isMobile }) => isMobile ? 147 : 165,
      height: ({ isMobile }) => isMobile ? 30 : 35,
      margin: 0,
      '& .MuiFormControlLabel-label': {
        position: 'absolute',
        left: 0,
        right: 0,
        fontSize: ({ isMobile }) => isMobile ? 14 : 16,
        textAlign: 'center',
        ...configTheme.fontFamily.MegaFonGraphikLCRegular
      }
    },
    buttons: {
      height: 100,
      margin: ({ isMobile }) => isMobile ? '5px 0 20px 0' : '15px 0 20px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& .mfui-button__inner': {
        height: 43,
        fontSize: ({ isMobile }) => isMobile ? 16 : 17
      }
    },
    warning: {
      display: 'flex',
      alignItems: 'center',
      marginTop: ({ isMobile }) => isMobile ? 10 : 15,
      fontSize: ({ isMobile }) => isMobile ? 12 : 14,
      letterSpacing: '-0.3px',
      color: configTheme.colors.text.dark.neutral
    },
    icon: {
      marginRight: 10,
      '& svg': {
        width: ({ isMobile }) => isMobile ? 24 : 34,
        height: ({ isMobile }) => isMobile ? 17 : 28
      }
    },
    paperLoader: {
      height: ({ isMobile }) => isMobile ? 325 : 360,
      padding: 0,
      backgroundColor: configTheme.megafonColors.basic.white,
      boxSizing: 'border-box',
      borderRadius: 5
    },
    title: {
      fontSize: ({ isMobile }) => isMobile ? 16 : 19,
      lineHeight: ({ isMobile }) => isMobile ? '17px' : '30px',
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      color: configTheme.megafonColors.basic.white
    },
    picture: {
      height: 125,
      marginTop: ({ isMobile }) => isMobile ? 30 : 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    desc: {
      padding: 10,
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      color: configTheme.megafonColors.basic.black.normal,
      textAlign: 'center'
    },
    progress: {
      margin: ({ isMobile }) => isMobile ? '30px 0 10px 0' : '35px 0 0 0',
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: configTheme.megafonColors.background.grey.neutral
      },
      '& .MuiLinearProgress-root': {
        height: 9,
        width: 296,
        margin: 'auto',
        borderRadius: 10,
        '& .MuiLinearProgress-barColorPrimary': {
          backgroundColor: configTheme.megafonColors.basic.purple
        }
      }
    }
  })
);
