import { EContentType } from 'app/models/model.content';

export const PROFILE = '/profile';
export const AUTH = '/auth';
export const GAME = '/game';
export const ERROR = `/error`;

export enum EDietPageSection {
  PROGRAM = 'program',
  PRODUCTS = 'products',
  PARAMETERS = 'parameters'
}

export const SECTIONS = (categoryId: number | string, subcategoryId: number | string, sectionId?: number | string) => {
  if (!sectionId) {
    return `/${categoryId}/section/list`;  // return route for section list items
  }
  return `/${categoryId}/section/item/${subcategoryId}`; // return route for section item
};

// return route for content list items
export const CONTENT_LIST = (categoryId: number | string, sectionId: number | string) => `/${categoryId}/content/list/${sectionId}`;

// return route for content item
export const CONTENT_ITEM = (contentType: EContentType, contentId?: number | string) => `/content/${contentType}/${contentId}`;

export const CONTENT_ITEM_VIDEO = (contentType: EContentType) => `/content/${contentType}`;

export const SUBCATEGORIES = (categoryId: number | string) => `/${categoryId}/subcategories`;

export const STUDY_RESULT = (contentType: EContentType, contentId: number | string) => `/content/${contentType}/${contentId}/result`;

export const DIET = (section: EDietPageSection) => `/diet/${section}`;

export const DIET_PROMO = '/diet';
