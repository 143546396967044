//#region imports
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import isUndefined from 'lodash/isUndefined';

import { configTheme } from 'app/configs/theme/config.theme';
import { IPlayerWrapperProps } from './player.wrapper.models';
//#endregion

export default makeStyles(
  createStyles({
    root: {
      width: '100%',
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      fontSize: 24
    },
    mobile: {},
    shape: {
      maxWidth: (props: IPlayerWrapperProps) => (!props.mobile ? 1440 : undefined),
      width: (props: IPlayerWrapperProps) => (!props.mobile ? '100%' : undefined),
      margin: (props: IPlayerWrapperProps) => (!isUndefined(props.offset) ? props.offset : !props.mobile ? 'auto' : undefined),
      padding: (props: IPlayerWrapperProps) => (!isUndefined(props.spacing) ? props.spacing : !props.mobile ? '0 50px' : '0 20px'),
      boxSizing: 'border-box'
    }
  }),
  { name: 'PlayerWrapper' }
);
