//#region imports
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import flow from 'lodash/fp/flow';

import { ContainerLogging, TContainerLoggingProps } from 'app/containers/container.logging';
import { TLoggingState } from 'app/entities/logging/logging.reducer';
import { getLoggingData } from 'app/utils/get.loggind.data';
import { EContentType } from 'app/models/model.content';
import { ContainerSectionItem, IContainerSectionItemProps } from 'app/containers/container.section.item';
import { ELoadStatus } from 'app/models/shared.model';
import { ESubscriptionStatus } from 'app/models/model.subscription';
//#endregion

interface IProps {
  categoryId?: number;
  sectionId?: number;
  contentId?: string;
  canSend: boolean;
  containerLogging: TContainerLoggingProps;
  containerSectionItem: IContainerSectionItemProps;
}

const LoggerEl = (props: IProps) => {
  const { canSend, containerLogging, containerSectionItem, ...newState } = props;
  const { authenticated, actions: { logPosition } } = containerLogging;
  const { subscribe, status: sectionStatus, subcategory, item } = containerSectionItem;
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname && authenticated && canSend && !pathname.includes('content/list')
      && !(pathname.includes(EContentType.ARTICLE) || pathname.includes(EContentType.VIDEO))) {
      const state: TLoggingState = getLoggingData(pathname);
      logPosition({
        ...state, ...newState,
        subcategoryId: !pathname.includes('diet') ? subcategory?.id : null,
        subscribeId: subscribe.status === ESubscriptionStatus.ACTIVE ? subscribe.id : null
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (authenticated && pathname.includes('content/list') && item?.id
      && sectionStatus === ELoadStatus.ready) {
      const state: TLoggingState = getLoggingData(pathname);
      logPosition({
        ...state, ...newState,
        subscribeId: subscribe.status === ESubscriptionStatus.ACTIVE ? subscribe.id : null,
        subcategoryId: subcategory?.id
      });
    }
  }, [subscribe]);

  return null;
};

export const Logger = flow([ContainerLogging, ContainerSectionItem])(LoggerEl);
