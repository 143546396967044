import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { configTheme } from 'app/configs/theme/config.theme';

export default makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex'
    },
    content: {
      display: 'flex',
      marginRight: 20,
      flexDirection: 'column',
      boxSizing: 'border-box',
      fontSize: 16,
      lineHeight: 1.2,
      flexGrow: 1
    },
    title: {
      fontSize: 19,
      marginBottom: 6
    },
    desc: {
      marginBottom: 10
    },
    price: {
      margin: 0
    },
    switcher: {
      '& .mfui-switcher__input_checked:not(.mfui-switcher__input_disabled)': {
        backgroundColor: configTheme.megafonColors.basic.purple
      },
      '& .mfui-switcher__input': {
        backgroundColor: configTheme.megafonColors.background.grey.neutral
      }
    }
  }),
  { name: 'CardSubscriptionMobile' }
);
