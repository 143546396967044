//#region imports
import { ReactNode, MouseEventHandler, MouseEvent } from 'react';
import { StandardProps } from '@material-ui/core';

import { PostcardLayout } from 'app/components/shared/postcard/postcard.models';
import { IContentItem } from 'app/models/model.content';
//#endregion

export interface IContentCardProps {
  layout?: PostcardLayout;
  origin?: null | 'category';
  contentType?: string;
  preview?: { src: string; width?: string | number; height?: string | number };
  extraMiddle?: ReactNode;
  extraTop?: ReactNode;
  descr?: ReactNode;
  titleClamp?: number;
  title: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  onButtonClick?: (e: MouseEvent<HTMLButtonElement>, item?: IContentItem) => void;
  onPictureClick?: (e: MouseEvent<HTMLElement>, item?: IContentItem) => void;
  onTitleClick?: (e: MouseEvent<HTMLElement>, item?: IContentItem) => void;
}

export const EContentCardClassKey = {
  root: 'root',
  inner: 'inner',
  title: 'title',
  descr: 'descr',
  picture: 'picture',
  pictureFigure: 'picture__figure',
  freeBlock: 'freeBlock',
  imgLike: 'img_like',
  button: 'button',
  disabled: 'disabled'
} as const;

// const assertions (TS 3.4+)
export type ContentCardClasses = typeof EContentCardClassKey[keyof typeof EContentCardClassKey];

export type ContentCardProps = StandardProps<IContentCardProps, ContentCardClasses>;

export interface IContentCardStylesProps {
  classes?: ContentCardProps['classes'];
  origin?: ContentCardProps['origin'];
  mobile?: boolean;
  isPictureClickable?: boolean;
  isTitleClickable?: boolean;
  isAllClickable?: boolean;
}
