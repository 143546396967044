//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { ECategoriesClassKey, CategoriesClasses } from './categories.models';
//#endregion

export const useCategoriesStyles = makeStyles(
  createStyles<CategoriesClasses, { isMobile: boolean }>({
    [ECategoriesClassKey.root]: {
      marginBottom: ({ isMobile }) => isMobile ? 40 : 60
    }
  }),
  { name: 'CategoriesMain' }
);
