import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProps } from 'app/models/model.icons';

export const IconNote: FC<IIconProps> = ({ width= 35, height= 35, fill, ...props }) => (
  /* tslint:disable */
  <SvgIcon width={ width } height={ height } viewBox={ `0 0 ${ width } ${ height }` } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <path d="M16.3334 17.4998H18.6667M9.33341 17.4998H11.6667M23.3334 17.4998H25.6667M33.8334 33.8332H17.5001C8.47943 33.8332 1.16675 26.5205 1.16675 17.4998C1.16675 8.47919 8.47943 1.1665 17.5001 1.1665C26.5207 1.1665 33.8334 8.47919 33.8334 17.4998V33.8332Z" stroke={ fill ? fill : "#721983"} stroke-width="1.5" fill="none"/>
  </SvgIcon>
  /* tslint:enable */
);
