import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { configTheme } from 'app/configs/theme/config.theme';

export default makeStyles(theme =>
  createStyles({
    root: {
      backgroundColor: configTheme.megafonColors.basic.white,
      boxShadow: '0px 7px 6px rgba(0, 0, 0, 0.08)'
    },
    main: {
      flex: '1 0 auto',
      marginBottom: 'auto',
      backgroundColor: configTheme.megafonColors.background.grey.light
    }
  }),
  { name: 'Layout' }
);
