//#region imports
import React, { FC, forwardRef, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, RadioGroup, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button, Header, Paragraph } from '@megafon/ui-core';

import IconClose from 'app/components/shared/icons/close/icon.close';
import { IconExclamation } from 'app/components/shared/icons/exclamation/icon.exclamation';
import { configTheme } from 'app/configs/theme/config.theme';
import { IconSquare } from 'app/components/shared/icons/square/icon.square';

import { Modal } from 'app/components/modal/modal.ui';
import { CustomTextField } from 'app/components/custom.input/custom.input';
import { CustomizedRadiosCheck } from 'app/components/customized.radios.check/customized.radios.check';
import { ESex, ETarget, IUserAttributes } from 'app/entities/diet/program/diet.program.reducer';
import { ELoadStatus } from 'app/models/shared.model';
import { CustomAutocomplete } from 'app/components/autocomplete/autocomplete';
import { stylesModalCalcProgram } from 'app/pages/desktop/diet/program/program.style';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

interface IModalParametersProps {
  isModalParameters: boolean;
  setModalParameters: (boolean) => void;
  save: (data: IUserAttributes) => void;
  propsState?: IUserAttributes;
  loading?: ELoadStatus;
}

export const ModalParameters: FC<IModalParametersProps> = forwardRef(
  ({ isModalParameters, setModalParameters, propsState, save, loading }, ref) => {
    const { t } = useTranslation();
    const options = [
      { id: ETarget.WEIGHT_DOWN, title: `${t('modalCalcProgram.loseWeight')}` },
      { id: ETarget.WEIGHT_UP, title: `${t('modalCalcProgram.toGainWeight')}` },
      { id: ETarget.WEIGHT_KEEP, title: `${t('modalCalcProgram.maintainWeight')}` }
    ];

    const [valueAutocomplete, setValueAutocomplete] = useState(
      propsState?.target ? options.find(el => el.id === propsState?.target) : options[0]
    );
    const [errorWeight, setErrorWeight] = useState(false);
    const [errorHeight, setErrorHeight] = useState(false);
    const [errorAge, setErrorAge] = useState(false);
    const [inputValueTextField, setInputValueTextField] = useState({
      height: '',
      weight: '',
      age: '',
      target: null
    });
    const [state, setState] = useState({
      breastFeeding: false
    });
    const [isSwitchWomenMen, setSwitchWomenMen] = useState<ESex>(ESex.FEMALE);

    const { isMobile } = useBreakpoints();
    const styles = stylesModalCalcProgram({ isMobile, isError: (errorHeight || errorWeight || errorAge) });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ breastFeeding: event.target.checked });
    };

    useEffect(() => {
      setInputValueTextField(prevState => ({
        ...prevState,
        target: valueAutocomplete.id
      }));
    }, [valueAutocomplete]);

    useEffect(() => {
      if (isSwitchWomenMen === ESex.MALE) {
        setState({ breastFeeding: false });
      }
    }, [isSwitchWomenMen]);

    const handleCloseModal = () => {
      setModalParameters(false);
    };

    const handleSave = async () => {
      if (errorWeight || errorHeight || errorAge) {
        return;
      }
      const { height, weight, age, target } = inputValueTextField;
      await save({
        height: !!height ? Number(height) : null,
        weight: !!weight ? Number(weight) : null,
        age: !!age ? Number(age) : null,
        sex: isSwitchWomenMen,
        target,
        ...state
      });
      if (loading === ELoadStatus.ready) {
        setModalParameters(false);
      }
    };

    const validateForm = (inputValidateItem, stateValidate, min, max) => {
      if (inputValidateItem < min || inputValidateItem > max) {
        stateValidate(true);
      } else {
        stateValidate(false);
      }

      if (inputValidateItem === '' || inputValidateItem === null) {
        stateValidate(false);
      }
    };

    useEffect(() => {
      validateForm(inputValueTextField.height, setErrorHeight, 100, 250);
    }, [inputValueTextField.height]);

    useEffect(() => {
      validateForm(inputValueTextField.weight, setErrorWeight, 20, 300);
    }, [inputValueTextField.weight]);

    useEffect(() => {
      validateForm(inputValueTextField.age, setErrorAge, 14, 114);
    }, [inputValueTextField.age]);

    useEffect(() => {
      if (propsState !== null && Object.keys(propsState).length !== 0) {
        const { height, weight, age, sex, breastFeeding, target } = propsState;

        setInputValueTextField({
          height: height === null ? '' : String(height),
          weight: weight === null ? '' : String(weight),
          age: age === null ? '' : String(age),
          target: target ? options.find(el => el.id === target)?.id : options[0]?.id
        });

        setValueAutocomplete(target ? options.find(el => el.id === target) : options[0]);
        setSwitchWomenMen(sex === null ? ESex.FEMALE : sex);
        setState({ breastFeeding });
      }
    }, [propsState]);

    return (
      <>
        <div className={ styles.root }>
          <Modal
            open={ isModalParameters }
            onClose={ handleCloseModal }
            renderModal={
              <div className={ styles.paper }>
                <div className={ styles.header }>
                  <Header className={ styles.title }>
                    { t('modalCalcProgram.myParameters') }
                  </Header>
                  <ButtonBase onClick={ e => setModalParameters(false) }>
                    <IconClose className={ styles.closeIcon }/>
                  </ButtonBase>
                </div>
                <div className={ styles.container }>
                  <Paragraph className={ styles.paramsText }>
                    { t('modalCalcProgram.yourParametersForCalculation') }
                  </Paragraph>
                <form noValidate className={ styles.formWrapper }>
                  <Grid className={ styles.inputs }>
                  <CustomTextField
                    type="number"
                    className={ styles.input }
                    label={ t('calc.height') }
                    value={ inputValueTextField?.height }
                    onChange={ e => setInputValueTextField({ ...inputValueTextField, height: e.target.value }) }
                    variant="filled"
                    id="height"
                    error={ errorHeight }
                    helperText={ (errorHeight || errorWeight || errorAge) && t('modalCalcProgram.checkValidData') }
                    onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                    inputProps={ { pattern: '[0-9]*' } }
                    unit={ t('modalCalcProgram.sm') }
                  />
                  <CustomTextField
                    type="number"
                    className={ styles.input }
                    label={ t('calc.weight') }
                    value={ inputValueTextField?.weight }
                    onChange={ e => setInputValueTextField({ ...inputValueTextField, weight: e.target.value }) }
                    variant="filled"
                    id="weight"
                    error={ errorWeight }
                    onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                    inputProps={ { pattern: '[0-9]*' } }
                    unit={ t('modalCalcProgram.kg') }
                  />
                  <CustomTextField
                    type="number"
                    className={ styles.input }
                    label={ t('modalCalcProgram.age') }
                    value={ inputValueTextField?.age }
                    onChange={ e => setInputValueTextField({ ...inputValueTextField, age: e.target.value }) }
                    variant="filled"
                    id="age"
                    error={ errorAge }
                    onKeyDown={ e => ['.', 'e', 'E'].includes(e.key) && e.preventDefault() }
                    inputProps={ { pattern: '[0-9]*' } }
                  />
                  </Grid>
                  <FormControl component="fieldset" style={ { marginTop: '18px' } }>
                    <RadioGroup
                      aria-label="gender"
                      name="customized-radios"
                      className={ styles.wrapperRadioGroup }
                      defaultValue={ t('modalCalcProgram.woman') }
                    >
                      <FormControlLabel
                        className={ styles.containerRadio }
                        value={ t('modalCalcProgram.woman') }
                        checked={ isSwitchWomenMen === ESex.FEMALE }
                        control={ <CustomizedRadiosCheck isFirst /> }
                        label={ t('modalCalcProgram.woman') }
                        onClick={ () => setSwitchWomenMen(ESex.FEMALE) }
                        style={ {
                          color: isSwitchWomenMen === ESex.FEMALE
                            ? configTheme.colors.background.light.default
                            : configTheme.colors.basic.primary
                        } }
                      />
                      <FormControlLabel
                        className={ styles.containerRadio }
                        value={ t('modalCalcProgram.man') }
                        checked={ isSwitchWomenMen === ESex.MALE }
                        control={ <CustomizedRadiosCheck /> }
                        label={ t('modalCalcProgram.man') }
                        onClick={ () => setSwitchWomenMen(ESex.MALE) }
                        style={ {
                          color: isSwitchWomenMen === ESex.MALE
                            ? configTheme.colors.background.light.default
                            : configTheme.colors.basic.primary
                        } }
                      />
                    </RadioGroup>
                  </FormControl>
                  <CustomAutocomplete
                    defaultValue={ propsState?.target ? valueAutocomplete : undefined }
                    value={ valueAutocomplete }
                    options={ options }
                    id="controllable-states"
                    onChange={ setValueAutocomplete }
                  />
                  <FormGroup row style={ { margin: isMobile ? '0 0 10px 0' : '10px 0' } }>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={ state.breastFeeding }
                          onChange={ handleChange }
                          name={ t('calc.breastFeeding') }
                          icon={ <IconSquare /> }
                        />
                      }
                      label={ t('calc.breastFeeding') }
                      classes={ { label: styles.checkBoxLabel, root: styles.checkBox } }
                      style={ isSwitchWomenMen === ESex.FEMALE ? { opacity: '1' } : { opacity: '0', cursor: 'default' } }
                    />
                  </FormGroup>

                  <div className={ styles.buttons }>
                    <Button onClick={ handleSave }>
                      { t('modalCalcProgram.saveParameters') }
                    </Button>
                    <Button type="outline" onClick={ handleCloseModal }>
                      { t('modalCalcProgram.cancel') }
                    </Button>
                  </div>
                </form>
                <div className={ styles.warning }>
                  <div className={ styles.icon }>
                    <IconExclamation />
                  </div>
                  { t('modalCalcProgram.programDescription') }
                </div>
              </div>
            </div>
            }
          />
        </div>
      </>
    );
  }
);
