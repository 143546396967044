import axios from 'axios';
import { BASENAME } from 'app/configs/const';

import { ISubcategory } from 'app/models/model.subcategory';
import { ISection, ISectionItem } from 'app/models/model.section';
import { searchRequest } from 'app/utils/utils.request.search';
import { IPagerProps } from 'app/containers/container.pager';
import { CancelToken, IRequestCancel } from 'app/models/shared.model';

export const SECTION_ACTIONS = {
  FETCH_LIST: 'SECTION:LIST::FETCH',
  FETCH_ALL_LIST: 'SECTION:LIST::FETCH_ALL',
  RESET_ITEMS: 'SECTION:ITEMS::RESET',
  REMOVE_ITEMS: 'SECTION:ITEMS::REMOVE',

  FETCH_ITEM: 'SECTION:ITEM::FETCH',
  RESET_ITEM: 'SECTION:ITEM::RESET',

  FETCH_MENU: 'SECTION:MENU::FETCH',
  RESET_MENU: 'SECTION:MENU::RESET'
};

export interface IFetchSectionItemsProps extends IPagerProps {
  id?: number;
  allId?: number[];
}
export const fetchSectionItems = (props: IFetchSectionItemsProps) => async dispatch => {
  const result = await dispatch({
    type: SECTION_ACTIONS.FETCH_LIST,
    meta: { props: { targetId: props.id } },
    payload: axios.get(
      `${BASENAME}/api/section/list?subcategoryId=${props.id}&limit=${props.pager ? props.pager.limit : ''}&offset=${
        props.pager ? props.pager.offset : ''
      }`
    )
  });
  return result;
};

export const fetchAllSectionItems = (props: IFetchSectionItemsProps) => async dispatch => {
  const data = await Promise.all(props.allId.map(id => {
    return axios.get(`${BASENAME}/api/section/list?subcategoryId=${id}&limit=${
      props.pager ? props.pager.limit : ''}&offset=${
      props.pager ? props.pager.offset : ''
    }`);
  }));
  if (data?.length) {
    const allItems = [];
    data?.map(el => allItems.push(...el.data.sections));
    dispatch({
      type: SECTION_ACTIONS.FETCH_ALL_LIST,
      payload: allItems
    });
  }
};

export const resetSectionItems = () => ({
  type: SECTION_ACTIONS.RESET_ITEMS
});

export const cancelFetchSectionItems = async (subcategoryId: ISubcategory['id']) => {
  const result = await searchRequest(`${BASENAME}/api/section/list?subcategoryId=${subcategoryId}`);
  return result;
};
export const removeSectionItems = (targetIdList: number[] = []) => ({
  type: SECTION_ACTIONS.REMOVE_ITEMS,
  payload: targetIdList
});

export const fetchSectionItem = (() => {
  let cancel;
  let url = null;
  return (id: ISectionItem['id'], props?: IRequestCancel) => {
    const { uncancelled, cancellable, cancelToken } = props ?? {};
    if (cancel && url) cancel(`Cancel fetchStream: ${url}`); // check and run cancel
    url = id ? `/api/section/get?id=${id}` : null;
    return {
      type: SECTION_ACTIONS.FETCH_ITEM,
      meta: { props: { uncancelled } },
      payload: axios
        .get(url, {
          cancelToken:
            cancelToken ??
            (cancellable &&
              new CancelToken(function executor(c) {
                cancel = c; // save cancel
              }))
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            console.warn(err?.message);
            return Promise.resolve<{ data: ISection; canceled: boolean }>({
              data: {
                announcement: null,
                contentType: null,
                distribution: null,
                section: null,
                subcategory: null,
                subscribe: null,
                subsections: []
              },
              canceled: true
            });
          }
          return Promise.reject(err);
        })
    };
  };
})();

export const cancelFetchSectionItem = async (id: ISectionItem['id']) => {
  const result = searchRequest(`${BASENAME}/api/section/get?id=${id}`);
  return result;
};

export const resetSectionItem = () => ({
  type: SECTION_ACTIONS.RESET_ITEM
});

export const fetchSectionMenu = (props: IFetchSectionItemsProps) => async dispatch => {
  const result = await dispatch({
    type: SECTION_ACTIONS.FETCH_MENU,
    meta: { props: { targetId: props.id } },
    payload: axios.get(
      `${BASENAME}/api/section/list?subcategoryId=${props.id}&limit=${props.pager ? props.pager.limit : ''}&offset=${
        props.pager ? props.pager.offset : ''
      }`
    )
  });
  return result;
};

export const resetSectionMenu = () => ({
  type: SECTION_ACTIONS.RESET_MENU
});
