//#region imports
import React, { FC } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { IconNote } from 'app/components/shared/icons/note/icon.note';
import IconPlay from 'app/components/shared/icons/play/icon.play';

import { stylesProgramDay } from '../program.day.styles';
import { ContainerDietIndividualProgram, IContainerDietIndividualProgramProps } from 'app/containers/container.diet.program';
import mockBackgroundMobile from 'app/assets/img/dietolog_mock_mobile.png';
import mockBackgroundDesktop from 'app/assets/img/dietolog_mock_desktop.png';

import { CONTENT_ITEM_VIDEO } from 'app/configs/route.names';
import { EContentType } from 'app/models/model.content';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

const AdviceDiet: FC<{
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  adviceVideoPreview: string;
  activeDayId?: number;
}> = ({ containerDietIndividualProgram, activeDayId }) => {
  const { isMobile } = useBreakpoints();
  const styles = stylesProgramDay({ isMobile });
  const { t } = useTranslation();
  const history = useHistory();

  const { dataDays } = containerDietIndividualProgram;

  const onPlay = () => {
    if (dataDays?.adviceVideoUrl) {
      localStorage.setItem('activeDayId', activeDayId.toString());
      history.push(CONTENT_ITEM_VIDEO(EContentType.VIDEO), { src: dataDays?.adviceVideoUrl, title: t('individualProgramDietolog.title') });
    }
  };

  return (
    <>
      <Paper elevation={ 3 } className={ styles.block }>
        <div className={ styles.titleContainer }>
          <IconNote className={ styles.iconMenu } />
          <p className={ styles.title }>{ t('individualProgramDietolog.title') }</p>
        </div>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <p className={ styles.displayDescription }>{ t('individualProgramDietolog.desc') }</p>
          <div className={ styles.pictureContainer }>
            <img
              src={ isMobile ? mockBackgroundMobile : mockBackgroundDesktop }
              width={ isMobile ? '100%' : 742 }
              height={ isMobile ? 'auto' : 416 }
              className={ styles.picture }
              alt={ t('diet.advices') }
            />
            <IconPlay className={ styles.videoIcon } onClick={ onPlay } />
          </div>
        </Grid>
      </Paper>
    </>
  );
};

export default ContainerDietIndividualProgram(AdviceDiet);
