//#region imports
import React, { FC } from 'react';

import classnames from 'classnames';

import { ILikesSubject, TLikesProps } from './likes.models';
import stylesLikes from './likes.styles';
import { EBreakPointSize } from 'app/models/model.theme';
import IconFavorite from 'app/components/shared/icons/favorite/icon.favorite';
//#endregion

export function getLikesSize(currentSize) {
  switch (currentSize) {
    case 'xxs': return EBreakPointSize.sm;
    case 'xs': return EBreakPointSize.sm;
    case 'sm': return EBreakPointSize.sm;
    case 'md': return EBreakPointSize.md;
    case 'lg': return EBreakPointSize.md;
    case 'xl': return EBreakPointSize.lg;
    default: return EBreakPointSize.md;
  }
}

const Likes: FC<TLikesProps> = props => {
  const {
    className,
    size,
    count,
    liked,
    inverted,
    disabled,
    onClick
  } = props;

  const styles = stylesLikes();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      if (e) { e.stopPropagation(); }
      onClick && onClick();
    }
  };

  const iconSize = getLikesSize(size);

  return (
    <div
      className={ classnames(className, styles.root, {
        [styles.disabled]: disabled,
        [styles[iconSize]]: size,
        'active': liked,
        'invert': inverted
      }) }
      onClick={ handleClick }
    >
      <IconFavorite className={ styles.icon }/>
      <span className={ styles.count }>{ count }</span>
    </div>
  );
};

Likes.defaultProps = {
  size: 'sm',
  count: 0,
  liked: false,
  inverted: false,
  disabled: false
} as TLikesProps;

Likes.displayName = 'Label';

export default Likes;
