//#region imports
import { makeStyles, createStyles } from '@material-ui/core';
//#endregion

export default makeStyles(theme =>
  createStyles({
    root: {}
}),
  { name: 'CardVertical' }
);
