import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { configTheme } from 'app/configs/theme/config.theme';

export default makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      borderRadius: 5,
      border: `1px solid ${ configTheme.colors.border.primary.light}`,
      boxShadow: '0px 5px 4px rgba(0, 0, 0, 0.05)'
    },
    header: {
      margin: '-1px -1px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px 5px 0 0',
      background: configTheme.megafonColors.basic.green.default,
      height: 82
    },
    title: {
      fontSize: 24,
      color: configTheme.colors.text.light.default
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      padding: '20px 25px 40px 25px',
      fontSize: 19,
      lineHeight: '30px',
      flexGrow: 1,
      borderRadius: 5,
      background: configTheme.megafonColors.basic.white
    },
    price: {
      marginTop: 0,
      marginBottom: 24
    },
    button: {
      marginTop: 'auto',
      '& .mfui-button__inner': {
        fontSize: 17,
        height: 43
      }
    }
  }),
  { name: 'CardSubscriptionDesktop' }
);
