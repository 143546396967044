import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, Header } from '@megafon/ui-core';
import { ISubscriptionCardProps } from 'app/models/model.subscription';

import styles from './card.subscription.desktop.styles';

const CardSubscriptionDesktop: FC<ISubscriptionCardProps> = ({ price, toggleSubscribe, title, desc, isSubscribe, className = '' }) => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <div className={ cn(classes.root, className) }>
      <div className={ classes.header }>
        <Header className={ classes.title } as={ 'h3' }>
          { title }
        </Header>
      </div>
      <div className={ classes.content }>
        <div dangerouslySetInnerHTML={ { __html: desc } }/>
        <p className={ classes.price }> { t('common.price') }&nbsp;{ price } </p>
        <Button type={ isSubscribe ? 'outline' : 'primary' } className={ classes.button } onClick={ toggleSubscribe }>
            { t(isSubscribe ? 'action.disable' : 'action.toPlug') }
        </Button>
      </div>
    </div>
  );
};

CardSubscriptionDesktop.displayName = 'CardSubscriptionDesktop';

export default CardSubscriptionDesktop;
