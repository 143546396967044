import React, { FC } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

interface IInputMaskProps extends MaskedInputProps {
  inputRef: any;
}

export const InputMaskHours: FC<IInputMaskProps> = props => {
  const { inputRef, mask, ...other } = props;

  const hoursMask = (value: string) => {
    const chars = value.split('');
    const hours = [
        /[0-2]/,
        chars[0] === '2' ? /[0-3]/ : /[0-9]/
    ];

    return [hours[0], hours[1]];
};

  return (
    <MaskedInput
      { ...other }
      ref={ ref => {
        inputRef(ref ? ref.inputElement : null);
      } }
      mask={ hoursMask }
      placeholderChar={ '\u2000' }
    />
  );
};
