import useMediaQuery from '@material-ui/core/useMediaQuery';

const useBreakpoints = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(min-width: 769px) and (max-width: 1024px)');
  const isNotDesktop = useMediaQuery('(max-width: 1024px)');
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  const isTabletMenu = useMediaQuery('(max-width: 1190px)');

  return {
    isMobile,
    isTablet,
    isNotDesktop,
    isDesktop,
    isTabletMenu
  };
};

export default useBreakpoints;
