//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import {
  EPostcardLayout,
  EPostcardClassKey,
  EPostcardBadgeClassKey,
  PostcardLayout,
  PostcardClasses,
  PostcardBadgeClasses,
  PostcardBadgeStylesProps
} from './postcard.models';
import { cssUnit } from 'app/utils/utils.stylesheet';
import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

//#region Postcard
export const usePostcardStyles = makeStyles(
  createStyles<PostcardClasses | PostcardLayout, {}>({
    [EPostcardClassKey.root]: {
      display: 'flex',
      border: `1px solid ${configTheme.colorsNew.border.default}`,
      borderRadius: 5
    },
    [EPostcardLayout.vertical]: {
      flexDirection: 'column'
    },
    [EPostcardLayout.horizontal]: {
      flexDirection: 'row',
      // flexWrap: 'nowrap',
      flexWrap: 'wrap'
    },

    [EPostcardClassKey.picture]: {
      display: 'block',
      position: 'relative',
      // flex: '1 1 auto',
      flex: '0 1',
      [`$${EPostcardLayout.vertical} &`]: {
        marginBottom: '12px'
      },
      [`$${EPostcardLayout.horizontal} &`]: {
        marginRight: '12px'
      }
    },
    [EPostcardClassKey.pictureFigure]: {
      position: 'relative'
    },
    [EPostcardClassKey.pictureImage]: {
      display: 'block',
      width: '100%',
      height: 'auto',
      objectFit: 'cover'
    },
    [EPostcardClassKey.picturePlaceholder]: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 },
    [EPostcardClassKey.pictureExtra]: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 },
    [EPostcardClassKey.pictureMask]: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0,0,0,.5)',
      opacity: 0,
      zIndex: 1,
      transition: 'opacity .3s',
      '&:hover': {
        opacity: 1
      }
    },

    [EPostcardClassKey.extra]: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    [EPostcardClassKey.extraTop]: { position: 'absolute', top: 0, left: 0, right: 0 },
    [EPostcardClassKey.extraMiddle]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    [EPostcardClassKey.extraBottom]: { position: 'absolute', bottom: 0, left: 0, right: 0 },

    [EPostcardClassKey.descr]: {
      // flex: '1 1 auto',
      flex: '1 1'
    }
  }),
  { name: 'Postcard' }
);
//#endregion

//#region PostcardBadge
export const usePostcardBadgeStyles = makeStyles(
  createStyles<PostcardBadgeClasses, PostcardBadgeStylesProps>({
    [EPostcardBadgeClassKey.root]: {
      position: 'relative',
      display: 'inline-block',
      padding: '0 2px 0 6px',
      whiteSpace: 'nowrap',
      backgroundColor: 'var(--bgcolor)',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: '-8px',
        borderStyle: 'solid',
        borderColor: 'var(--bgcolor) transparent transparent transparent'
      }
    },

    [EPostcardBadgeClassKey.sizeSmall]: {
      fontSize: '12px',
      lineHeight: '18px',
      '&::after': {
        borderWidth: '18px 8px 0 0'
      }
    },
    [EPostcardBadgeClassKey.sizeLarge]: {
      fontSize: '16px',
      lineHeight: '22px',
      '&::after': {
        borderWidth: '22px 8px 0 0'
      }
    },

    [EPostcardBadgeClassKey.offset]: ({ offset }) => {
      const top = offset?.top ? cssUnit(offset.top) : 0;
      const right = offset?.right ? cssUnit(offset.right) : 0;
      const bottom = offset?.bottom ? cssUnit(offset.bottom) : 0;
      const left = offset?.left ? cssUnit(offset.left) : 0;
      return { margin: `${top} ${right} ${bottom} ${left}` };
    },
    [EPostcardBadgeClassKey.colorPrimary]: {
      '--bgcolor': configTheme.colorsNew.basic.primary.default,
      color: configTheme.colorsNew.text.white.default
    },
    [EPostcardBadgeClassKey.colorSecondary]: {
      '--bgcolor': configTheme.colorsNew.basic.primary.default,
      color: configTheme.colorsNew.text.white.default
    }
  }),
  { name: 'PostcardBadge' }
);
//#endregion
