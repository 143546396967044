//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { ICardVerticalProps } from '../card.vertical.models';
import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

const computeXxsSizeStyles = () => ({
  padding: 10,
  '& img': {
    width: 254,
    height: 180
  },
  '& h5$title': {
    fontSize: '16px',
    width: 254
  }
});

const computeXsSizeStyles = theme => ({
  padding: 10,
  '& img': {
    width: 313,
    height: 210,
    [theme.breakpoints.down(375)]: {
      width: 290
    },
    [theme.breakpoints.down(350)]: {
      width: 270
    }
  },
  '& h5$title': {
    fontSize: '16px',
    width: 313,
    [theme.breakpoints.down(375)]: {
      width: 290
    },
    [theme.breakpoints.down(350)]: {
      width: 270
    }
  }
});

const computeSmSizeStyles = () => ({
  padding: 18,
  '& img': {
    width: 325,
    height: 239
  },
  '& h5$title': {
    fontSize: '19px',
    width: 325
  }
});

const computeMdSizeStyles = () => ({
  padding: '20px 20px 10px 20px',
  '& img': {
    width: 360,
    height: 270
  },
  '& h5$title': {
    fontSize: '19px',
    width: 347
  }
});

const computeLgSizeStyles = () => ({
  padding: 20,
  '& img': {
    width: 505,
    height: 371
  },
  '& h5$title': {
    fontSize: '19px',
    width: 505
  }
});

const computeXlSizeStyles = () => ({
  padding: 20,
  '& img': {
    width: 702,
    height: 416
  },
  '& h5$title': {
    paddingTop: 20,
    fontSize: '24px',
    width: 702
  }
});

export default makeStyles(theme =>
  createStyles({
    root: (props: ICardVerticalProps) => ({
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      backgroundColor: props.isTransparent ? 'transparent' : configTheme.colors.basic.secondary,
      border: props.isTransparent ? 'none' : `1px solid ${ configTheme.colors.border.primary.light }`,
      boxShadow: props.isTransparent ? 'none' : '0px 5px 4px rgba(0, 0, 0, 0.05)',
      borderRadius: 10,
      cursor: 'pointer'
    }),
    picture: {
      position: 'relative',
      margin: '0 auto',
      '& img': {
        objectFit: 'cover',
        borderRadius: 5
      }
    },
    title: {
      margin: 0,
      padding: '0.4em 0 0 0',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'start'
    },
    indent: {
      padding: '0.3em 0'
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    footer:  {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '0.4em'
    },
    badge:  {},
    likes:  {
      marginTop: '0.1em'
    },
    xxs: computeXxsSizeStyles(),
    xs: computeXsSizeStyles(theme),
    sm: computeSmSizeStyles(),
    md: computeMdSizeStyles(),
    lg: computeLgSizeStyles(),
    xl: computeXlSizeStyles()
  }),
  { name: 'CardVerticalLeft' }
);
