import { makeStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

export default makeStyles(theme => ({
  arrow: {
    display: 'none',
    color: configTheme.colors.text.dark.neutral,
    [theme.breakpoints.up(configTheme.breakpoints.values.mdx)]: {
      zIndex: configTheme.layers.bar,
      position: 'absolute',
      flexDirection: 'column',
      justifyContent: 'center',
      minWidth: 19,
      top: 2,
      bottom: 2,
      cursor: 'pointer',
      background: configTheme.megafonColors.background.grey.light,
      border: 'none',
      display: 'flex'
    },
    '&:disabled': {
      display: 'none',
      cursor: 'default'
    }
  },
  icon: {
    stroke: 'currentColor',
    fill: 'transparent'
  },
  arrowRight: {
    right: 0
  },
  arrowLeft: {
    left: 0,
    transform: 'rotate(180deg)'
  }
}));
