import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconCloseBig: FC <SvgIconProps> = props => (
  /* tslint:disable */
  <SvgIcon { ...props } viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7074 22.8893L19.0002 22.1822L18.2931 22.8893L5.04834 36.1341L1.86636 32.9521L15.1112 19.7074L15.8183 19.0002L15.1112 18.2931L1.86636 5.04834L5.04834 1.86636L18.2931 15.1112L19.0002 15.8183L19.7074 15.1112L32.9521 1.86636L36.1341 5.04834L22.8893 18.2931L22.1822 19.0002L22.8893 19.7074L36.1341 32.9521L32.9521 36.1341L19.7074 22.8893Z" fill="white" stroke="#292929" stroke-width="2"/>
  </SvgIcon>
  /* tslint:enable */
);

export default IconCloseBig;
