import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Paragraph, TextLink } from '@megafon/ui-core';

import Wrapper from 'app/components/shared/wrapper/wrapper.index';

import stylesFooter from './footer.styles';

export interface IFooterProps {
  className?: string;
  termsHref?: string;
  termsValue?: string;
  isTabletMenu?: boolean;
}

const Footer: FC<IFooterProps> = props => {
  const { termsHref, termsValue, className, children, isTabletMenu } = props;
  const classes = stylesFooter({ isTabletMenu });
  const { t } = useTranslation();

  const defaultFooter = useMemo(() => (
    <>
      <Paragraph
        color="white"
        hasMargin={ false }
        className={ classes.company }
      >
        { t('footer.company') }
      </Paragraph>
      <TextLink
        href={ termsHref }
        target="_blank"
        color="white"
        underlineStyle="none"
        className={ classes.terms }
      >
        { termsValue || t('footer.terms') }
      </TextLink>
    </>
  ), [ termsValue, termsHref ]);

  return (
    <footer className={ classNames(className, classes.root) }>
      <Wrapper>
        { children || defaultFooter }
      </Wrapper>
    </footer>
  );
};

Footer.displayName = 'Footer';

export default Footer;
