export const MODAL_ROOT = 'modal-root';
export const DEVELOPMENT = process.env.NODE_ENV === 'development';
export const BASENAME = process.env.CONTEXT_PATH || '';
export const SERVER_API_URL = process.env.SERVER_API_URL;

export const MAX_TIME_SMS_CODE = 60 * 10 * 1000;

export const PAGER = {
  LIMIT: 10,
  OFFSET: 0
};

export const numberRegex = /^\d+$/;

let sd ;
let updateCounter = 0;

export const LOG_PROFILE = (
  id: string,
  phase: 'mount' | 'update',
  actualDuration: number,
  baseDuration: number,
  startTime: number,
  commitTime: number,
  interactions: Set<any>
) => {
  if (DEVELOPMENT) {
    console.group('%c' + id, 'color:blue');
    if (id === sd && phase === 'update') {
      updateCounter = updateCounter + 1;
      console.warn('Count Update', updateCounter);
    }
    console.warn('Phase', phase);
    console.warn('Actual Duration', actualDuration);
    console.warn('Base Duration', baseDuration);
    console.warn('Start Time', startTime);
    console.warn('Commit Time', commitTime);
    console.warn('Interactions', interactions);
    console.groupEnd();
    sd = id;
  }
};

export const MAX_GAME_ROUND = 10;

export const SUBSCRIBE_STATUS = {
  ACTIVE: 'ACTIVE',
  NOT_ACTIVE: 'NOT_ACTIVE',
  NO_MONEY: 'NO_MONEY'
};
