//#region imports
import React, { FC, useEffect, useMemo } from 'react';
import { useLocation } from 'react-use';
import { useHistory } from 'react-router-dom';

import { ContainerMenu, IContainerMenu } from 'app/containers/menu.container';
import MenuMobile from 'app/components/mobile/menu/menu.mobile';
import MenuDesktop from 'app/components/desktop/menu/menu.desktop';

import useBreakpoints from 'app/hooks/use.breakpoints';
import { useTranslation } from 'react-i18next';
import flow from 'lodash/fp/flow';

import LayoutBase from 'app/components/shared/layout/layout.index';
import Logo from 'app/components/shared/logo/logo.index';
import Footer from 'app/components/shared/footer/footer.index';

import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { ContainerContentItem, IContainerContentItemProps } from 'app/containers/container.content.item';
import { ContainerSectionItem, IContainerSectionItemProps } from 'app/containers/container.section.item';

import Toolbar from 'app/components/toolbar/toolbar';
import { getMenuItem } from 'app/utils/create.menu.helper';
import { stylesLayout } from 'app/layouts/layout.styles';
import { PROFILE } from 'app/configs/route.names';
import { Logger } from 'app/entities/logging/logger';
//#endregion

interface ILayoutProps {
  containerMenu: IContainerMenu;
  containerUser: IUserContainerProps;
  containerContentItem: IContainerContentItemProps;
  containerSectionItem: IContainerSectionItemProps;
}

const Layout: FC<ILayoutProps> = ({ containerMenu, containerUser, children }) => {
  const { actions: menuActions, items: menuData } = containerMenu;
  const { actions: userActions, data: { authenticated } } = containerUser;

  const { pathname, hash } = useLocation();
  const { isTabletMenu, isDesktop } = useBreakpoints();
  const { t } = useTranslation();
  const { isNotDesktop } = useBreakpoints();
  const styles = stylesLayout({ isNotDesktop, isTabletMenu });
  const history = useHistory();

  const CurrentMenu = isTabletMenu ? MenuMobile : MenuDesktop;
  const menuItems = useMemo(() => menuData?.length > 0 && menuData.map(item => getMenuItem(item, pathname, hash)), [menuData, pathname, hash]);

  useEffect(() => {
    menuActions.itemsFetch();
    }, []);

  const onLogin = () => {
    if (authenticated) {
      history.push(PROFILE);
    } else {
      userActions.toggleModalLogin();
    }
  };

  const renderMenu = useMemo(() => (
      <CurrentMenu
        className={ styles.menu }
        logo={
          <Logo
            description={ t('menu.logo') }
            fontSize={ isTabletMenu ? 15 : 21 }
            width={ isTabletMenu ? 150 : 200 }
            isTabletMenu={ isTabletMenu }
          />
        }
        toolbar={ <Toolbar isAuthenticate={ authenticated } handleClick={ onLogin } /> }
        items={ menuItems }
        logoRedirectUrl={ '/' }
      />
  ), [menuItems, CurrentMenu, authenticated, isTabletMenu]);

  const renderFooter = useMemo(() =>
    <Footer termsHref={ '/oferta.pdf' } isTabletMenu={ isTabletMenu } />
  , [isTabletMenu]);

  return (
    <LayoutBase renderHeader={ renderMenu } renderFooter={ renderFooter }>
      <Logger canSend/>
      { children }
    </LayoutBase>
  );
};

export default flow([ContainerUser, ContainerMenu, ContainerContentItem, ContainerSectionItem])(Layout);
