import React, { FC } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';

import IconCloseDark from 'app/components/shared/icons/close/icon.close.dark';
import stylesMenuModal from './menu.modal.styles';
import MobileModalList from './list/menu.modal.list';
import { IMenuMobileProps } from '../menu.mobile';

export interface IMenuMobileModalProps extends Pick<IMenuMobileProps, 'items' | 'logo' | 'toggleMenuCallback'> {
  toggleMenu: () => void;
}

const MenuMobileModal: FC<IMenuMobileModalProps> = ({ toggleMenuCallback, logo, items, toggleMenu }) => {

  const classes = stylesMenuModal({ widthImg: 80 });

  return (
    <div className={ classes.root }>
      <div className={ classes.modalHeader }>
        { logo && logo }
        <ButtonBase className={ classes.modalClose } onClick={ toggleMenuCallback }>
          <IconCloseDark className={ classes.icon } />
        </ButtonBase>
      </div>

      <MobileModalList items={ items } toggleMenu={ toggleMenu }/>
    </div>
  );
};

MenuMobileModal.displayName = 'MenuMobileModal';

export default MenuMobileModal;
