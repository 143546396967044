//#region imports
import { History } from 'history';

import { ESubscriptionStatus, ISubscription } from 'app/models/model.subscription';
import { IUserContainerProps } from 'app/containers/container.user';
import { DIET, EDietPageSection } from 'app/configs/route.names';
//#endregion

interface IUtilUserOpportunitiesCheck {
  readonly subscribe: ISubscription;
  readonly callback?: () => void;
  readonly callbackClose?: () => void;
  readonly callbackCancel?: () => void;
  readonly containerUser: IUserContainerProps;
  readonly history?: History;
  isDeclineSetParams?: boolean;
  isNullishUserAttributes?: boolean;
}

const UtilUserOpportunitiesCheck = ({
   subscribe,
   containerUser,
   history,
   isNullishUserAttributes,
   isDeclineSetParams
 }: IUtilUserOpportunitiesCheck) => {
  if (!containerUser?.data.authenticated
    || subscribe?.status === ESubscriptionStatus.NOT_ACTIVE
    || (isNullishUserAttributes && !isDeclineSetParams)
  ) {
    return;
  } else {
    return history.replace(DIET(EDietPageSection.PROGRAM));
  }
};

export default UtilUserOpportunitiesCheck;
