//#region imports
import React, { FC, MouseEvent } from 'react';
import { ButtonBase } from '@material-ui/core';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';

import { TButtonPlayStandardProps } from './button.play.models';
import stylesButtonPlay from './button.play.styles';
import IconPlay from 'app/components/shared/icons/play/icon.play';
//#endregion

const ButtonPlay: FC<TButtonPlayStandardProps> = props => {
  const {
    className,
    size,
    onClick
  } = props;

  const styles = stylesButtonPlay();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (isFunction(onClick)) onClick(e);
  };

  return (
    <ButtonBase
      component="button"
      className={ classnames(className, styles.root, {
        [styles[size]]: size
      }) }
      onClick={ handleClick }
    >
      <IconPlay />
    </ButtonBase>
  );
};

ButtonPlay.defaultProps = {
  size: 'xs'
} as TButtonPlayStandardProps;

ButtonPlay.displayName = 'ButtonPlay';

export default ButtonPlay;
