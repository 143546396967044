//#region imports
import React, { FC, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { Paragraph } from '@megafon/ui-core';

import IconSheet from 'app/components/shared/icons/sheet/icon.sheet';

import {
  ContainerDietIndividualProgram,
  IContainerDietIndividualProgramProps
} from 'app/containers/container.diet.program';
import { stylesProgramDay } from '../program.day.styles';

import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ESubscriptionStatus, ISubscription } from 'app/models/model.subscription';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

const ProgramMenuByDayBlock: FC<{
  containerDietIndividualProgram: IContainerDietIndividualProgramProps;
  containerSubscribe: IContainerSubscribeProps;
  dietStatus: ISubscription
}> = props => {
  const { isMobile } = useBreakpoints();
  const styles = stylesProgramDay({ isMobile });
  const { t } = useTranslation();
  const { dataDays, programStatus } = props.containerDietIndividualProgram;
  const { subscriptions } = props.containerSubscribe;
  const myRefs = useRef([]);

  const mocData = {
    eatings: [
      {
        id: 2940,
        displayDescription: '<p>40 минут до тренировки</p><p><strong>139 ккал</strong>,&nbsp;<em>белки 14, жиры 5, углеводы 11</em></p><p>' +
            '<strong>Белковый перекус</strong></p><blockquote><p>Яйцо белок&nbsp;<strong>3 шт</strong></p><p>Хлебцы Dr.Corner 7 злаков&nbsp;<strong>1 шт</strong>' +
            '</p><p>Авокадо&nbsp;<strong>30 гр</strong></p><p>Помидоры&nbsp;<strong>40 гр</strong></p></blockquote>' +
            '<p>Авокадо и помидоры порезать и выложить на хлебец. Яйцо отварить принимать в пищу без желтка.</p>',
        displayHeader: t('individualProgramMenuBlock.mokData.displayHeaderBreakfast')
      },
      {
        id: 2941,
        displayDescription: t('individualProgramMenuBlock.mokData.displayDescription'),
        displayHeader: t('individualProgramMenuBlock.mokData.displayHeaderSnack')
      },
      {
        id: 2942,
        displayDescription: null,
        displayHeader: t('individualProgramMenuBlock.mokData.displayHeaderDinner')
      },
      {
        id: 2943,
        displayDescription: null,
        displayHeader: t('individualProgramMenuBlock.mokData.displayHeaderAfternoon')
      },
      {
        id: 2944,
        displayDescription: null,
        displayHeader: t('individualProgramMenuBlock.mokData.displayHeader')
      }
    ],
    adviceVideoUrl: '',
    id: 1,
    trainingVideoUrl: ''
  };

  const [open, setOpen] = useState(2940);
  const [state, setState] = useState({
    eatings: [],
    adviceVideoUrl: '',
    id: null,
    trainingVideoUrl: ''
  });

  const subscribe = subscriptions.find(sub => sub.id === programStatus?.id);

  useEffect(() => {
    if (subscribe?.status === ESubscriptionStatus.ACTIVE) {
      if (dataDays !== null && dataDays?.eatings.length) {
        setState(dataDays);
        setOpen(dataDays?.eatings.find(el => el?.displayDescription)?.id);
      }
    } else {
      setState(mocData);
    }
  }, [dataDays, subscribe?.status]);

  useEffect(() => {
    if (subscribe?.status !== ESubscriptionStatus.ACTIVE) {
      setState(mocData);
    }
  }, [subscribe]);

  const handleClick = (openBlockId: number, index: number) => {
    setOpen(openBlockId);
    myRefs.current[index].scrollIntoView(false);
  };

  const selectItemMargin = id => {
    if (id === open) {
      if (state.eatings.find(el => el?.displayDescription)?.id !== id) {
        return isMobile ? 29 : 19;
      } else return 0;
    } else return 0;
  };

  return (
    <>
      <Paper elevation={ 3 } className={ styles.block }>
        <div className={ styles.titleContainer }>
          <IconSheet className={ styles.iconMenu }/>
          <p className={ styles.title }>{ t('individualProgramMenuBlock.menuForDay') }</p>
        </div>
        { state.eatings.map((item, i) => (
          <List
            ref={ el => (myRefs.current[i] = el) }
            component="nav" aria-labelledby="nested-list-subheader" className={ styles.root } key={ item.displayHeader }>
            { item.displayDescription &&
              <>
                <ListItem className={ styles.listItem } button onClick={ () => handleClick(item.id, i) } style={ {
                  marginTop: selectItemMargin(item.id)
                } }>
                  <ListItemText
                    disableTypography
                    primary={
                      <Paragraph className={ item.id === open ? styles.listItemActive : styles.listItemDashed }>
                        { item.displayHeader }
                      </Paragraph>
                    }
                  />
                </ListItem>
                <Collapse in={ item.id === open } timeout="auto" unmountOnExit>
                  <div dangerouslySetInnerHTML={ { __html: item.displayDescription } } className={ styles.displayDescription } />
                </Collapse>
              </>
            }
          </List>
        )) }
      </Paper>
    </>
  );
};

export default ContainerDietIndividualProgram(ContainerSubscribe(ProgramMenuByDayBlock));
