import axios from 'axios';
import { TLoggingState } from 'app/entities/logging/logging.reducer';
import { IRootState } from 'app/shared/reducers/root.reducer';
import { BASENAME } from 'app/configs/const';

export const LOGGING_ACTIONS = {
  SET_STATE: 'LOGGING::STATE:SET',
  START_VIDEO: 'LOGGING::VIDEO:START',
  PART_VIDEO: 'LOGGING::VIDEO:PART'
};

export const logPosition = (newState: TLoggingState) => () => {
  axios.post(`${BASENAME}/api/userAction/position`, newState);
};

export const setState = (newState: TLoggingState) => ({
  type: LOGGING_ACTIONS.SET_STATE,
  payload: newState
});

export const logLogin = (position: string) => (_, getState: () => IRootState) => {
  const state = getState().logging;
  axios.post(`${BASENAME}/api/userAction/login`, { ...state, position });
};

export const logLogout = (position: string) => (_, getState: () => IRootState) => {
  const state = getState().logging;
  return axios.post(`${BASENAME}/api/userAction/logout`, { ...state, position });
};

export const logSubscriptionChange = (isSubscribe: boolean, subscribeId: number, position: string) => (_, getState: () => IRootState) => {
  const state = getState().logging;
  axios.post(`${BASENAME}/api/userAction/${isSubscribe ? '' : 'un'}subscribe`, { ...state, subscribeId, position });
};

export const logView = (newState: TLoggingState) => (_, getState: () => IRootState) => {
  const state = getState().logging;
  axios.post(`${BASENAME}/api/userAction/view`, { ...state, ...newState });
};

export const logDownload = (newState: TLoggingState) => (_, getState: () => IRootState) => {
  const state = getState().logging;
  axios.post(`${BASENAME}/api/userAction/download`, { ...state, ...newState });
};

const navigator = window.navigator as any;

export interface ILogStartVideo { accessToken: string; contentId: string; }
export const logStartVideo = (params: ILogStartVideo) => (_, getState: () => IRootState) => {
  const state = getState().logging;
  return {
    type: LOGGING_ACTIONS.START_VIDEO,
    payload:
      axios.post(`${BASENAME}/api/contentView/start`, {
        ...state,
        accessToken: params.accessToken,
        contentId: params.contentId,
        deviceType: navigator.platform,
        osType: navigator.oscpu
      })
  };
};

export interface ILogPartVideo { tickSeconds: string; contentId: string; contentViewId: string; }
export const logPartVideo = (params: ILogPartVideo) => {
  axios.post(`${BASENAME}/api/contentView/tick`, {
    contentId: params.contentId,
    contentViewId: params.contentViewId,
    tickSeconds: params.tickSeconds
  });
};
