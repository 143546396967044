//#region imports
import React, { FC, memo, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Header } from '@megafon/ui-core';

import IconArrow from 'app/components/shared/icons/arrow/icon.arrow';
import { SECTIONS } from 'app/configs/route.names';
import { HeadlineProps } from './headline.model';
import { stylesHeadline } from 'app/pages/desktop/main/components/headline/headline.styles';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

//#region Headline
const Headline: FC<HeadlineProps> = memo(
  forwardRef<HTMLDivElement, HeadlineProps>((props, ref) => {
    const { category, activeSubcategoryId } = props;
    const { t } = useTranslation();
    const { isNotDesktop } = useBreakpoints();
    const classes = stylesHeadline({ isNotDesktop });

    return (
      <Grid className={ classes.container } ref={ ref } container alignItems="baseline" justifyContent="space-between" spacing={ 1 }>
        <Link className={ classes.title } to={ SECTIONS(category?.id, activeSubcategoryId) }>
          <Header as={ 'h3' } className={ classes.header }> { category.name }<IconArrow className={ classes.icon }/></Header>
        </Link>
      </Grid>
    );
  })
);
Headline.displayName = 'HeadlineMain';
//#endregion

export default Headline;
