import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProps } from 'app/models/model.icons';

const IconSheet: FC<IIconProps> = ({ width= 37, height= 43, fill, ...props }) => (
  /* tslint:disable */
  <SvgIcon width={ width } height={ height } viewBox={ `0 0 ${ width } ${ height }` } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <path d="M9.90005 20.75H9.15005V22.25H9.90005V20.75ZM27.1 22.25H27.85V20.75H27.1V22.25ZM21.3667 29.35H20.6167V30.85H21.3667V29.35ZM27.1 30.85H27.85V29.35H27.1V30.85ZM9.90005 12.15H9.15005V13.65H9.90005V12.15ZM15.6334 13.65H16.3834V12.15H15.6334V13.65ZM27.1 1.43335L27.6304 0.90302L27.4107 0.68335H27.1V1.43335ZM35.7 10.0333H36.45V9.72269L36.2304 9.50302L35.7 10.0333ZM9.90005 22.25H27.1V20.75H9.90005V22.25ZM21.3667 30.85H27.1V29.35H21.3667V30.85ZM9.90005 13.65H15.6334V12.15H9.90005V13.65ZM32.8334 40.8167H4.16671V42.3167H32.8334V40.8167ZM2.05005 38.7V4.30002H0.550049V38.7H2.05005ZM4.16672 2.18335H27.1V0.68335H4.16672V2.18335ZM34.95 10.0333V38.7H36.45V10.0333H34.95ZM26.5697 1.96368L35.1697 10.5637L36.2304 9.50302L27.6304 0.90302L26.5697 1.96368ZM4.16671 40.8167C2.99771 40.8167 2.05005 39.869 2.05005 38.7H0.550049C0.550049 40.6974 2.16928 42.3167 4.16671 42.3167V40.8167ZM32.8334 42.3167C34.8308 42.3167 36.45 40.6974 36.45 38.7H34.95C34.95 39.869 34.0024 40.8167 32.8334 40.8167V42.3167ZM2.05005 4.30002C2.05005 3.13101 2.99771 2.18335 4.16672 2.18335V0.68335C2.16929 0.68335 0.550049 2.30259 0.550049 4.30002H2.05005Z" fill={ fill ? fill : "#721983"}/>
  </SvgIcon>
  /* tslint:enable */
);

export default IconSheet;
