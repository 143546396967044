import React, { FC } from 'react';
import { ISectionItem } from 'app/models/model.section';

import NavbarInfinity, { INavbarInfinityProps } from 'app/components/shared/navbar/infinity/navbar.infinity';

interface ICategoryMenuProps extends Omit<INavbarInfinityProps, 'navMenuItems' | 'initialItemId'> {
  items: ISectionItem[];
  initialItemId: string | number;
  onClickItem: (id: string | number) => void;
}

const CategoryButtonsMenu: FC<ICategoryMenuProps> = ({ items = [], initialItemId = '', ...rest }) => {

  const navMenuItems = items?.map(item => ({
    name: item?.name ?? item?.title,
    link: item?.link,
    itemId: item?.id?.toString()
  }));

  return (
    <NavbarInfinity navMenuItems={ navMenuItems } initialItemId={ initialItemId.toString() } { ...rest } />
  );
};

CategoryButtonsMenu.displayName = 'CategoryButtonsMenu';

export default CategoryButtonsMenu;
