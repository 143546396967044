import { createStyles, makeStyles } from '@material-ui/core/styles';

import { configTheme } from 'app/configs/theme/config.theme';

export const stylesError = makeStyles<unknown, { isMobile?: boolean }>(
  createStyles({
    root: {},

    errorIcon: {
      '&:not(&:first-child)': { marginTop: 50 }
    },
    logo: {
      cursor: 'pointer'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: ({ isMobile }) => isMobile ? 58 : 80,
      backgroundColor: configTheme.megafonColors.basic.white,
      boxShadow: '0px 7px 6px rgba(0, 0, 0, 0.08)'
    },
    title: {
      display: 'block',
      color: configTheme.megafonColors.basic.black.normal,
      fontSize: ({ isMobile }) => isMobile ? 24 : 36,
      lineHeight: ({ isMobile }) => isMobile ? '30px' : '44px',
      textAlign: 'center',
      letterSpacing: '-0.3px'
    },
    descr: {
      display: 'block',
      marginTop: 20,
      color: configTheme.megafonColors.basic.black.normal,
      fontSize: ({ isMobile }) => isMobile ? 14 : 19,
      lineHeight: ({ isMobile }) => isMobile ? '17px' : '23px',
      textAlign: 'center',
      letterSpacing: '-0.3px'
    },

    content: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center'
    },

    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0
    },
    item: { paddingTop: 40 },
    button: {
      padding: '0 70px',
      '& .mfui-button__inner': {
        height: ({ isMobile }) => isMobile ? 43 : 60,
        fontSize: ({ isMobile }) => isMobile ? 16 : 19
      }
    },
    icon: {
      width: ({ isMobile }) => isMobile ? 100 : 170,
      height: ({ isMobile }) => isMobile ? 90 : 170,
      margin: ({ isMobile }) => isMobile ? '60px 0 30px 0' : '100px 0 30px 0',
      color: configTheme.colors.notification.warning.default
    }
  }),
  { name: 'ComponentError' }
);
