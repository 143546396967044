//#region imports
import React, { FC, memo, forwardRef } from 'react';
import { Skeleton } from '@material-ui/lab';
import cx from 'classnames';
import isFunction from 'lodash/isFunction';
import includes from 'lodash/includes';
import slice from 'lodash/slice';
import map from 'lodash/map';

import { CONTENT_LIST, CONTENT_ITEM } from 'app/configs/route.names';
import { EAgeType } from 'app/models/shared.model';
import { ISectionItem } from 'app/models/model.section';
import { IContent, EContentType } from 'app/models/model.content';
import ItemCard from 'app/components/desktop/item.card/item.card.index';
import FollowLink from 'app/components/follow-link/follow-link.index';
import stylesEllipsis from 'app/hooks/useEllipsisStyles';

import { CategoryBlocksProps, ECategoryBlocksClassKey } from './category.models';
import { useCategoryBlocksStyles } from './category.styles';
import { useHistory } from 'react-router-dom';
//#endregion

const convertContentType = contentType =>
  includes([EContentType.VIDEO, EContentType.AUDIO, EContentType.STUDY, EContentType.OLYMPIAD], contentType)
    ? EContentType.IMAGE
    : contentType;

//#region CategoryBlocks
export const CategoryBlocks: FC<CategoryBlocksProps> = memo(
  forwardRef<HTMLDivElement, CategoryBlocksProps>((props, ref) => {
    const { className, style, classes, type, view, category, data, loading, uAgeType, onConfirm } = props;
    const ellipsisStyles = stylesEllipsis({});
    const styles = useCategoryBlocksStyles({ classes });
    const history = useHistory();

    const isAgeAdult = uAgeType === EAgeType.ADULT;
    const handleClick = (e, url) => {
      if (!isAgeAdult && category?.ageType === EAgeType.ADULT) {
        e.preventDefault();
        if (isFunction(onConfirm)) onConfirm({ type: EAgeType.ADULT, url });
      }
    };

    const onSectionClick = id => {
      history.push(CONTENT_LIST(category?.id, id));
    };

    const onItemClick = (itemType, id) => {
      history.push(CONTENT_ITEM(itemType, id));
    };

    const render = () => {
      if (loading || !data?.length) {
        if (view === 'cell') {
          return (
            <>
              <div className={ styles[ECategoryBlocksClassKey.item] }>
                <Skeleton variant="rect" width="100%" height={ 360 } animation="wave" />
              </div>
              <div className={ styles[ECategoryBlocksClassKey.item] }>
                <Skeleton variant="rect" width="100%" height={ 360 } animation="wave" />
              </div>
              <div className={ styles[ECategoryBlocksClassKey.item] }>
                <Skeleton variant="rect" width="100%" height={ 360 } animation="wave" />
              </div>
              <div className={ styles[ECategoryBlocksClassKey.item] }>
                <Skeleton variant="rect" width="100%" height={ 360 } animation="wave" />
              </div>
              <div className={ styles[ECategoryBlocksClassKey.item] }>
                <Skeleton variant="rect" width="100%" height={ 360 } animation="wave" />
              </div>
            </>
          );
        }
        return (
          <>
            <div className={ styles[ECategoryBlocksClassKey.item] }>
              <Skeleton variant="rect" width="100%" height={ 290 } animation="wave" />
            </div>
            <div className={ styles[ECategoryBlocksClassKey.item] }>
              <Skeleton variant="rect" width="100%" height={ 290 } animation="wave" />
            </div>
          </>
        );
      }

      const displayData = data?.length >= 5 ? slice(data as ISectionItem[], 0, 5) : slice(data as ISectionItem[], 0, 2);
      return (
        <>
          { map(displayData, (item: unknown) => {
            let contentType;
            let displayContentType;
            if (type === 'section') {
              const sectionItem = item as ISectionItem;
              contentType = sectionItem?.contentType;
              displayContentType = convertContentType(contentType);
              return (
                <div className={ cx(styles[ECategoryBlocksClassKey.item], displayContentType) }>
                  <ItemCard
                    classes={ {
                      title: styles[ECategoryBlocksClassKey.itemTitle],
                      root: styles[ECategoryBlocksClassKey.itemRoot],
                      picture: styles[ECategoryBlocksClassKey.itemPicture],
                      descr: styles[ECategoryBlocksClassKey.itemDescr],
                      pictureFigure: styles[ECategoryBlocksClassKey.pictureFigure]
                    } }
                    format="compact"
                    contentType={ displayContentType }
                    preview={ { src: sectionItem.desktopPreview || sectionItem.preview } }
                    title={ sectionItem.name }
                    withIndicator={ false }
                    onPictureClick={ () => onSectionClick(sectionItem.id) }
                    onTitleClick={ () => onSectionClick(sectionItem.id) }
                  />
                </div>
              );
            } else if (type === 'content') {
              const contentItem = item as IContent;
              contentType = contentItem?.type;
              displayContentType = convertContentType(contentType);
              return (
                <div className={ cx(styles[ECategoryBlocksClassKey.item], displayContentType) }>
                  <ItemCard
                    classes={ {
                      title: styles[ECategoryBlocksClassKey.itemTitle]
                    } }
                    titleClamp={ 1 }
                    format="compact"
                    contentType={ displayContentType }
                    preview={ { src: contentItem.desktopImageUrl || contentItem.imageUrl } }
                    title={ contentItem.name }
                    data={ contentItem?.data }
                    withIndicator={ false }
                    onPictureClick={ () => onItemClick(contentType, contentItem.id) }
                    onTitleClick={ () => onItemClick(contentType, contentItem.id) }
                  />
                </div>
              );
            }
            return null;
          }) }
        </>
      );
    };

    return (
      <div
        ref={ ref }
        className={ cx(className, styles[ECategoryBlocksClassKey.root], {
          [styles[ECategoryBlocksClassKey.box]]: view === 'box',
          [styles[ECategoryBlocksClassKey.cell]]: view === 'cell'
        }) }
        style={ style }
      >
        { render() }
      </div>
    );
  })
);
CategoryBlocks.displayName = 'CategoryBlocksMain';

CategoryBlocks.defaultProps = { data: [], loading: false } as CategoryBlocksProps;
//#endregion
