//#region imports
import React, { FC } from 'react';
import { Dialog, DialogTitle, IconButton } from '@material-ui/core';
import IconClose from 'app/components/shared/icons/close/icon.close';

import stylesModal from 'app/components/shared/modal/modal.styles';
import { IModalProps } from 'app/components/shared/modal/modal.models';
import cn from 'classnames';
//#endregion

const Modal: FC<IModalProps> = props => {
  const {
    size,
    open,
    children,
    title,
    hasCloseButton,
    className,
    onClose
  } = props;

  const styles = stylesModal({ size });

  return (
    <Dialog disableScrollLock onClose={ onClose } open={ open } className={ cn(styles.root, className) }>
      { title &&
        <DialogTitle className={ styles.title }>
          { title }
        </DialogTitle>
      }
      { hasCloseButton &&
        <IconButton aria-label="close" onClick={ onClose } className={ styles.close }>
          <IconClose/>
        </IconButton>
      }
      { children }
    </Dialog>
  );
};

Modal.displayName = 'Modal';

Modal.defaultProps = {
  size: 'xs'
} as IModalProps;

export default Modal;
