//#region imports
import React, { FC, memo, useEffect } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import toUpper from 'lodash/toUpper';
import flow from 'lodash/fp/flow';
import _ from 'lodash';

import { ELoadStatus } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';

import { ContainerSectionItem } from 'app/containers/container.section.item';
import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerUser } from 'app/containers/container.user';
import { ContainerSubscribe } from 'app/containers/container.subscribe';

import ContentMediaVideo from './video/content.media.video.index';
import { IDesktopContentMediaPageProps } from './page.content.media.models';
import { useDesktopContentMediaPageStyles } from './page.content.media.styles';
import { ESubscriptionStatus } from 'app/models/model.subscription';
import { ContainerRoute } from 'app/containers/container.route';
import usePrevRoute from 'app/hooks/use.prev.route';
import { ContainerLogging } from 'app/containers/container.logging';
//#endregion

const DesktopContentMediaPage: FC<IDesktopContentMediaPageProps> = memo<IDesktopContentMediaPageProps>(props => {
  const { containerUser, containerContentItem, containerSectionItem, containerSubscribe, containerRoute, containerLogging } = props;
  const { authenticated, actions: { logView, logPosition } } = containerLogging;

  const { pathname } = useLocation();
  const history = useHistory();
  const params = useParams<any>();
  const styles = useDesktopContentMediaPageStyles();

  const content = containerContentItem.item;
  const section = containerContentItem.section?.section;
  const subscribe = containerContentItem.section?.subscribe;
  const accessible = containerUser?.data?.authenticated;

  const location = useLocation<any>();
  const contentId = params?.contentId || 0;
  const contentType = toUpper(content?.type) as EContentType;
  const distribution = containerContentItem.section?.distribution;
  const sectionId = section?.parentId ?? section?.id;
  const subsectionId = section?.parentId ? section?.id : undefined;
  const categoryId = containerContentItem.section?.subcategory?.categoryId ?? containerSectionItem.subcategory?.categoryId;
  const subcategoryId = containerContentItem.section?.subcategory?.id ?? containerSectionItem.subcategory?.id;
  const { actions: containerRouteActions, route: containerRouteData } = containerRoute;

  usePrevRoute({ containerRouteActions });

  useEffect(() => {
    if (contentId) {
      containerContentItem.actions.fetchContentItem(contentId, { cancellable: true });
    }
    return () => {
      containerContentItem.actions.resetContentItem();
    };
  }, [contentId, containerSubscribe.status.change]);

  const loading = containerContentItem.status.item === ELoadStatus.loading;
  const ready = containerContentItem.status.item === ELoadStatus.ready;

  useEffect(() => {
    if (ready) {
      if (!containerContentItem?.item?.free && subscribe.status !== ESubscriptionStatus.ACTIVE) {
        history.replace('/');
      }
    }
  }, [ready]);

  useEffect(() => {
    if (authenticated && !_.isEmpty(containerContentItem.section)) {
      const newData = {
        position: pathname,
        categoryId: containerContentItem.section?.subcategory?.categoryId,
        subcategoryId: containerContentItem.section?.subcategory?.id,
        sectionId: containerContentItem.section?.section?.id,
        contentId,
        subscribeId: containerContentItem.section?.subscribe?.id
      };
      logPosition({ ...newData });
      logView({ ...newData });
    }
  }, [containerContentItem.section]);

  if (location?.state?.src || (ready && (containerContentItem?.item?.free || subscribe?.status === ESubscriptionStatus.ACTIVE))) {
    switch (contentType || params?.contentType) {
      case EContentType.VIDEO:
        return (
          <ContentMediaVideo
            containerUser={ containerUser }
            history={ history }
            location={ location }
            subscribe={ subscribe }
            contentId={ contentId }
            sectionId={ sectionId }
            src={ location?.state?.src }
            title={ location?.state?.title }
            subsectionId={ subsectionId }
            content={ content }
            section={ section }
            contentType={ contentType }
            accessible={ accessible }
            distribution={ distribution }
            loading={ loading }
            ready={ ready }
            prevRoute={ containerRouteData }
          />
        );
      default:
        return <div className={ styles.root } />;
    }
  }

});
DesktopContentMediaPage.displayName = 'DesktopContentMediaPage';

export default flow([
  ContainerUser,
  ContainerContentItem,
  ContainerSectionItem,
  ContainerSubscribe,
  ContainerRoute,
  ContainerLogging
])(DesktopContentMediaPage);
