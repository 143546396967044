import { ThunkDispatch } from 'redux-thunk';

import { connect } from 'app/containers/connect';

import { TRouteState } from 'app/entities/route/route.reducer';
import {
  IRouteActions,
  updateRoute
} from 'app/entities/route/route.actions';

import { IRootState } from 'app/shared/reducers/root.reducer';

const mapStateToProps = (state: IRootState): TRouteState => state.route;
const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, unknown, any>): IRouteActions => ({
  updateRoute: (route: string) => dispatch(updateRoute(route))
});

export type TContainerRouteProps = ReturnType<typeof mapStateToProps> & {
  actions: ReturnType<typeof mapDispatchToProps>;
};

export const ContainerRoute = connect<TContainerRouteProps>('containerRoute', mapStateToProps, mapDispatchToProps);
