export const loadLibrary = (skin = 'amp-default') =>
  new Promise((resolve, reject) => {
    if (document.querySelector('#amp-azure')) return resolve({ skin });
    const scriptTag = document.createElement('script');
    const linkTag = document.createElement('link');
    linkTag.rel = 'stylesheet';
    scriptTag.id = 'amp-azure';
    scriptTag.src = '//amp.azure.net/libs/amp/latest/azuremediaplayer.min.js';
    linkTag.href = `//amp.azure.net/libs/amp/latest/skins/${skin}/azuremediaplayer.min.css`;
    document.body.appendChild(scriptTag);
    document.head.insertBefore(linkTag, document.head.firstChild);
    scriptTag.onload = () => resolve({ skin });
  });
