import React, { FC } from 'react';
import { configTheme } from 'app/configs/theme/config.theme';
import IconLogo from 'app/assets/img/logo.svg';
import stylesLogo, { IStylesLogoProps } from './logo.styles';

export interface ILogoProps extends IStylesLogoProps {
  description?: string;
  isTabletMenu?: boolean;
}

const Logo: FC<ILogoProps> = ({ description, fontSize, lineHeight, color, commentColor, width, isTabletMenu }) => {
  const classes = stylesLogo({
    fontSize, lineHeight, color: color || configTheme.megafonColors.basic.black.normal, commentColor, width, isTabletMenu
  });

  return (
    <div className={ classes.root }>
      <img src={ IconLogo } alt="logo megafon" className={ classes.logo }/>
      { description &&
        <span className={ classes.description }>
          { description }
        </span>
      }
    </div>
  );
};

Logo.defaultProps = {
  fontSize: 17,
  width: 135
};

Logo.displayName = 'Logo';

export default Logo;
