//#region imports
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

interface IProps {
  isNotDesktop?: boolean;
  isMobile?: boolean;
}

export const stylesContentListPage = makeStyles<Theme, IProps>(theme =>
  createStyles({
    root: {},

    title: {
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 19 : 24,
      color: configTheme.colors.text.light.default
    },

    caption: {},

    contentList: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      marginTop: ({ isNotDesktop }) => isNotDesktop ? 20 : 40,
      marginBottom: ({ isNotDesktop }) => isNotDesktop ? 10 : 10
    },
    contentItem: {
      margin: '0 auto',
      marginTop: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 15 : 20) : 35,
      '&:first-child': { marginTop: 0 },
      '&:last-child': {
        marginBottom: ({ isMobile }) => isMobile ? 10 : 30
      },
      '& img': {
        borderRadius: 4,
        height: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 210 : 282) : 416
      }
    },

    contentSheet: {
      display: 'flex',
      flexFlow: 'row wrap',
      marginTop: 10
    },
    contentCell: {
      margin: `10px 30px 0 0`,
      flex: '1 1 calc((100% / 5) - 30px)',
      maxWidth: 'calc((100% / 5) - 30px + (30px / 5))',
      width: 'calc((100% / 5) - 30px + (30px / 5))',
      '&:nth-child(-n + 5)': { marginTop: 0 },
      '&:nth-child(5n)': { marginRight: 0 }
    },
    contentGrid: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: ({ isMobile }) => isMobile ? 20 : 30,
      paddingBottom: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 20 : 30) : 0,
      [theme.breakpoints.down(870)]: {
        flexWrap: 'nowrap',
        flexDirection: 'column'
      }
    },
    contentGridItem: {
      flex: '0 calc(50% - 15px)',
      minWidth: 280,
      margin: '0 25px 30px 0',
      '&:nth-child(2n)': { marginRight: 0 },
      [theme.breakpoints.down(870)]: {
        flex: '100%',
        marginRight: 0,
        '&:last-child': { marginBottom: 0 }
      }
    },

    buttonContainer: {
      display: 'flex'
    },
    moreButton: {
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 16 : 19,
      cursor: 'pointer',
      margin: ({ isNotDesktop, isMobile }) => isNotDesktop ? '0 auto' : '0px auto 30px auto',
      padding: ({ isMobile }) => isMobile ? '0 40px' : '0 70px',
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      '& .mfui-button__inner': {
        height: ({ isMobile }) => isMobile ? 43 : 60
      }
    },

    recommendPack: {
      marginTop: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 30 : 35) : 40,
      paddingBottom: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 40 : 50) : 60
    },
    recommendsCard: {
      height: '100%',
      border: 'none'
    },
    recommendContainer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start',
      marginTop: 10,
      paddingBottom: ({ isMobile }) => isMobile ? 30 : 80
    },
    recommendList: {
      marginLeft: ({ isMobile }) => isMobile ? 0 : -20
    },
    recommendItem: {
      flex: '1 1 calc((100% / 3) - 15px)',
      maxWidth: 'calc((100% / 3) - 15px + (15px / 3))',
      width: 'calc((100% / 3) - 15px + (15px / 3))',
      margin: `10px 15px 0 0`,
      '&:nth-child(-n + 3)': { marginTop: 0 },
      '&:nth-child(3n)': { marginRight: 0 }
    },
    recommendsTitle: {
      margin: ({ isMobile }) => isMobile ? '10px 0 30px 0' : '30px 0 20px 0',
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      fontSize: ({ isMobile }) => isMobile ? 24 : 36
    },
    categoryName: {
      margin: 0,
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 18 : 19,
      cursor: 'pointer'
    },
    categoryImg: {
      width: ({ isNotDesktop }) => isNotDesktop ? 280 : 339
    }
  })
);
