//#region imports
import React, { FC, useEffect, useState, useMemo } from 'react';
import { useDrag } from 'app/hooks/use.drag';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-next';
import NavbarInfinityItem, { INavbarInfinityItem } from './navbar.infinity.item';
import NavbarInfinityArrowLeft from './arrow/navbar.infinity.arrow.left';
import NavbarInfinityArrowRight from './arrow/navbar.infinity.arrow.right';
import stylesNavbarInfinity from './navbar.infinity.styles';
//#endregion

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export interface INavbarInfinityProps {
  className?: string;
  navMenuItems: INavbarInfinityItem[];
  initialItemId?: INavbarInfinityItem['itemId'];
  onClickItem?: (id: INavbarInfinityItem['itemId']) => void;
  maxItems?: number;
  getMoreItems?: () => void;
  buttons?: boolean;
  mobile?: boolean;
}

const NavbarInfinity: FC<INavbarInfinityProps> = props => {
  const { navMenuItems = [], className = '', initialItemId, onClickItem, getMoreItems, maxItems, buttons, mobile } = props;

  const { dragStart, dragStop, dragMove } = useDrag();

  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (e: React.MouseEvent) =>
    dragMove(e, posDiff => {
      if (!scrollContainer.current) return;
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });

  const onMouseDown = () => dragStart;
  const onMouseUp = () => dragStop;

  const [activeItemId, setActiveItemId] = useState<INavbarInfinityItem['itemId']>(navMenuItems[0]?.itemId);

  const classes = stylesNavbarInfinity({ buttons, mobile });
  const apiRef = React.useRef({} as scrollVisibilityApiType);

  const onSelect = (itemId: INavbarInfinityItem['itemId']) => {
    if (itemId === activeItemId) {
      onClickItem && onClickItem(itemId);
    } else {
      setActiveItemId(itemId);
      onClickItem && onClickItem(itemId);
    }
  };

  useEffect(() => {
    if (initialItemId) {
      setActiveItemId(initialItemId);
    }
  }, [initialItemId]);

  useEffect(() => {
    // при запуске не скроллится к активному элементу, решается отложенным запуском
    const timerId = setTimeout(() => {
      if (activeItemId) {
        const currentItem = apiRef.current.getItemById(activeItemId.toString());
        apiRef.current.scrollToItem(currentItem, 'smooth', 'start');
      }
    }, 1000);
    return () => clearTimeout(timerId);
  }, [activeItemId, apiRef]);

  const linkList = useMemo(() => (
    navMenuItems && navMenuItems.length > 0 && navMenuItems.map(({ itemId, ...rest }, index) => (
      <NavbarInfinityItem
        key={ itemId }
        isActive={ activeItemId === itemId }
        onSelect={ onSelect }
        itemId={ itemId }
        buttons={ buttons }
        mobile={ mobile }
        isFirst={ index === 0 }
        isLast={ index === navMenuItems.length - 1 }
        { ...rest }
      />
    ))
  ), [navMenuItems, activeItemId]);

  return (
    <div className={ `${classes.root} ${className}` } onMouseLeave={ dragStop }>
      <ScrollMenu
        apiRef={ apiRef }
        LeftArrow={ NavbarInfinityArrowLeft }
        RightArrow={ <NavbarInfinityArrowRight maxItems={ maxItems } getMoreItems={ getMoreItems } /> }
        scrollContainerClassName={ classes.scrollContainer }
        wrapperClassName={ classes.container }
        itemClassName={ classes.itemWrap }
        onMouseDown={ onMouseDown }
        onMouseUp={ onMouseUp }
        onMouseMove={ handleDrag }
      >
        { linkList }
      </ScrollMenu>
    </div>
  );
};

NavbarInfinity.displayName = 'NavbarInfinity';

export default NavbarInfinity;
