//#region imports
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogActions, DialogContent } from '@material-ui/core';
import isFunction from 'lodash/isFunction';
import classnames from 'classnames';
import { Button } from '@megafon/ui-core';

import Input from 'app/components/shared/input/input.index';
import TextField from '@material-ui/core/TextField';
import stylesModal from 'app/components/shared/modal/modal.styles';
import { IModalProps } from 'app/components/shared/modal/modal.models';
import Modal from 'app/components/shared/modal/modal.index';
import { InputMaskPhone } from 'app/components/custom.input/input.mask.phone';
//#endregion

export interface IModalLoginProps extends IModalProps {
  footer?: ReactNode;
}

const ModalLogin: FC<IModalLoginProps> = props => {
  const {
    className,
    size,
    desc,
    input,
    buttons,
    onClose,
    onClick,
    onChange,
    renderInfo,
    footer
  } = props;

  const { t } = useTranslation();
  const styles = stylesModal({ size });

  const handleClick = (): void => {
    if (isFunction(onClick)) onClick();
  };

  const handleClose = (): void => {
    if (isFunction(onClose)) onClose();
  };

  return (
    <Modal { ...props }>
      { desc &&
        <DialogContent className={ classnames(className, {
          [styles[size]]: size
        }) }>
          <div className={ styles.desc }>{ desc }</div>
        </DialogContent>
      }
      <DialogActions className={ styles.content }>
        <div className={ styles.buttons }>
          <TextField
            onChange={ onChange }
            variant={ 'outlined' }
            name={ input?.name }
            value={ input?.value }
            placeholder={ input?.placeholder }
            { ...(input?.mask && { InputProps: {
              inputComponent: InputMaskPhone,
              inputProps: { mask: input?.mask }
            } }) }
          />
          <div className={ styles.renderInfo }>
            { renderInfo && renderInfo }
          </div>
          <Button
            onClick={ handleClick }
          >
            { buttons?.submit?.title || t('components.shared.modal.login.button.submit') }
          </Button>
          { footer && footer }
        </div>
      </DialogActions>
    </Modal>
  );
};

ModalLogin.displayName = 'ModalLogin';

ModalLogin.defaultProps = {
  open: true,
  size: 'xs',
  hasCloseButton: false,
  title: 'Вход по SMS'
} as IModalProps;

export default ModalLogin;
