import { makeStyles } from '@material-ui/core/styles';

import { configTheme } from 'app/configs/theme/config.theme';

interface IProps {
  isFirst?: boolean;
  isMobile?: boolean;
}

export const useStylesRadiosCheck = makeStyles<{}, IProps>({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiTypography-body1': {
      fontFamily: 'inherit'
    }
  },
  icon: {
    width: ({ isMobile }) => isMobile ? 147 : 165,
    height: ({ isMobile }) => isMobile ? 30 : 35,
    backgroundColor: configTheme.megafonColors.basic.white,
    border: `1px solid ${ configTheme.megafonColors.basic.green.default }`,
    borderRadius: ({ isFirst }) => isFirst ? '50px 0 0 50px' : '0 50px 50px 0',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    }
  },
  checkedIcon: {
    backgroundColor: configTheme.megafonColors.basic.green.default,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""'
    }
  }
});
