import { Theme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles<Theme, { isMobile?: boolean }>(
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center'
    },
    label: {
      padding: '0 0 0 10px',
      margin: 0,
      fontSize: 16
    },
    icon: {
      width: ({ isMobile }) => isMobile ? 25 : 28,
      height: ({ isMobile }) => isMobile ? 25 : 28
    }
  })
);
