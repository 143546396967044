//#region imports
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

export default makeStyles(
  createStyles({
    root: mobile => ({
      boxSizing: 'border-box',
      position: 'absolute',
      bottom: 0,
      right: mobile ? '20px' : '50px',
      left: mobile ? '20px' : '50px',
      maxHeight: 107,
      padding: 24,
      borderRadius: 6,
      fontSize: '1rem',
      color: configTheme.megafonColors.background.light,
      backgroundColor: configTheme.megafonColors.background.grey.medium
    }),

    buttons: {},

    button: {
      cursor: 'pointer',
      margin: 0,
      padding: 0,
      outline: 0,
      border: '0 none',
      color: 'currentColor',
      backgroundColor: 'transparent'
    },

    btnItemCol: { minWidth: '33%', maxWidth: '33%' },

    playback: { margin: '0 auto' },

    playbackIcon: { fontSize: 55 }
  }),
  { name: 'PlayerControls' }
);
