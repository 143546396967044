import React, { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IIconProps } from 'app/models/model.icons';

const IconDocument: FC<IIconProps> = ({ width= 31, height= 31, ...props }) => (
  /* tslint:disable */
  <SvgIcon width={ width } height={ height } viewBox={ `0 0 ${ width } ${ height }` } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
    <path d="M9.3001 14.7407H8.5501V16.2407H9.3001V14.7407ZM21.7001 16.2407H22.4501V14.7407H21.7001V16.2407ZM9.3001 20.9501H8.5501V22.4501H9.3001V20.9501ZM21.7001 22.4501H22.4501V20.9501H21.7001V22.4501ZM9.3001 8.54469H8.5501V10.0447H9.3001V8.54469ZM21.7001 10.0447H22.4501V8.54469H21.7001V10.0447ZM27.9001 7.23345H28.6501V6.92279L28.4304 6.70312L27.9001 7.23345ZM21.7001 1.03345L22.2304 0.503117L22.0108 0.283447H21.7001V1.03345ZM9.3001 16.2407H21.7001V14.7407H9.3001V16.2407ZM9.3001 22.4501H21.7001V20.9501H9.3001V22.4501ZM9.3001 10.0447H21.7001V8.54469H9.3001V10.0447ZM25.8334 29.2168H5.16676V30.7168H25.8334V29.2168ZM3.8501 27.9001V3.10011H2.3501V27.9001H3.8501ZM27.1501 7.23345V27.9001H28.6501V7.23345H27.1501ZM5.16676 1.78345H21.7001V0.283447H5.16676V1.78345ZM21.1698 1.56378L27.3698 7.76378L28.4304 6.70312L22.2304 0.503117L21.1698 1.56378ZM5.16676 29.2168C4.43959 29.2168 3.8501 28.6273 3.8501 27.9001H2.3501C2.3501 29.4557 3.61116 30.7168 5.16676 30.7168V29.2168ZM25.8334 30.7168C27.389 30.7168 28.6501 29.4557 28.6501 27.9001H27.1501C27.1501 28.6273 26.5606 29.2168 25.8334 29.2168V30.7168ZM3.8501 3.10011C3.8501 2.37294 4.43959 1.78345 5.16676 1.78345V0.283447C3.61116 0.283447 2.3501 1.54451 2.3501 3.10011H3.8501Z" fill="#00B956"/>
  </SvgIcon>
  /* tslint:enable */
);

export default IconDocument;
