//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

const computeXsSizeStyles = () => ({
  '& svg': {
    height: '50px',
    width: '50px'
  }
});

const computeSmSizeStyles = () => ({
  '& svg': {
    height: '59px',
    width: '59px'
  }
});

const computeMdSizeStyles = () => ({
  '& svg': {
    height: '59px',
    width: '59px'
  }
});

const computeLgSizeStyles = () => ({
  '& svg': {
    height: '80px',
    width: '80px'
  }
});

const computeXlSizeStyles = () => ({
  '& svg': {
    height: '80px',
    width: '80px'
  }
});

export default makeStyles(
  createStyles({
    root: {
      borderRadius: '50%',
      '& svg': {
        fill: configTheme.megafonColors.basic.green.default
      }
    },
    xxs: computeXsSizeStyles(),
    xs: computeXsSizeStyles(),
    sm: computeSmSizeStyles(),
    md: computeMdSizeStyles(),
    lg: computeLgSizeStyles(),
    xl: computeXlSizeStyles()
  }),
  { name: 'ButtonPlay' }
);
