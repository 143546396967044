import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconPlay: FC <SvgIconProps> = props => (
  <SvgIcon width="59" height="59" viewBox={ '0 0 59 59' } fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <circle cx="29.4998" cy="29.5001" r="19.8953" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 29.5C0 13.2076 13.2076 0 29.5 0C45.7924 0 59 13.2076 59
      29.5C59 45.7924 45.7924 59 29.5 59C13.2076 59 0 45.7924 0 29.5ZM24.5795 19.9323C25.1859 19.5805 25.9337 19.578
      26.5424 19.9258L40.3091 27.7925C40.9218 28.1426 41.3 28.7942 41.3 29.5C41.3 30.2058 40.9218 30.8574 40.3091
      31.2075L26.5424 39.0742C25.9337 39.422 25.1859 39.4195 24.5795 39.0677C23.9732 38.7158 23.6 38.0677 23.6
      37.3667V21.6333C23.6 20.9323 23.9732 20.2842 24.5795 19.9323Z"
      fill="#00B956"
      />
  </SvgIcon>
);

export default IconPlay;
