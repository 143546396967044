//#region imports
import React, { FC, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { Button, Header } from '@megafon/ui-core';

import IconClose from 'app/components/shared/icons/close/icon.close';

import { Modal } from 'app/components/modal/modal.ui';
import { useStylesModalIndividualProgram } from './modal.individual.program.styles';
import { CustomTextField } from 'app/components/custom.input/custom.input';
import { ETarget, IUserAttributes, EResult, INextWeek } from 'app/entities/diet/program/diet.program.reducer';
import { CustomAutocomplete } from 'app/components/autocomplete/autocomplete';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

interface IModalWeekendProgram {
  openModalWeekend: boolean;
  setOpenModalWeekend: (boolean) => void;
  propState: IUserAttributes;
  onNewWeekClick: (data: INextWeek) => void;
  onCompleteClick: (data: INextWeek) => void;
}

const ModalWeekendProgram: FC<IModalWeekendProgram> = ({ openModalWeekend, setOpenModalWeekend, propState, onNewWeekClick, onCompleteClick }) => {
  const { isMobile } = useBreakpoints();
  const styles = useStylesModalIndividualProgram({ isMobile });
  const { t } = useTranslation();
  const history = useHistory();

  const options = useMemo(() => {
    switch (propState?.target) {
      case ETarget.WEIGHT_DOWN:
        return [
          { id: EResult[ETarget.WEIGHT_DOWN + '_SUCCESS'], title: 'Да, удалось снизить вес' },
          { id: EResult[ETarget.WEIGHT_DOWN + '_BETTER'], title: 'Да, я чувствую себя лучше' },
          { id: EResult[ETarget.WEIGHT_DOWN + '_FAIL'], title: 'Нет, у меня не получилось' },
          { id: EResult[ETarget.WEIGHT_DOWN + '_WRONG'], title: 'Нет, мне не подошла диета' }
        ];
      case ETarget.WEIGHT_UP:
        return [
          { id: EResult[ETarget.WEIGHT_UP + '_SUCCESS'], title: 'Да, удалось набрать вес' },
          { id: EResult[ETarget.WEIGHT_UP + '_BETTER'], title: 'Да, я чувствую себя лучше' },
          { id: EResult[ETarget.WEIGHT_UP + '_FAIL'], title: 'Нет, у меня не получилось' },
          { id: EResult[ETarget.WEIGHT_UP + '_WRONG'], title: 'Нет, мне не подошла диета' }
        ];
      case ETarget.WEIGHT_KEEP:
        return [
          { id: EResult[ETarget.WEIGHT_KEEP + '_SUCCESS'], title: 'Да, удалось удерживать вес' },
          { id: EResult[ETarget.WEIGHT_KEEP + '_BETTER'], title: 'Да, я чувствую себя лучше' },
          { id: EResult[ETarget.WEIGHT_KEEP + '_FAIL'], title: 'Нет, у меня не получилось' },
          { id: EResult[ETarget.WEIGHT_KEEP + '_WRONG'], title: 'Нет, мне не подошла диета' }
        ];
      default :
        return [
          { id: EResult.WEIGHT_UNKNOWN_DOWN, title: 'Да, удалось снизить вес' },
          { id: EResult.WEIGHT_UNKNOWN_UP, title: 'Да, удалось набрать вес' },
          { id: EResult.WEIGHT_UNKNOWN_KEEP, title: 'Да, удалось удерживать вес' },
          { id: EResult.WEIGHT_UNKNOWN_WRONG, title: 'Нет, мне не подошла диета' }
        ];
    }
  }, [propState?.target]);

  const [errorWeight, setErrorWeight] = useState(false);
  const [valueAutocomplete, setValueAutocomplete] = useState(options[0]);
  const [inputValueTextField, setInputValueTextField] = useState({
    weight: '',
    result: null
  });

  useEffect(() => {
    if (!openModalWeekend) {
      setInputValueTextField({
        weight: '',
        result: null
      });
      setValueAutocomplete(options[0]);
    }
  }, [openModalWeekend]);

  useEffect(() => {
    valueAutocomplete && setInputValueTextField(prevState => ({
      ...prevState,
      result: valueAutocomplete?.id
    }));
  }, [valueAutocomplete]);

  const handleClose = () => {
    setOpenModalWeekend(false);
  };

  const handleComplete = async () => {
    if (errorWeight) return;
    const { result, weight } = inputValueTextField;
    await onCompleteClick({
      result,
      weight: weight === '' ? null : Number(weight)
    });
    setOpenModalWeekend(false);
    history.push('/');
  };

  const validateForm = (inputValidateItem, stateValidate, min, max) => {
    if (inputValidateItem < min || inputValidateItem > max) {
      stateValidate(true);
    } else {
      stateValidate(false);
    }

    if (inputValidateItem === '' || inputValidateItem === null) {
      stateValidate(false);
    }
  };

  useEffect(() => {
    validateForm(inputValueTextField.weight, setErrorWeight, 20, 300);
  }, [inputValueTextField.weight]);

  const handleNewWeek = () => {
    if (errorWeight) return;
    const { result, weight } = inputValueTextField;
    onNewWeekClick({
      result,
      weight: weight === '' ? null : Number(weight)
    });
  };

  return (
    <div className={ styles.root }>
      <Modal
        open={ openModalWeekend }
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        mobile={ isMobile }
        renderModal={
          <div className={ styles.paper }>
            <div className={ styles.header }>
              <Header className={ styles.title }>
                { t('diet.weekendProgram.header') }
              </Header>
              <ButtonBase onClick={ handleClose }><IconClose/></ButtonBase>
            </div>
            <div className={ styles.container }>
              <div>{ t('diet.weekendProgram.desc.0') }</div>
              <p className={ styles.resultDesc }>{ t('diet.weekendProgram.desc.1') }</p>
              <form noValidate>
                <CustomAutocomplete
                  defaultValue={ options[0] }
                  options={ options }
                  id="controllable-states"
                  onChange={ setValueAutocomplete }
                  style={ { marginBottom: 27 } }
                />
                { propState?.weight ?
                  <div className={ styles.weightContainer }>
                    <div
                      style={ { marginBottom: errorWeight ? 40 : 0 } }
                      className={ styles.weightDesc }
                      dangerouslySetInnerHTML={ { __html: t('diet.weekendProgram.yourStartingWeight', { weight: propState?.weight }) } }
                    />
                    <CustomTextField
                    type="number"
                    className={ styles.weightInput }
                    label={ t('diet.weekendProgram.weightAfter') }
                    value={ inputValueTextField?.weight }
                    onChange={ e => setInputValueTextField({ ...inputValueTextField, weight: e.target.value }) }
                    variant="filled"
                    id="weight"
                    error={ errorWeight }
                    helperText={ errorWeight && t('modalCalcProgram.checkValidData') }
                    unit={ t('modalCalcProgram.kg') }
                    />
                  </div> : null
                }
                <div className={ styles.buttons }>
                  <Button
                    className={ styles.button }
                    onClick={ handleNewWeek }
                  >
                    { t('diet.weekendProgram.startNewProgram') }
                  </Button>
                  <Button
                    type="outline"
                    className={ styles.button }
                    onClick={ handleComplete }
                  >
                    { t('diet.weekendProgram.goToKnowledge') }
                  </Button>
                </div>
              </form>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ModalWeekendProgram;
