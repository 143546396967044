import React, { FC, useEffect, useContext, useState } from 'react';
import NavbarInfinityArrow from './navbar.infinity.arrow';
import stylesNavbarInfinityArrow from './navbar.infinity.arrow.styles';
import classnames from 'classnames';
import { VisibilityContext } from 'react-horizontal-scrolling-next';
import IconSide from 'app/components/shared/icons/side/side.arrow';

interface IRightArrowProps {
  maxItems: number;
  getMoreItems: () => void;
}

const NavbarInfinityArrowRight: FC<IRightArrowProps> = ({ maxItems = 0, getMoreItems }) => {
  const { getNextItem, scrollToItem, isLastItemVisible, visibleItemsWithoutSeparators, items } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState<boolean>(!visibleItemsWithoutSeparators?.length && isLastItemVisible);
  const classesArrow = stylesNavbarInfinityArrow();
  const classes = classnames(classesArrow.arrow, classesArrow.arrowRight);

  useEffect(() => {
    if (isLastItemVisible) {
      getMoreItems && getMoreItems();
    }
    if (items.toItemsWithoutSeparators()?.length >= maxItems) {
      setDisabled(isLastItemVisible);
    }
  }, [items, maxItems, isLastItemVisible]);

  const clickHandler = () => {
    const nextItem = getNextItem();
    scrollToItem(nextItem?.entry?.target, 'smooth', 'end');
  };

  return (
    <NavbarInfinityArrow className={ classes } disabled={ disabled } onClick={ clickHandler }>
      <IconSide className={ classesArrow.icon }/>
    </NavbarInfinityArrow>
  );
};

NavbarInfinityArrowRight.displayName = 'NavbarInfinityArrowRight';

export default NavbarInfinityArrowRight;
