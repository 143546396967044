import { BASENAME } from 'app/configs/const';

/** @deprecated */
export const loginExternal = (backUrl: string, withoutParams = false) => {
  const url = getUrlLoginExternal(backUrl, withoutParams);
  window.location.href = url.href;
};

/** @deprecated */
export const getUrlLoginExternal = (backUrl: string, withoutParams = false) => {
  const base = location.origin;
  const basename = BASENAME?.length > 0 ? BASENAME : '';
  const pathname = `${basename}/ssoLogin`.replace(/\/{2,}/g, '/');

  const url = new URL(pathname, base);
  url.searchParams.set('backUrl', `${encodeURIComponent(`${backUrl?.replace(/^\//g, '')}${withoutParams ? '' : '?login=success'}`)}`);
  return url;
};

export const logoutExternal = () => {
  const base = location.origin;
  const basename = BASENAME?.length > 0 ? BASENAME : '';
  const pathname = `${basename}/exit`.replace(/\/{2,}/g, '/');

  const url = new URL(pathname, base);
  window.location.href = url.href;
};
