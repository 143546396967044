//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

const computeSmSizeStyles = () => ({
  fontSize: '12px',
  lineHeight: '16px',
  alignItems: 'center',
  '& svg': {
    width: 17,
    height: 17
  },
  '& $count': {
    marginLeft: 5
  }
});

const computeMdSizeStyles = () => ({
  fontSize: '14px',
  lineHeight: '22px',
  '& svg': {
    width: 23,
    height: 23
  }
});

const computeLgSizeStyles = () => ({
  fontSize: 15,
  lineHeight: '25px',
  '& svg': {
    width: 28,
    height: 28,
    marginRight: 5
  }
});

export default makeStyles(theme =>
  createStyles({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: '14px',
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      '& svg': {
        fill: 'transparent',
        stroke: 'currentColor',
        width: 23,
        height: 23
      },
      '&.active svg': {
        fill: 'currentColor'
      }
    },
    icon: {
      color: configTheme.megafonColors.basic.green.default,
      cursor: 'pointer',
      '.invert &': {
        color: configTheme.colors.text.light.default
      }
    },
    count: {
      marginLeft: '0.3em',
      color: configTheme.colors.text.dark.neutral,
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      '.invert &': {
        color: configTheme.colors.text.light.default
      }
    },
    sm: computeSmSizeStyles(),
    md: computeMdSizeStyles(),
    lg: computeLgSizeStyles(),
    disabled: {
      pointerEvents: 'none'
    }
  }),
  { name: 'Likes' }
);
