//#region imports
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import cn from 'classnames';
import flow from 'lodash/flow';
import { Button, Header, Paragraph } from '@megafon/ui-core';

import Wrapper from 'app/components/shared/wrapper/wrapper.index';
import HeadTitle from 'app/components/shared/headtitle/head.title.index';
import CardSubscriptionDesktop from 'app/components/desktop/card/subscription/card.subscription.desktop.index';
import CardSubscriptionMobile from 'app/components/mobile/card/subscription/card.subscription.mobile.index';

import helperStyles from 'app/configs/theme/helper.styles';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';
import { ContainerSubscribe, IContainerSubscribeProps } from 'app/containers/container.subscribe';
import { ModalsContainer, IModalsContainer } from 'app/containers/modals.container';
import { ContainerLogging, TContainerLoggingProps } from 'app/containers/container.logging';
import { IRouteProps } from 'app/models/shared.model';

import CircularLoader from 'app/components/loader/loader.circular';
import Parameters from 'app/pages/shared/diet/parameters/parameters.index';
import useBreakpoints from 'app/hooks/use.breakpoints';

import stylesProfile from './profile.styles';
import { SUBSCRIBE_STATUS } from 'app/configs/const';
//#endregion

export interface IProfileProps extends IRouteProps {
  containerUser: IUserContainerProps;
  containerSubscribe: IContainerSubscribeProps;
  modalsContainer: IModalsContainer;
  containerLogging: TContainerLoggingProps;
}

const Profile: FC<IProfileProps> = ({ containerUser, containerSubscribe, containerLogging, modalsContainer }) => {
  const { actions: { unSubscribeModal } } = modalsContainer;
  const { data: { authenticated }, actions: userActions, request: { status: userStatus } } = containerUser;
  const { actions: subscribeActions, subscriptions } = containerSubscribe;
  const { actions: { logSubscriptionChange } } = containerLogging;

  const { isMobile, isTablet, isNotDesktop } = useBreakpoints();
  const { pathname } = useLocation();

  const { t } = useTranslation();
  const history = useHistory();

  const classesHelper = helperStyles();
  const classes = stylesProfile({ isMobile, isNotDesktop });

  const handleSubscribeCard = (isSubscribe, data) => {
    if (isSubscribe) {
      unSubscribeModal(data.id);
    } else {
      logSubscriptionChange(true, data.id, pathname);
      subscribeActions.setSubscription(true, data.id);
    }
  };

  const loader = (
    <div className={ classesHelper.suspendLoader }>
      <CircularLoader />
    </div>
  );

  const renderParameters = useMemo(() => (
    <Wrapper className={ classes.wrapper }>
    <Parameters
      classes={ {
        wrapper: classes.parametersWrapper,
        table: classes.parametersTable,
        actionButton: classes.parametersBtn,
        title: classes.parametersTitle
      } }
      isNoIcon
      isNoCard
      isNoParametersInfo
    />
    </Wrapper>
  ), []);

  const renderSubscribeDesktopList = useMemo(() => (
    <Grid container>
      {
        subscriptions.map(({ name, price, description, status, id }) => (
          <Grid key={ id } item className={ classes.subscriptionContainer }>
            <CardSubscriptionDesktop
              className={ classes.subscriptionDesktop }
              toggleSubscribe={ () => handleSubscribeCard(status !== SUBSCRIBE_STATUS.NOT_ACTIVE, { id, name }) }
              title={ name }
              price={ price }
              desc={ description }
              isSubscribe={ status !== SUBSCRIBE_STATUS.NOT_ACTIVE }
            />
          </Grid>
        ))
      }
    </Grid>
  ), [subscriptions, isTablet]);

  const renderSubscribeMobileList = useMemo(() => (
    <>
      {
        subscriptions.map(({ name, price, description, status, id }) => (
          <CardSubscriptionMobile
            className={ classes.subscriptionMobile }
            key={ id }
            toggleSubscribe={ () => handleSubscribeCard(status !== SUBSCRIBE_STATUS.NOT_ACTIVE, { id, name }) }
            title={ name }
            price={ price }
            desc={ description }
            isSubscribe={ status !== SUBSCRIBE_STATUS.NOT_ACTIVE }
          />
        ))
      }
    </>
  ), [subscriptions]);

  const content = (
    <>
    <Wrapper>
      <section className={ classes.primarySection }>
        <Paragraph className={ classes.header }>
          { t('me.myData') }
        </Paragraph>
        <div className={ classes.data }>
          <span>{ t('me.number') }:&nbsp;</span>
          <b>{ containerUser?.data?.msisdn }</b>
        </div>
      </section>
    </Wrapper>
      { renderParameters }
    <Wrapper className={ classes.wrapper }>
      <section className={ `${classes.subscriptions}` }>
        <Paragraph className={ classes.header }>
          { t('me.subscriptions') }
        </Paragraph>
        { isMobile ? renderSubscribeMobileList : renderSubscribeDesktopList }
      </section>
    </Wrapper>
    </>
  );

  const handleHeadTitle = () => {
    history.push('/');
  };

  return (
    <div className={ classes.root }>
      <HeadTitle callback={ handleHeadTitle }>
        <Header as={ 'h3' }>{ t('common.profile') }</Header>
      </HeadTitle>
      { authenticated ? content : loader }
          <div className={ classes.buttonContainer }>
            <Button
              type="outline"
              className={ cn(classes.exitButton) }
              onClick={ userActions.toggleModalLogout }
              fullWidth={ isMobile }
            >
              Выйти
            </Button>
          </div>
    </div>
  );
};

Profile.displayName = 'Profile';

export default flow([ContainerLogging, ContainerUser, ContainerSubscribe, ModalsContainer])(Profile);
