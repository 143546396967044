import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@megafon/ui-core';

import IconBurger from 'app/components/shared/icons/burger/icon.burger';
import stylesMobileToolbar from './mobile.toolbar.styles';
import { IMenuMobileProps } from '../../../mobile/menu/menu.mobile';
import useBreakpoints from 'app/hooks/use.breakpoints';

type TPropsMobileToolbar = Pick<IMenuMobileProps, 'toggleMenuCallback' | 'logo' | 'toolbar' | 'logoRedirectUrl'>;

const MobileToolbar: FC<TPropsMobileToolbar> = ({ logo, toolbar, toggleMenuCallback, logoRedirectUrl }) => {
  const { isMobile } = useBreakpoints();
  const classes = stylesMobileToolbar({ isMobile });

  return (
    <div className={ classes.wrap }>
      { logoRedirectUrl
          ?
          <Link className={ classes.logoLinkWrap } to={ logoRedirectUrl } >
            { logo }
          </Link>
          : logo
      }
      <div className={ classes.listWrap }>
        <div className={ classes.toolbar }>
          { toolbar && toolbar }
        </div>
        <Button
          icon={ <IconBurger width={ isMobile ? 25 : 29 } height={ isMobile ? 28 : 32 }/> }
          onClick={ () => { toggleMenuCallback(); } }
          className={ classes.icon }
        />
      </div>
    </div>
  );
};

MobileToolbar.displayName = 'MobileToolbar';

export default MobileToolbar;
