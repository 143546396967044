import React from 'react';
import ModalLogin from 'app/modals/login/modal.login';
import ModalLogout from 'app/modals/logout/modal.logout';
import ModalSubscribe from 'app/modals/subscribe/modal.subscribe';
import ModalNotifications from 'app/pages/shared/notification/modal.notifications';

const Modals = () => (
  <>
    <ModalLogin />
    <ModalLogout />
    <ModalSubscribe />
    <ModalNotifications />
  </>
);

Modals.displayName = 'Modals';

export default Modals;
