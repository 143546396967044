import { Theme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { configTheme } from 'app/configs/theme/config.theme';

export default makeStyles<Theme, { isMobile?: boolean }>(theme =>
  createStyles({
  wrap: {
    position: 'relative',
    zIndex: configTheme.layers.modal,
    height: 58,
    background: configTheme.megafonColors.basic.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 18px',
    boxShadow: '0px 7px 6px rgba(0, 0, 0, 0.08)'
  },
  listWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    marginLeft: 'auto'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
    cursor: 'pointer'
  },
  burger: {
    width: 24,
    height: 24,
    color: configTheme.megafonColors.basic.white
  },
  icon: {
    width: '30px',
    height: '30px',
    [theme.breakpoints.up(configTheme.breakpoints.values.sm)]: {
      width: '38px',
      height: '38px',
      marginLeft: 20
    },
    '& .mfui-button__inner': {
      height: 'auto',
      minWidth: 'auto'
    },
    '.mfui-button__icon': {
      width: 28,
      height: 28
    }
  },
  logoLinkWrap: {
    textDecoration: 'none'
  }
}));
