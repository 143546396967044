import { fontFamily } from './config.font.family';
import { colors, colorsNew, iconColors, megafonColors } from './config.colors';
import { breakpoints } from './config.breakpoints';
import { layers } from './config.layers';
import { indents, indentsDesktop } from './config.indents';

export const configTheme = {
  colors: {
    ...colors,
    icon: iconColors
  },
  megafonColors: {
    ...megafonColors
  },
  colorsNew: {
    ...colorsNew
  },
  breakpoints,
  layers,
  fontFamily,
  indents,
  indentsDesktop
};
