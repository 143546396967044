//#region imports
import React, { useEffect, useContext, useState } from 'react';
import NavbarInfinityArrow from './navbar.infinity.arrow';
import stylesNavbarInfinityArrow from './navbar.infinity.arrow.styles';
import classnames from 'classnames';
import { VisibilityContext } from 'react-horizontal-scrolling-next';
import IconSide from 'app/components/shared/icons/side/side.arrow';
//#endregion

const NavbarInfinityArrowLeft = () => {
  const { getPrevItem, isFirstItemVisible, scrollToItem, visibleItemsWithoutSeparators, initComplete } = useContext(VisibilityContext);

  const [disabled, setDisabled] = useState<boolean>(!initComplete || (initComplete && isFirstItemVisible));
  const classesArrow = stylesNavbarInfinityArrow();
  const classes = classnames(classesArrow.arrow, classesArrow.arrowLeft);

  useEffect(() => {
    if (visibleItemsWithoutSeparators?.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  const clickHandler = () => {
    const prevItem = getPrevItem();
    scrollToItem(prevItem?.entry?.target, 'smooth', 'start');
  };

  return (
    <NavbarInfinityArrow className={ classes } disabled={ disabled } onClick={ clickHandler } >
      <IconSide className={ classesArrow.icon }/>
    </NavbarInfinityArrow>
  );
};

NavbarInfinityArrowLeft.displayName = 'NavbarInfinityArrowLeft';

export default NavbarInfinityArrowLeft;
