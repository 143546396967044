import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';
import { SECTION_ACTIONS } from 'app/entities/section/entity.section.actions';

import { ISectionItem } from 'app/models/model.section';
import { ELoadStatus } from 'app/models/shared.model';
import { ISubcategory } from 'app/models/model.subcategory';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

export interface ISectionListInitialState {
  items?: ISectionItem[];
  targetId?: ISubcategory['id'];
  status: ELoadStatus;
  error: Error;
  more: boolean;
}

const initialParams: ISectionListInitialState = {
  items: [],
  targetId: null,
  status: ELoadStatus.not_ready,
  error: null,
  more: false
};

const initialState = {
  list: [] as ISectionListInitialState[]
};

export type ISectionListState = Readonly<typeof initialState>;

export default (state = initialState, action): ISectionListState => {
  const { type, payload, meta } = action;
  switch (type) {
    case REQUEST(SECTION_ACTIONS.FETCH_LIST):
      const dataRequest = state.list && state.list.length
        ?
          [
              ...state.list.filter(li => li.targetId !== meta.props.targetId),
            {
              targetId: meta.props.targetId,
              items: [],
              status: ELoadStatus.loading,
              error: initialParams.error,
              more:  initialParams.more
            }
          ]
        :
          [
            {
              status: ELoadStatus.loading,
              error: initialParams.error,
              targetId: meta.props.targetId,
              items: [],
              more: initialParams.more
            }
          ];
      return { list: dataRequest };
    case SUCCESS(SECTION_ACTIONS.FETCH_LIST):
      const dataSuccess = state.list && state.list.length
        ?
          [
            ...state.list.filter(li => li.targetId !== meta.props.targetId),
            {
              targetId: meta.props.targetId,
              status: ELoadStatus.ready,
              error: initialParams.error,
              items: payload.data.sections,
              more: payload.data.more
            }
          ]
        :
          [
            {
              status: ELoadStatus.ready,
              error: initialParams.error,
              targetId: meta.props.targetId,
              items: payload.data.sections,
              more: payload.data.more
            }
          ];
      return {
        list: dataSuccess
      };
    case FAILURE(SECTION_ACTIONS.FETCH_LIST):
      const dataFailure = state.list && state.list.length
        ?
          [
            ...state.list.filter(li => li.targetId !== meta.props.targetId),
            {
              targetId: meta.props.targetId,
              status: ELoadStatus.error,
              error: new Error('section list fetch failed'),
              items: [],
              more: initialParams.more
            }
          ]
        :
          [
            {
              status: ELoadStatus.error,
              error: new Error('section list fetch failed'),
              targetId: meta.props.targetId,
              items: [],
              more: initialParams.more
            }
          ];
      return {
        list: dataFailure
      };
    case SECTION_ACTIONS.FETCH_ALL_LIST:
      const dataListSuccess = [
          {
            status: ELoadStatus.ready,
            error: initialParams.error,
            items: payload,
            more: initialParams.more
          }
        ];
      return {
        list: dataListSuccess
      };
    case SECTION_ACTIONS.RESET_ITEMS:
      return {
        list: []
      };
    default:
      return state;
  }
};
