//#region imports
import React, { FC, forwardRef, useRef } from 'react';
import { useForkRef } from '@material-ui/core';
import classnames from 'classnames';

import { PostcardBadgeProps, EPostcardBadgeClassKey, EPostcardBadgeType, EPostcardBadgeSize } from './postcard.models';
import { usePostcardBadgeStyles } from './postcard.styles';
//#endregion

export const PostcardBadge: FC<PostcardBadgeProps> = forwardRef<HTMLDivElement, PostcardBadgeProps>((props, ref) => {
  const { className, style, classes, text, offset, type, size } = props;
  const styles = usePostcardBadgeStyles({ classes, offset });

  const rootRef = useRef(null);
  const handleRef = useForkRef(rootRef, ref);

  return (
    <div
      ref={ handleRef }
      className={ classnames(className, styles[EPostcardBadgeClassKey.root], styles[EPostcardBadgeClassKey.offset], {
        [styles[EPostcardBadgeClassKey.colorPrimary]]: type === EPostcardBadgeType.primary,
        [styles[EPostcardBadgeClassKey.colorSecondary]]: type === EPostcardBadgeType.secondary,
        [styles[EPostcardBadgeClassKey.sizeSmall]]: size === EPostcardBadgeSize.small,
        [styles[EPostcardBadgeClassKey.sizeLarge]]: size === EPostcardBadgeSize.large
      }) }
      style={ style }
    >
      { text }
    </div>
  );
});

PostcardBadge.defaultProps = { type: EPostcardBadgeType.primary, size: EPostcardBadgeSize.small };
