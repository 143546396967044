//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '@megafon/ui-core';
import classnames from 'classnames';
import isFunction from 'lodash/isFunction';

import {
  TCardVerticalProps
} from '../card.vertical.models';
import stylesCardVerticalLeft from './card.vertical.left.styles';
import Likes from '../../../likes/likes.index';
import ButtonPlay from 'app/components/shared/button/play/button.play.index';
import Badge from '../../../badge/badge.index';
import { placeholder, cloneEl } from 'app/utils/utils.accessories';
//#endregion

const CardVerticalLeft: FC<TCardVerticalProps> = props => {
  const {
    className,
    size,
    title,
    outlined,
    src,
    button = {},
    badge = {},
    likes = {},
    overlay,
    onClick,
    isTransparent
  } = props;

  const { visible: btnVisible = true } = button;
  const { visible: likesVisible = true, ...likesProps } = likes;
  const { visible: badgeVisible = true, ...badgeProps } = badge;

  const { t } = useTranslation();
  const styles = stylesCardVerticalLeft({ outlined, isTransparent });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (isFunction(onClick)) onClick(e);
  };

  return (
    <div className={ classnames(className, styles.root, {
      [styles[size]]: size
    }) }
      onClick={ handleClick }
    >
      <div className={ styles.picture }>
        <img
          src={ src ?? placeholder }
          alt={ title ?? t('components.shared.card.imgAltDefault') }
        />
        { overlay && <div className={ styles.overlay }>{ cloneEl(overlay, size) }</div> }
        <div className={ styles.overlay }>{ btnVisible && <ButtonPlay size={ size }/> }</div>
      </div>
      <Header as={ 'h5' } className={ styles.title }>
        { title ?? t('components.shared.card.titleDefault') }
        { (badgeVisible || likesVisible) && <div className={ styles.footer }>
            <span className={ styles.badge }>{ badgeVisible && <Badge size={ size } { ...badgeProps }/> }</span>
            <span className={ styles.likes }>{ likesVisible && <Likes size={ size } { ...likesProps } /> }</span>
        </div> }
      </Header>
    </div>
  );
};

CardVerticalLeft.displayName = 'CardVerticalLeft';

export default CardVerticalLeft;
