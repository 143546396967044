import { IUserActions } from 'app/entities/user/user.actions';
import { IRequest } from 'app/models/model.request';
import { IModal } from 'app/models/types';

export enum EPostConfirmStatus {
  EXHAUSTED = 'EXHAUSTED',
  OK = 'OK',
  WRONG = 'WRONG'
}

export interface IConfirmBody {
  msisdn: string;
  smsCode: string;
}

export interface IUserInitialState extends IRequest<keyof IUserActions> {
  data: {
    msisdn?: string;
    token: string;
    authenticated?: boolean;
  };
  meta: {
    status: keyof typeof EPostConfirmStatus;
    modal: IModal;
  };
}
