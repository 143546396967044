//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

const computeXsSizeStyles = () => ({
  width: 150,
  height: 220,
  '& img': {
    width: 85,
    height: 85
  },
  '& h5$title': {
    fontSize: '14px',
    width: 125
  }
});

const computeSmSizeStyles = () => ({
  width: 160,
  height: 240,
  '& img': {
    width: 94,
    height: 94
  },
  '& h5$title': {
    fontSize: '16px',
    width: 135
  }
});

const computeMdSizeStyles = () => ({
  width: 214,
  height: 310,
  '& img': {
    width: 131,
    height: 131
  },
  '& h5$title': {
    fontSize: '19px',
    width: 184
  }
});

const computeLgSizeStyles = () => ({
  width: 244,
  height: 352,
  '& img': {
    width: 150,
    height: 150
  },
  '& h5$title': {
    fontSize: '19px',
    width: 214
  }
});

const computeXlSizeStyles = () => ({
  width: 264,
  height: 362,
  '& img': {
    width: 160,
    height: 160
  },
  '& h5$title': {
    fontSize: '24px',
    width: 224
  }
});

export default makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 'fit-content',
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      backgroundColor: configTheme.colors.basic.secondary,
      border: `1px solid ${ configTheme.colors.border.primary.light }`,
      borderRadius: 5,
      cursor: 'pointer'
    },
    billet: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      backgroundColor: configTheme.colors.basic.primary,
      border: `1px solid ${ configTheme.colors.basic.primary }`,
      borderRadius: 5,
      '& h5$title': {
        color: configTheme.colors.basic.secondary
      }
    },
    picture: {
      position: 'relative',
      margin: '10px auto 0.1em auto',
      '& img': {
        objectFit: 'cover',
        borderRadius: 10
      }
    },
    title: {
      margin: '0.4em auto',
      padding: 0,
      overflow: 'hidden',
      whiteSpace: 'normal',
      textAlign: 'center',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical'
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    button:  {
      margin: '0 0.8em'
    },
    footer:  {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0.7em 0.8em'
    },
    badge:  {},
    likes:  {},
    xxs: computeXsSizeStyles(),
    xs: computeXsSizeStyles(),
    sm: computeSmSizeStyles(),
    md: computeMdSizeStyles(),
    lg: computeLgSizeStyles(),
    xl: computeXlSizeStyles()
  }),
  { name: 'CardVerticalCentral' }
);
