import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

interface IProps {
  isMobile?: boolean;
  isNotDesktop?: boolean;
}

export const stylesIndividualProgram = makeStyles<{}, IProps>((theme: Theme) =>
  createStyles({
    container: {
      padding: ({ isMobile }) => isMobile ? '35px 0' : '40px 0',
      minHeight: ({ isMobile, isNotDesktop }) =>
        isMobile ? 'calc(100vh - 307px)' : isNotDesktop ? 'calc(100vh - 317px)' : 'calc(100vh - 324px)'
    },
    parameters: {
      width: ({ isNotDesktop }) => isNotDesktop ? 'unset' : 653,
      margin: ({ isNotDesktop }) => isNotDesktop ? '25px auto 30px auto' : '40px auto 50px auto',
      color: configTheme.megafonColors.basic.black.normal,
      padding: ({ isMobile }) => isMobile ? 0 : 36,
      backgroundColor: configTheme.megafonColors.basic.white,
      border: `1px solid ${ configTheme.megafonColors.border.grey }`,
      borderRadius: 10
    },
    descBlock: {
      width: '100%',
      padding: ({ isMobile }) => isMobile ? '20px 15px' : '36px 40px',
      border: `1px solid ${configTheme.megafonColors.border.grey}`,
      borderRadius: 10,
      boxSizing: 'border-box',
      boxShadow: '0px 5px 4px rgba(0, 0, 0, 0.05)',
      backgroundColor: configTheme.megafonColors.basic.white,
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 16 : 19,
      lineHeight: ({ isNotDesktop }) => isNotDesktop ? '20px' : '24px',
      color: configTheme.megafonColors.basic.black.normal
    }

  })
);
