import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconPaid: FC <SvgIconProps> = props => (
  <SvgIcon width="22" height="23" viewBox={ '0 0 22 23' } { ...props }>
    <path d="M0 19H22M0 22H22M3.66667 6.25V4H5.86667M16.1333 4H18.3333V6.25M3.66667 10.75V13H5.86667M16.1333
      13H18.3333V10.75M11 11.5C9.37997 11.5 8.06667 10.1569 8.06667 8.5C8.06667 6.84315 9.37997 5.5 11 5.5C12.62 5.5
      13.9333 6.84315 13.9333 8.5C13.9333 10.1569 12.62 11.5 11 11.5ZM2.2 1H19.8C20.61 1 21.2667 1.67157 21.2667
      2.5V14.5C21.2667 15.3284 20.61 16 19.8 16H2.2C1.38998 16 0.733333 15.3284 0.733333 14.5V2.5C0.733333 1.67157 1.38998 1 2.2 1Z"
    />
  </SvgIcon>
);

export default IconPaid;
