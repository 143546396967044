//#region imports
import React, { FC, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import flow from 'lodash/fp/flow';
import { Header } from '@megafon/ui-core';

import { CONTENT_ITEM } from 'app/configs/route.names';

import { ELoadStatus } from 'app/models/shared.model';
import { EContentType } from 'app/models/model.content';
import { IRecommendsProps } from '../item.models';

import { ContainerContentItem } from 'app/containers/container.content.item';
import { ContainerContentList } from 'app/containers/container.content.list';
import { ModalsContainer } from 'app/containers/modals.container';
import { ContainerUser } from 'app/containers/container.user';

import ListHorizontal from 'app/components/shared/list/horizontal/list.horizontal.index';

import useLimiter from 'app/hooks/useLimiter';
import subscribeCheck from 'app/utils/util.subscribe.check';
import useBreakpoints from 'app/hooks/use.breakpoints';
import { stylesContentListPage } from 'app/pages/desktop/content/list/page.content.list.styles';
import Wrapper from 'app/components/wrapper/wrapper.index';
//#endregion

const Recommends: FC<IRecommendsProps> = memo(props => {
  const { containerContentItem, containerContentList, modalsContainer, containerUser, subscribe, contentId } = props;
  const { isNotDesktop, isMobile } = useBreakpoints();
  const styles = stylesContentListPage({ isNotDesktop, isMobile });
  const { t } = useTranslation();
  const history = useHistory();

  const sectionId = containerContentItem.section?.section?.id;
  const contentType = containerContentItem.item?.type;

  const contents = containerContentList.items;
  const loading = containerContentList.status.items === ELoadStatus.loading;
  const more = containerContentList.more;

  const [paging, pagingEmiter] = useLimiter();
  useEffect(() => {
    if (sectionId) {
      containerContentList.actions.fetchContentList({
        id: sectionId,
        pager: paging.current(),
        cancellable: true
      });
    }
    return () => {
      pagingEmiter.invokeReset();
      containerContentList.actions.resetContentList();
    };
  }, [sectionId]);

  const handleClick = (type, id, isFree) => {
    if (isFree) {
      history.push(CONTENT_ITEM(type, id));
    } else {
      subscribeCheck({
        subscribe,
        callback: () => history.push(CONTENT_ITEM(type, id)),
        history,
        containerUser,
        containerModals: modalsContainer
      });
    }
  };

  const recommendsList = useMemo(() => contents?.filter(
    el => el.id !== contentId).map(({ name, id, type, free, imageUrl }) => ({
    key: id,
    title: name,
    src: imageUrl,
    onClick: e => {
      handleClick(type, id, free);
    },
    badge: {
      visible: false
    },
    likes: {
      visible: false
    },
    button: {
      visible: false
    }
  })), [containerContentList, isMobile, contentId]);

  switch (contentType) {
    case EContentType.ARTICLE:
      return (
        <Wrapper className={ styles.recommendPack }>
          <Header className={ styles.recommendsTitle }>
            { t('common.recommend') }
          </Header>
          { recommendsList?.length ?
            <ListHorizontal
              size={ isMobile ? (isNotDesktop ? 'xs' : 'xxs') : 'sm' }
              cardAlign={ 'left' }
              cardsPadding={ isMobile ? (isNotDesktop ? 20 : 12) : 0 }
              listItems={ recommendsList }
              isTransparent={ !isMobile }
            /> : null
          }
        </Wrapper>
      );
    default:
      return null;
  }
});

export default flow([ContainerContentItem, ContainerContentList, ModalsContainer, ContainerUser ])(Recommends);
