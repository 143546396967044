import { SvgIconProps } from '@material-ui/core/SvgIcon';

export interface IIconProps extends SvgIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

export const EIconAlign = {
  left: 'left',
  right: 'right'
} as const;

export type IconAlign = typeof EIconAlign[keyof typeof EIconAlign];

export const EIconSize = {
  default: 'default',
  small: 'small'
} as const;

export type IconSize = typeof EIconSize[keyof typeof EIconSize];

export const EIconColor = {
  inherit: 'inherit',
  primary: 'primary',
  secondary: 'secondary',
  default: 'default',
  success: 'success',
  error: 'error',
  white: 'white',
  disabled: 'disabled'
} as const;

export type IconColor = typeof EIconColor[keyof typeof EIconColor];

export const EIconColorClassKey = {
  colorDefault: 'colorDefault',
  colorPrimary: 'colorPrimary',
  colorSecondary: 'colorSecondary',
  colorSuccess: 'colorSuccess',
  colorError: 'colorError',
  colorWhite: 'colorWhite',
  colorDisabled: 'colorDisabled'
} as const;

export type IconColorClasses = typeof EIconColorClassKey[keyof typeof EIconColorClassKey];

export const EIconSizeClassKey = {
  sizeDefault: 'sizeDefault',
  sizeSmall: 'sizeSmall'
} as const;

export type IconSizeClasses = typeof EIconSizeClassKey[keyof typeof EIconSizeClassKey];
