//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import classnames from 'classnames';
import isFunction from 'lodash/isFunction';

import {
  TCardVerticalProps
} from '../card.vertical.models';
import stylesCardVerticalPicture from './card.vertical.picture.styles';
import Likes from '../../../likes/likes.index';
import ButtonPlay from '../../../../shared/button/play/button.play.index';
import Badge from '../../../badge/badge.index';
import { placeholder, cloneEl } from '../../../../../utils/utils.accessories';
//#endregion

const CardVerticalPicture: FC<TCardVerticalProps> = props => {
  const {
    size,
    src,
    title
  } = props;

  const { t } = useTranslation();
  const styles = stylesCardVerticalPicture({ size });

  return (
    <div className={ styles.root }>
      <div className={ styles.picture }>
        <img
          style={ { height: size === 'xs' ? 331 : 541 } }
          src={ src ?? placeholder }
          alt={ title ?? t('components.shared.card.imgAltDefault') }
        />
      </div>
    </div>
  );
};

CardVerticalPicture.displayName = 'CardVerticalPicture';

export default CardVerticalPicture;
