//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
import { EContentType } from 'app/models/model.content';

import { OFormat } from './item.card.models';
//#endregion

interface IProps {
  isNotDesktop?: boolean;
  isMobile?: boolean;
}

export const stylesItemCard = makeStyles(theme =>
  createStyles<any, IProps>({
    root: {
      [`&.${EContentType.GAME_ANDROID}`]: { padding: configTheme.indents['indentX1'].margin },
      [`&.${EContentType.GAME_HTML5}`]: { padding: configTheme.indents['indentX1'].margin },
      [`&.${EContentType.BOOK}.${OFormat.Fit}`]: {
        padding: configTheme.indents['indentX2'].margin + configTheme.indents['indentX1'].margin * 0.5,
        paddingRight: configTheme.indents['indentX2'].margin
      },
      [`&.${EContentType.BOOK}.${OFormat.Compact}`]: { padding: configTheme.indents['indentX1'].margin },
      [`&.${EContentType.AUDIO}`]: { padding: configTheme.indents['indentX1'].margin },
      [`&.${EContentType.ARTICLE}`]: {
        margin: ({ isNotDesktop }) => isNotDesktop ? '18px auto 0 auto' : '30px auto 0 auto',
        border: `1px solid ${ configTheme.colorsNew.border.default }`,
        borderRadius: 5
      }
    },

    picture: {
      [`$root.${EContentType.BOOK} & img`]: { cursor: 'pointer' },
      [`$root.${EContentType.ARTICLE} &`]: {
        flexBasis: '50%',
        ['@media(min-width: 1024px) and (max-width: 1140px)']: {
          flexBasis: '40%'
        }
      },
      [`$root.${EContentType.ARTICLE} & img`]: { cursor: 'pointer', borderRadius: 4 },
      [`$root.${EContentType.VIDEO} &`]: { cursor: 'pointer' },
      [`$root.${EContentType.IMAGE} &`]: { cursor: 'pointer' },
      [`$root.${EContentType.STUDY} & img`]: { cursor: 'pointer' },
      [`$root.${EContentType.OLYMPIAD} &`]: { cursor: 'pointer' },
      [`$root.${EContentType.GAME_ANDROID} & img`]: { cursor: 'pointer', borderRadius: 10 },
      [`$root.${EContentType.GAME_HTML5} & img`]: { cursor: 'pointer', borderRadius: 10 },
      [`$root.${EContentType.AUDIO} & img`]: { borderRadius: 10 }
    },

    descr: {
      display: 'flex',
      flexFlow: 'column nowrap',
      minWidth: 0,
      maxWidth: '99.98%',

      [`$root.${EContentType.GAME_ANDROID} &`]: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${configTheme.indents['indentX2'].margin}px 0 ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.GAME_HTML5} &`]: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${configTheme.indents['indentX2'].margin}px 0 ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.BOOK}.${OFormat.Fit} &`]: {
        alignItems: 'flex-start',
        margin: 0,
        marginLeft: configTheme.indents['indentX2'].margin,
        padding: 0,
        '& > button:last-child': { marginTop: 'auto' }
      },
      [`$root.${EContentType.BOOK}.${OFormat.Compact} &`]: {
        padding: `${configTheme.indents['indentX1'].margin}px 0 ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.AUDIO} &`]: {
        padding: `0 ${configTheme.indents['indentX1'].margin * 0.5}px 0 ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.VIDEO} &`]: {
        padding: ({ isNotDesktop }) => isNotDesktop ? '12px 15px' : '16px 18px'
      },
      [`$root.${EContentType.IMAGE} &`]: {
        justifyContent: 'center',
        padding: `${configTheme.indents['indentX1'].margin}px ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.STUDY} &`]: {
        padding: `${configTheme.indents['indentX1'].margin - 2}px ${configTheme.indents['indentX1'].margin}px`,
        justifyContent: 'center'
      },
      [`$root.${EContentType.OLYMPIAD} &`]: {
        justifyContent: 'center',
        padding: `${configTheme.indents['indentX1'].margin}px ${configTheme.indents['indentX1'].margin}px`
      },
      [`$root.${EContentType.ARTICLE} &`]: {
        padding: ({ isNotDesktop }) => isNotDesktop ? '0 0 0 15px' : '0 0 0 30px',
        [theme.breakpoints.down(400)]: {
          paddingLeft: 10
        }
      }
    },

    playIcon: {
      width: ({ isNotDesktop }) => isNotDesktop ? 59 : 80,
      height: ({ isNotDesktop }) => isNotDesktop ? 59 : 80
    },

    title: {
      minWidth: 0,
      maxWidth: '99.98%',
      color: configTheme.megafonColors.basic.black.normal,
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 16 : 24,
      lineHeight: ({ isNotDesktop }) => isNotDesktop ? '19px' : '29px',
      [`$root.${EContentType.BOOK}.${OFormat.Fit} &`]: { fontWeight: 700 }
      // [`$root.${EContentType.BOOK} &`]: { minHeight: 'calc(2em * 1.273)' },
    },

    subtitle: {
      minWidth: 0,
      maxWidth: '99.98%',
      paddingTop: configTheme.indents['indentX1'].margin * 0.5,
      fontSize: 16,
      lineHeight: 1.375,
      color: configTheme.colors.text.light.neutral,

      [`$root.${EContentType.BOOK}.${OFormat.Fit} &`]: { paddingTop: configTheme.indents['indentX1'].margin }
    },

    text: {
      paddingTop: ({ isNotDesktop }) => isNotDesktop ? 10 : 20,
      fontSize: ({ isNotDesktop }) => isNotDesktop ? 12 : 19,
      lineHeight: ({ isNotDesktop }) => isNotDesktop ? '14px' : '28px',
      color: configTheme.megafonColors.basic.black.normal,
      [`$root.${EContentType.BOOK}.${OFormat.Fit} &`]: { paddingTop: configTheme.indents['indentX1'].margin }
    },

    media: { paddingTop: configTheme.indents['indentX1'].margin * 0.5 },

    mediaPlayIcon: {
      // cursor: 'pointer',
      width: 72,
      height: 80,
      paddingLeft: 8,
      borderRadius: '50%',
      fontSize: 36,
      lineHeight: 2.727,
      textAlign: 'center',
      color: configTheme.megafonColors.basic.black.normal,
      background: configTheme.colors.background.light.neutral
    },

    pictureFigure: {},

    inner: {
      [`$root.${EContentType.ARTICLE} &`]: { border: 'none' }
    }
  }),
  { name: 'ItemCard' }
);
