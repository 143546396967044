import React, { FC, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import stylesMenuNavbar from './menu.navbar.styles';
import { IMenuItem } from 'app/models/model.menu';
import classNames from 'classnames';

export interface INavbarProps {
  items: IMenuItem[];
  logo: ReactNode;
  logoRedirectUrl?: string;
}

const NavbarMenu: FC<INavbarProps> = ({ items, logo, logoRedirectUrl }) => {
  const classes = stylesMenuNavbar({ widthImg: 80 });

  const renderItem = ({ name, link, isExternal, isAnchor, isBlank, isActive }: IMenuItem) => {
    if (isAnchor) return <a className={ classes.navLink } href={ link }>{ name }</a>;

    if (isExternal) return <a className={ classes.navLink } href={ link } target={ isBlank && '_blank' }>{ name }</a>;

    return <Link to={ link } className={ classNames(classes.navLink, { 'exact': isActive }) }>{ name }</Link>;
  };

  return (
    <div className={ classes.navbar }>
      { logoRedirectUrl
          ?
          <Link className={ classes.logoLinkWrap } to={ logoRedirectUrl } >
            { logo }
          </Link>
          : logo
      }
      <ul className={ classes.navList }>
        { Array.isArray(items) && items.length && items.map((item, index) => (
          <li className={ classes.navItem } key={ `${index}-${item.name}` }>
            { renderItem(item) }
          </li>
          ))
        }
      </ul>
    </div>
  );
};

NavbarMenu.displayName = 'NavbarMenuDesktop';

NavbarMenu.defaultProps = {
  items: []
} as INavbarProps;

export default NavbarMenu;
