//#region imports
import React, { FC, memo, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Header } from '@megafon/ui-core';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import { useLocation } from 'react-router-dom';
import flow from 'lodash/fp/flow';

import helperStyles from 'app/configs/theme/helper.styles';
import { useValidateSubscribeCallback } from 'app/utils/util.subscribe';
import stylesEllipsis from 'app/hooks/useEllipsisStyles';

import { IContentItemProps } from 'app/models/model.content';
import { ESubscriptionStatus } from 'app/models/model.subscription';

import Wrapper from 'app/components/wrapper/wrapper.index';
import ViewBox from 'app/components/view.box/view.box.index';
import CircularLoader from 'app/components/loader/loader.circular';

import Likes from 'app/components/shared/likes/likes.index';
import Badge from 'app/components/shared/badge/badge.index';
import IconFree from 'app/components/shared/icons/free/icon.free';
import IconAvailable from 'app/components/shared/icons/available/icon.available';
import IconPaid from 'app/components/shared/icons/paid/icon.paid';
import HeadTitle from 'app/components/shared/headtitle/head.title.index';

import { stylesDesktopContentItemPage } from '../page.content.item.styles';
import Recommends from './content.item.article.recommends';

import useBreakpoints from 'app/hooks/use.breakpoints';
import { ELoadStatus } from 'app/models/shared.model';
import { ContainerLogging } from 'app/containers/container.logging';
//#endregion

export interface IContentArticleProps extends IContentItemProps {
  onArticleLinkClick: (props: any) => void;
}

const ContentItemArticle: FC<IContentArticleProps> = memo<IContentArticleProps>(props => {
  const {
    containerUser,
    containerContentList,
    containerLike,
    content: contentItem,
    contentType,
    categoryId,
    contentId,
    sectionId,
    subsectionId,
    subscribe,
    history,
    loading,
    ready,
    onArticleLinkClick,
    prevRoute,
    containerLogging
  } = props;
  const isUserAuthenticated = containerUser?.data?.token && containerUser?.data?.authenticated;
  const { actions: { logView, logPosition } } = containerLogging;

  const [isLikesLoading, setIsLikesLoading] = useState<boolean>(false);

  const { pathname } = useLocation();
  const { t } = useTranslation();
  const classesHelper = helperStyles();
  const ellipsisStyles = stylesEllipsis({});
  const { isNotDesktop, isMobile } = useBreakpoints();
  const styles = stylesDesktopContentItemPage({ isNotDesktop, isMobile });

  const accessible = containerUser?.data?.authenticated;
  const validateSubscribe = useValidateSubscribeCallback({ user: containerUser, subscribe });

  useEffect(() => {
    if (isUserAuthenticated && categoryId) {
      const newData = {
        position: pathname,
        categoryId,
        subcategoryId: subsectionId,
        sectionId,
        contentId,
        subscribeId: subscribe?.id
      };
      logPosition({ ...newData });
      logView({ ...newData });
    }
  }, [categoryId]);

  useEffect(() => {
    if (ready) {
      if (!contentItem?.free && subscribe.status !== ESubscriptionStatus.ACTIVE) {
        history.replace('/');
      }
    }
  }, [ready]);

  useEffect(() => {
    if (contentId) {
      containerLike.actions.fetchLikeList([contentId]);
    }
    return () => {
      containerLike.actions.resetLikeItems();
    };
  }, [contentId]);

  useEffect(() => {
    if (sectionId && ready) {
      containerContentList.actions.fetchContentList({ id: sectionId, pager: { limit: 10, offset: 0 }, cancellable: true });
    }
    return () => {
      containerContentList.actions.resetContentList();
    };
  }, [sectionId, ready]);

  const heart = useMemo(() => {
    return find(containerLike.list, h => h.contentId === contentId);
  }, [contentId, containerLike.list]);

  const handleClickLike = (id, liked) => {
    if (isUserAuthenticated) {
      if (containerContentList.status?.likeStatus === ELoadStatus.ready && !isLikesLoading) {
        try {
          setIsLikesLoading(true);
          if (liked) {
            containerLike.actions.removeLikeItem(id);
          } else {
            containerLike.actions.createLikeItem(id);
          }
        } finally {
          if (containerContentList.items.length) {
            setTimeout(() => {
              containerLike.actions.fetchLikeList([contentId]);
              setIsLikesLoading(false);
            }, 500);
          }
        }
      }
    } else {
      containerUser?.actions.toggleModalLogin();
    }
  };

  const loader = (
    <div className={ classesHelper.suspendLoader }>
      <CircularLoader />
    </div>
  );
  const hasLikes = !isNil(heart?.count ?? 0);

  const indicator = useMemo(() => {
    return !contentItem?.free
      ? subscribe?.status === ESubscriptionStatus.ACTIVE
        ? t('content.subscribed')
        : t('content.bySubscription')
      : undefined;
  }, [contentItem?.free, subscribe?.status]);

  const indicatorIcon = useMemo(() => {
    return !contentItem?.free ? (subscribe?.status === ESubscriptionStatus.ACTIVE ? IconAvailable : IconPaid) : IconFree;
  }, [contentItem?.free, subscribe?.status]);

  const recommendsList = useMemo(() => {
    return <Recommends contentId={ contentId } subscribe={ subscribe }/>;
  }, [contentId, isNotDesktop]);

  const content = useMemo(() => {
    return (
      <>
      { (contentItem?.free || subscribe.status === ESubscriptionStatus.ACTIVE) &&
        <>
          <img className={ styles.imageArticle } src={ contentItem?.imageUrl } alt={ contentItem?.name } />
          <div className={ styles.article } dangerouslySetInnerHTML={ { __html: contentItem?.text } } onClick={ onArticleLinkClick }/>
          <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Badge
              size={ isMobile ? 'sm' : 'xl' }
              type={ contentItem?.free
                ? 'free'
                : subscribe.status === ESubscriptionStatus.ACTIVE
                ? 'available'
                : 'paid'
              }
            />
            { hasLikes && (
              <Grid
                className={ containerContentList.status?.likeStatus !== ELoadStatus.ready || isLikesLoading
              ? styles.disabled
              : styles.likesContainer }
                item
              >
                <Likes
                  size={ isMobile ? 'sm' : 'xl' }
                  count={ heart?.count ?? 0 }
                  liked={ heart?.active ?? false }
                  // declined={ !accessible }
                  onClick={ () => handleClickLike(contentItem?.id, heart?.active) }
                  className={ styles.icon }
                />
              </Grid>
            ) }
          </Grid>
        </>
      }
    </>
    );
  }, [contentItem, heart, containerContentList, subscribe, isMobile, isLikesLoading]);

  const handleHeadTitle = () => {
    history.push(prevRoute ?? '/');
  };

  return (
    <ViewBox className={ styles.root }>
      { contentItem &&
        <HeadTitle callback={ handleHeadTitle }>
          <Header>{ contentItem.name }</Header>
        </HeadTitle>
      }
      <Wrapper className={ styles.wrapper }>
        { loading || !ready ? loader : content }
      </Wrapper>
        { recommendsList }
    </ViewBox>
  );
});
ContentItemArticle.displayName = 'ContentItemArticle';

export default flow([ContainerLogging])(ContentItemArticle);
