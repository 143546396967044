//#region imports
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';

import { IBadgeProps, EBadgeType } from './badge.models';
import stylesBadge from './badge.styles';
import { EBreakPointSize } from 'app/models/model.theme';
import IconFree from 'app/components/shared/icons/free/icon.free';
import IconPaid from 'app/components/shared/icons/paid/icon.paid';
import IconAvailable from 'app/components/shared/icons/available/icon.available';
//#endregion

export function getBadgeSize(currentSize) {
  switch (currentSize) {
    case 'xxs': return EBreakPointSize.sm;
    case 'xs': return EBreakPointSize.sm;
    case 'sm': return EBreakPointSize.sm;
    case 'md': return EBreakPointSize.md;
    case 'lg': return EBreakPointSize.md;
    case 'xl': return EBreakPointSize.lg;
    default: return EBreakPointSize.md;
  }
}

function getBadgeText(badgeType: keyof typeof EBadgeType, text?: string) {
  const { t } = useTranslation();
  if (text) {
    return text;
  }
  switch (badgeType) {
    case EBadgeType.free: return t('components.shared.badge.free');
    case EBadgeType.paid: return t('components.shared.badge.paid');
    case EBadgeType.available: return t('components.shared.badge.available');
    default: return '';
  }
}

function getBadgeIcon(badgeType: keyof typeof EBadgeType) {
  switch (badgeType) {
    case EBadgeType.free: return IconFree;
    case EBadgeType.paid: return IconPaid;
    case EBadgeType.available: return IconAvailable;
    default: return IconFree;
  }
}

const Badge: FC<IBadgeProps> = props => {
  const {
    className,
    type,
    text,
    size,
    inverted,
    onClick
  } = props;

  const styles = stylesBadge();

  const badgeSize = getBadgeSize(size);

  const Icon = getBadgeIcon(type);

  return (
    <div
      className={ classnames(className, styles.root, {
        [styles[badgeSize]]: size,
        'strokeIcon': type === EBadgeType.free || type === EBadgeType.paid,
        'invert': inverted
      }) }
      onClick={ onClick }
    >
      <Icon className={ styles.icon } />
      <span className={ styles.label }>{ getBadgeText(type, text) }</span>
    </div>
  );
};

Badge.displayName = 'Badge';

Badge.defaultProps = {
  type: 'paid',
  size: 'sm',
  inverted: false
} as IBadgeProps;

export default Badge;
