//#region imports
import React, { FC, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import { Button } from '@megafon/ui-core';
import { useHistory, useLocation } from 'react-router-dom';
import flow from 'lodash/flow';

import ModalLoginUI from 'app/components/shared/modal/login/modal.login';
import { IconExclamation } from 'app/components/shared/icons/exclamation/icon.exclamation';
import IconRefresh from 'app/components/shared/icons/refresh/icon.refresh';

import { ELoadStatus } from 'app/models/shared.model';
import { EPostConfirmStatus } from 'app/entities/user/user.model';
import useBreakpoints from 'app/hooks/use.breakpoints';
import { ContainerUser, IUserContainerProps } from 'app/containers/container.user';

import { validateProviderDefCode } from 'app/utils/util.auth';
import modalLoginStyles from './modal.login.styles';

import { MAX_TIME_SMS_CODE } from 'app/configs/const';
import { ContainerLogging, TContainerLoggingProps } from 'app/containers/container.logging';
import { getCleanNumbers, getMsisdn } from 'app/utils/msisdn.helper';
//#endregion

export interface IModalLoginProps {
  containerUser: IUserContainerProps;
  containerLogging: TContainerLoggingProps;
}

const ModalLogin: FC<IModalLoginProps> = ({ containerUser, containerLogging }) => {
  const {
    data: { authenticated },
    meta: { modal: { login: isOpen, extended: isExtended }, status: smsCodeStatus },
    request: { status },
    actions: { request, confirm, resetFields, toggleModalLogin }
  } = containerUser;
  const { actions: { logLogin } } = containerLogging;

  const { isMobile } = useBreakpoints();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();

  const classes = modalLoginStyles({ mobile: isMobile });

  const [msisdn, setMsisdn] = useState<string>('');
  const [smsCode, setSmsCode] = useState<string>('');
  const [isCodeExhausted, setIsCodeExhausted] = useState<boolean>(false);

  const [hasMsisdnInvalidError, setHasMsisdnInvalidError] = useState(false);
  const isSmsCode = useMemo(() => status.request === ELoadStatus.ready || Boolean(smsCodeStatus), [status, smsCodeStatus]);
  const cleanMsisdn = getMsisdn(msisdn);

  useEffect(() => {
    if (!isOpen) {
      setHasMsisdnInvalidError(false);
      setSmsCode('');
      setMsisdn('');
      resetFields(['request.status', 'request.error', 'meta.status']);
    }
  }, [isOpen]);

  useEffect(() => {
    if (authenticated && status.confirm === ELoadStatus.ready) {
      logLogin(pathname);
    }
  }, [authenticated, status.confirm]);

  useEffect(() => {
    if (status.request === ELoadStatus.ready) {
      resetFields(['meta.status']);
      setIsCodeExhausted(false);
    }
  }, [status.request]);

  useDebounce(() => {
    if (status.request === ELoadStatus.ready) {
      setIsCodeExhausted(true);
    }
  }, MAX_TIME_SMS_CODE, [status.request]);

  const handleSendCode = () => {
    if (cleanMsisdn) {
      if (validateProviderDefCode(cleanMsisdn)) {
        setHasMsisdnInvalidError(false);
        request(cleanMsisdn);
      } else {
        setHasMsisdnInvalidError(true);
      }
    }
  };

  const handleResendCode = () => {
    if (smsCode) {
      setSmsCode('');
      request(cleanMsisdn);
    }
  };

  const handleSmsLoginPost = () => {
    if (smsCode) {
      confirm({ msisdn: cleanMsisdn, smsCode });
    }
  };

  const handleCloseModal = () => {
    if (isExtended) {
      history.replace('/diet');
    }
    toggleModalLogin();
  };

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isSmsCode) {
      setSmsCode(getCleanNumbers(e.target.value.replace(/\s/g, '')));
    } else {
      setMsisdn(e.target.value);
    }
  };

  const requestStep = useMemo(() => (
    <>
      { hasMsisdnInvalidError && <div className={ classes.controlError }>{ t('auth.errors.msisdnInvalid') }</div> }
    </>
  ), [cleanMsisdn, hasMsisdnInvalidError, status, isExtended]);

  const confirmStep = useMemo(() => (
    <>
      { (smsCodeStatus && smsCodeStatus !== EPostConfirmStatus.OK || isCodeExhausted) && (
        <>
          { smsCodeStatus === EPostConfirmStatus.WRONG && !isCodeExhausted && <div className={ classes.controlError }>{ t('auth.codeStatus.wrong') }</div> }
          { (smsCodeStatus === EPostConfirmStatus.EXHAUSTED || isCodeExhausted) && <div className={ classes.controlError }>{ t('auth.codeStatus.exhausted') }</div> }
          <div className={ classes.sendCodeAgain } onClick={ handleResendCode }>
            <IconRefresh />
            <span>{ t('auth.resendCode') }</span>
          </div>
        </>
      ) }
    </>
  ), [smsCode, smsCodeStatus, status, isCodeExhausted]);

  const footer = useMemo(() => (
    <div className={ classes.footer }>
      <Button
        type="outline"
        onClick={ handleCloseModal }
        fullWidth
      >
        { t('common.cancel') }
      </Button>
      <>
        <div className={ classes.warning }>
          <div className={ classes.icon }>
            <IconExclamation />
          </div>
          { t('auth.warning') }
        </div>
      </>
    </div>
  ), [isExtended]);

  const inputOptions = {
    type: 'number',
    value: isSmsCode ? smsCode : msisdn,
    name: 'login',
    placeholder: isSmsCode ? t('auth.enterCode') : '',
    mask: isSmsCode ? null : ['+', '9', '9', '2', ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  };

  return (
    <ModalLoginUI
      open={ isOpen }
      title={ t('auth.title') }
      desc={ isSmsCode ? null : <span className={ classes.desc }>{ t('auth.descr') }</span> }
      onClick={ isSmsCode ? handleSmsLoginPost : handleSendCode }
      onClose={ handleCloseModal }
      onChange={ handleOnInputChange }
      size={ isMobile ? 'xs' : 'sm' }
      input={ inputOptions }
      renderInfo={ isSmsCode ? confirmStep : requestStep }
      buttons= { {
        submit: {
          title: isSmsCode ? t('auth.login') : t('auth.sendCode')
        }
      } }
      footer={ isExtended && footer }
      hasCloseButton
    />
  );
};

export default flow([ContainerUser, ContainerLogging])(ModalLogin);
