import React, { FC } from 'react';

import stylesHeaderTitle from './head.title.styles';
import classNames from 'classnames';
import IconClose from 'app/components/shared/icons/close/icon.close';
import ButtonBase from '@material-ui/core/ButtonBase';
import useBreakpoints from 'app/hooks/use.breakpoints';

export interface IHeadTitleProps {
  className?: string;
  callback: () => void;
}

const HeadTitle: FC <IHeadTitleProps> = props => {
  const { callback, className, children } = props;
  const { isTabletMenu } = useBreakpoints();
  const classes = stylesHeaderTitle({ isTabletMenu });

  return (
    <div className={ classes.root }>
      <div className={ classNames(className, classes.container) }>
        { children }
        <ButtonBase className={ classes.button } onClick={ callback }>
          <IconClose className={ classes.icon } />
        </ButtonBase>
      </div>
    </div>
  );
};

HeadTitle.displayName = 'HeadTitle';

export default HeadTitle;
