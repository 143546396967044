import { makeStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

export interface IStylesLogoProps {
  fontSize?: number | string;
  lineHeight?: number | string;
  color?: string;
  commentColor?: string;
  width?: number;
  isTabletMenu?: boolean;
}

export default makeStyles(theme => ({
  root: ({ fontSize, color }: IStylesLogoProps) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize,
    ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
    color
  }),
  logo: ({ width, commentColor, color }: IStylesLogoProps) => ({
    width,
    height: 'auto',
    '& .svg-comment': {
      color: commentColor || color
    }
  }),
  description: ({ color, lineHeight, isTabletMenu }: IStylesLogoProps) => ({
    [theme.breakpoints.down(320)]: {
      display: 'none'
    },
    position: 'relative',
    display: 'flex',
    lineHeight: lineHeight ?? 1,
    paddingLeft: isTabletMenu ? 10 : 17,
    margin: isTabletMenu ? '0 0 0 10px' : '0 0 4px 17px',
    '&::before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      content: "''",
      width: 1,
      backgroundColor: color
    }
  })
}));
