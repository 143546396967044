import { makeStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

export interface IStylesNavbarMenuProps {
  widthImg?: number;
}

export default makeStyles(theme => ({
  navbar: {
    display: 'flex',
    flex: '0 1 auto',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logoLinkWrap: {
    textDecoration: 'none'
  },
  navList: {
    flex: '1 1 auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyle: 'none',
    marginLeft: 60,
    padding: 0,
    ...configTheme.fontFamily.MegaFonGraphikLCRegular
  },
  navItem: {
    whiteSpice: 'no-wrap',
    padding: '5px 0',
    marginRight: 36,
    [theme.breakpoints.down(1270)]: {
      marginRight: 20
    },
    [theme.breakpoints.down(1205)]: {
      marginRight: 15
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  navLink: {
    color: configTheme.megafonColors.basic.black.normal,
    textDecoration: 'none',
    fontWeight: 400,
    '&.exact': {
      pointerEvents: 'none',
      color: configTheme.megafonColors.basic.green.default
    }
  }
}));
