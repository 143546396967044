import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconBurger: FC <SvgIconProps> = props => (
  <SvgIcon viewBox={ '0 0 23 23' } { ...props }>
    <rect x="7" y="9" width="16" height="2" fill="white"/>
    <rect x="7" y="14" width="16" height="2" fill="white"/>
    <rect x="7" y="19" width="16" height="2" fill="white"/>
  </SvgIcon>
);

export default IconBurger;
