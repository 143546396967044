//#region imports
import { makeStyles, createStyles } from '@material-ui/core';
//#endregion

export default makeStyles(theme =>
  createStyles({
    root: {},
    picture: {
      position: 'relative',
      margin: '0 auto',
      '& img': {
        width: '100%',
        objectFit: 'cover',
        borderRadius: 10
      }
    }
  }),
  { name: 'CardVerticalPicture' }
);
