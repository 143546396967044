//#region imports
import React, { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '@megafon/ui-core';

import IconAlert from 'app/components/shared/icons/alert/icon.alert';
import Logo from 'app/components/shared/logo/logo.index';
import { configTheme } from 'app/configs/theme/config.theme';

import { Logger } from 'app/entities/logging/logger';
import { stylesError } from './component.error.style';
import useBreakpoints from 'app/hooks/use.breakpoints';
//#endregion

export interface IComponentErrorProps {
  code: number;
  callback: Function;
  isCommon?: boolean;
  logo?: ReactNode;
}

export const ComponentError = ({ code, callback, isCommon = true }: IComponentErrorProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isMobile } = useBreakpoints();
  const styles = stylesError({ isMobile });

  const currentCode = code ?? 404;
  const localeKey = isCommon ? 'message' : currentCode;

  useEffect(() => {
    return () => callback();
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoHome = () => {
    history.push('/');
  };

  return (
    <>
      <Logger canSend />

      <div className={ styles.root }>
        <div className={ styles.content }>
          <div className={ styles.header }>
            <div className={ styles.logo } onClick={ handleGoHome }>
              <Logo
                description={ t('menu.logo') }
                fontSize={ isMobile ? 15 : 21 }
                width={ isMobile ? 150 : 200 }
                color={ configTheme.colors.basic.primary }
                commentColor={ configTheme.colors.basic.primary }
              />
            </div>
          </div>
          <IconAlert className={ styles.icon } viewBox={ '0 0 103.5 92' }/>
          <div className={ styles.title }>
            { t('error.errorWord') }
            { '\u00A0' }
            { currentCode }
          </div>

          <span className={ styles.descr }>{ t(`error.${localeKey}.desc`) }</span>

          <ul className={ styles.list }>
            <li className={ styles.item }>
              <Button
                type="outline"
                onClick={ history.length > 2 ? handleGoBack : handleGoHome }
                className={ styles.button }
              >
                { t('error.message.goBack') }
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
