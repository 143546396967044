import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Switcher, Header } from '@megafon/ui-core';

import { ISubscriptionCardProps } from 'app/models/model.subscription';
import styles from './card.subscription.mobile.styles';

const CardSubscriptionMobile: FC<ISubscriptionCardProps> = ({ price, toggleSubscribe, title, desc, isSubscribe, className = '' }) => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <div className={ cn(classes.root, className) }>
      <div className={ classes.content }>
        <Header className={ classes.title } as={ 'h3' }>
          { title }
        </Header>
        <div className={ classes.content }>
          <div className={ classes.desc } dangerouslySetInnerHTML={ { __html: desc } }/>
          <p className={ classes.price }> { t('common.price') }&nbsp;{ price } </p>
        </div>
      </div>
      <Switcher className={ classes.switcher } checked={ isSubscribe } onChange={ toggleSubscribe } />
    </div>
  );
};

CardSubscriptionMobile.displayName = 'CardSubscriptionMobile';

export default CardSubscriptionMobile;
