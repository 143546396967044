//#region imports
import { ReactNode, MouseEventHandler, MouseEvent } from 'react';
import { StandardProps } from '@material-ui/core';

import { EContentType, IContent, IContentGame, IContentOlympiad } from 'app/models/model.content';
import { ESubscriptionStatus } from 'app/models/model.subscription';
//#endregion

export const OFormat = { Fit: 'fit', Compact: 'compact' } as const;

export interface IItemCardProps {
  format?: typeof OFormat[keyof typeof OFormat];
  contentType?: EContentType;
  preview?: { src: string; width?: string | number; height?: string | number };
  data?: Partial<IContent['data'] & IContentGame['data'] & IContentOlympiad['data']>;
  mediaSrc?: string;
  descr?: string;
  titleClamp?: number;
  title: string;
  free?: boolean;
  subscribeStatus?: ESubscriptionStatus;
  itemCardId?: string;
  contentItemId?: string;
  authStatus?: boolean;
  liked?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  onPictureClick?: MouseEventHandler<HTMLElement>;
  onTitleClick?: MouseEventHandler<HTMLElement>;
  onButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  onMediaAudioPlayed?: (event: any) => void;
  handleClickLike?: (contentId: string, liked: boolean) => void;
  withIndicator?: boolean;
  withButton?: boolean;
  isNew ?: boolean;
  isLikesDisabled?: boolean;
}

export type ItemCardProps = StandardProps<IItemCardProps, never>;
