import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const IconClose: FC <SvgIconProps> = ({ fill, ...props }) => (
  /* tslint:disable */
  <SvgIcon viewBox={ '0 0 20 20' } { ...props }>
    <path d="M10.3537 12.1213L10.0001 11.7678L9.64658 12.1213L2.48757 19.2803L0.719802 17.5126L7.87881 10.3536L8.23237 10L7.87881 9.64646L0.719802 2.48745L2.48757 0.71968L9.64658 7.87869L10.0001 8.23224L10.3537 7.87869L17.5127 0.71968L19.2805 2.48745L12.1215 9.64646L11.7679 10L12.1215 10.3536L19.2805 17.5126L17.5127 19.2803L10.3537 12.1213Z" fill={ fill ? fill : '#1D1D1D' } stroke="white"/>
  </SvgIcon>
  /* tslint:enable */
);

export default IconClose;
