import { makeStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';

export interface IStylesModalMenuProps {
  logoTextSize?: number;
  widthImg?: number;
}

export default makeStyles(theme => ({
  root: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: 'fixed',
    zIndex: configTheme.layers.modal,
    overflowY: 'auto',
    background: configTheme.colors.background.light.default,
    display: 'flex',
    flexDirection: 'column'
  },
  modalHeader: {
    height: 58,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 18px',
    marginBottom: 20,
    boxShadow: '0px 7px 6px rgba(0, 0, 0, 0.08)'
  },
  modalClose: {
    fontWeight: 'bold',
    color: configTheme.colors.text.light.default,
    cursor: 'pointer'
  },
  icon: {
    width: 28,
    height: 28
  }
}));
