import { History } from 'history';

import get from 'lodash/get';

import { ELoadStatus } from 'app/models/shared.model';
import { ESubscriptionStatus, ISubscription } from 'app/models/model.subscription';

import { IModalsContainer } from 'app/containers/modals.container';
import { IUserContainerProps } from 'app/containers/container.user';

interface IUtilSubscribeCheck {
  readonly subscribe: ISubscription;
  readonly callback?: () => void;
  readonly callbackClose?: () => void;
  readonly callbackCancel?: () => void;
  readonly containerUser: IUserContainerProps;
  readonly containerModals: IModalsContainer;
  readonly history?: History;
}

const UtilSubscribeCheck = ({
  subscribe,
  callback,
  callbackClose,
  callbackCancel,
  containerUser,
  containerModals,
  history
}: IUtilSubscribeCheck) => {
  if (!containerUser?.data?.authenticated ||
    (get(containerUser, 'request.status.token') === ELoadStatus.ready && !get(containerUser, 'token'))) {
    containerUser?.actions.toggleModalLogin();
  } else {
    if (subscribe?.status === ESubscriptionStatus.NOT_ACTIVE) {
      return containerModals.actions.subscribeModal(subscribe.id, { handleCancel: callbackCancel });
    } else if (subscribe?.status === ESubscriptionStatus.NO_MONEY) {
      return containerModals.actions.noMoneyModal({ handleCancel: callbackCancel });
    } else {
      return callback && callback();
    }
  }
};

export default UtilSubscribeCheck;
