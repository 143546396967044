//#region imports
import { makeStyles, createStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

const computeSmSizeStyles = () => ({
  minWidth: 285,
  height: 229,
  padding: '18px 20px',
  '& img': {
    width: '100%',
    height: '100%',
    borderRadius: 4
  },
  '& div$picture': {
    minWidth: 'calc(50% - 15px)',
    marginRight: 15
  },
  '& h5$title': {
    fontSize: '16px',
    lineHeight: '19px',
    margin: '0 0 5px 0'
  },
  '& p$desc': {
    fontSize: '12px',
    lineHeight: '15px',
    color: configTheme.colors.text.dark.neutral,
    '-webkit-line-clamp': 5
  },
  '& button': {
    marginBottom: 20,
    '& .mfui-button__inner': {
      height: 30
    }
  }
});

const computeMdSizeStyles = () => ({
  height: 348,
  padding: '30px 35px',
  '& img': {
    width: '100%',
    height: '100%',
    borderRadius: 10
  },
  '& div$picture': {
    minWidth: 'calc(50% - 35px)',
    marginRight: 35
  },
  '& h5$title': {
    margin: '0 0 15px 0',
    fontSize: '24px',
    lineHeight: '28px'
  },
  '& p$desc': {
    fontSize: '19px',
    lineHeight: '28px',
    marginBottom: 10,
    '-webkit-line-clamp': 6
  },
  '& button': {
    margin: '15px 0 20px 0',
    '& .mfui-button__inner': {
      height: 43
    }
  }
});

export default makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'fit-content',
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      backgroundColor: configTheme.colors.basic.secondary,
      border: `1px solid ${ configTheme.colors.border.primary.light }`,
      borderRadius: 5,
      cursor: 'pointer'
    },
    content: {
      minWidth: '50%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    picture: {
      position: 'relative',
      '& img': {
        objectFit: 'cover',
        borderRadius: 10
      }
    },
    title: {
      padding: 0,
      overflow: 'hidden',
      whiteSpace: 'normal',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      '& h5': {
        marginTop: 0
      }
    },
    desc: {
      overflow: 'hidden',
      whiteSpace: 'normal',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      color: configTheme.megafonColors.basic.grey.default
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    button:  {},
    footer:  {
      display: 'flex',
      justifyContent: 'space-between'
    },
    badge:  {},
    likes:  {},
    xxs: computeSmSizeStyles(),
    xs: computeSmSizeStyles(),
    sm: computeSmSizeStyles(),
    md: computeMdSizeStyles(),
    lg: computeMdSizeStyles(),
    xl: computeMdSizeStyles()
  }),
  { name: 'CardVerticalArticle' }
);
