//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

export const useAudioStyles = makeStyles(
  createStyles({
    root: {},

    panel: {
      boxSizing: 'border-box',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'stretch',
      margin: '0 -8px'
    },

    controlBlock: {
      boxSizing: 'border-box',
      flex: '0 0 auto',
      padding: '0 8px',
      '& svg': { cursor: 'pointer' },
      '& span': { fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', letterSpacing: '0.00938em' }
    },
    sliderBlock: { boxSizing: 'border-box', flex: '1 1 auto', maxWidth: '72%', marginLeft: 'auto', padding: '0 8px' },

    slider: { padding: '6px 0' },
    sliderRail: { height: 3, color: configTheme.colors.icon.secondary },
    sliderTrack: { height: 3, color: configTheme.colors.icon.primary.default },
    sliderThumb: {
      width: 3,
      height: 3,
      marginTop: 0,
      marginLeft: 0,
      color: configTheme.colors.icon.primary.default,
      '&:hover': { color: configTheme.colors.icon.primary.default, boxShadow: 'none' },
      '&$sliderActive': { color: configTheme.colors.icon.primary.default, boxShadow: 'none' },
      '&$focusVisible': { color: configTheme.colors.icon.primary.default, boxShadow: 'none' }
    },
    sliderActive: {},

    focusVisible: {}
  }),
  { name: 'Audio' }
);
