import { LOGGING_ACTIONS } from 'app/entities/logging/logging.actions';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/actions/action.types';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

export interface ILoggingInitialState {
  position: string;
  contentId?: string;
  contentViewId?: string;
  categoryId: number;
  sectionId: number;
  subcategoryId: number;
  subsectionId: number;
  subscribeId: number;
}

const initialState: ILoggingInitialState = {
  position: '/',
  contentId: null,
  contentViewId: null,
  categoryId: null,
  sectionId: null,
  subcategoryId: null,
  subsectionId: null,
  subscribeId: null
};

export type TLoggingState = Readonly<typeof initialState>;

export default (state = initialState, action): TLoggingState => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST(LOGGING_ACTIONS.PART_VIDEO):
    case REQUEST(LOGGING_ACTIONS.START_VIDEO):
      return flow([
        set('contentViewId', null)
      ])(state);
    case SUCCESS(LOGGING_ACTIONS.PART_VIDEO):
    case SUCCESS(LOGGING_ACTIONS.START_VIDEO):
      return flow([
        set('contentViewId', payload.data.contentViewId)
      ])(state);
    case FAILURE(LOGGING_ACTIONS.PART_VIDEO):
    case FAILURE(LOGGING_ACTIONS.START_VIDEO):
      return flow([
        set('contentViewId', payload.data.contentViewId)
      ])(state);

    case LOGGING_ACTIONS.SET_STATE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
