import { IRootState } from 'app/shared/reducers/root.reducer';

import { Dispatch } from 'redux';
import { connect } from 'app/containers/connect';
import { IModalsState } from 'app/entities/modals/modals.reducer';
import {
  noMoneyModal,
  subscribeModal,
  maxNotificationsModal,
  closeModal,
  networkModal,
  subscribeNotFoundModal,
  notAuthorizedModal,
  unSubscribeModal,
  successActivateModal,
  howLoadNotificationsModal,
  failActivateModal
} from 'app/entities/modals/modals.actions';

export type IModalsContainer = IModalsState & {
  actions: {
    noMoneyModal: typeof noMoneyModal;
    subscribeModal: typeof subscribeModal;
    unSubscribeModal: typeof unSubscribeModal;
    subscribeNotFoundModal: typeof subscribeNotFoundModal;
    maxNotificationsModal: typeof maxNotificationsModal;
    closeModal: typeof closeModal;
    networkModal: typeof networkModal;
    notAuthorizedModal: typeof notAuthorizedModal;
    successActivateModal: typeof successActivateModal;
    failActivateModal: typeof failActivateModal;
    howLoadModal: typeof howLoadNotificationsModal;
  };
};

const mapStateToProps = (state: IRootState) => state.modals;
const mapDispatchToProps = (dispatch: Dispatch): IModalsContainer['actions'] => ({
  noMoneyModal: (...payload) => dispatch(noMoneyModal(...payload)),
  subscribeModal: (...payload) => dispatch(subscribeModal(...payload)),
  unSubscribeModal: (...payload) => dispatch(unSubscribeModal(...payload)),
  subscribeNotFoundModal: (...payload) => dispatch(subscribeNotFoundModal(...payload)),
  maxNotificationsModal: () => dispatch(maxNotificationsModal()),
  closeModal: () => dispatch(closeModal()),
  networkModal: () => dispatch(networkModal()),
  notAuthorizedModal: () => dispatch(notAuthorizedModal()),
  failActivateModal: () => dispatch(failActivateModal()),
  howLoadModal: () => dispatch(howLoadNotificationsModal()),
  successActivateModal: (...payload) => dispatch(successActivateModal(...payload))
});

export const ModalsContainer = connect<IModalsContainer>('modalsContainer', mapStateToProps, mapDispatchToProps);
