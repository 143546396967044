//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { configTheme } from 'app/configs/theme/config.theme';
import { EContentType } from 'app/models/model.content';
//#endregion

interface IProps {
  isNotDesktop?: boolean;
  isMobile?: boolean;
}

export const stylesDesktopContentItemPage = makeStyles<{}, IProps>(
  createStyles({
    wrapper: {
      padding: ({ isMobile }) => isMobile ? '15px 0 10px 0' : '15px 0 20px 0',
      backgroundColor: configTheme.megafonColors.basic.white
    },
    billboard: { marginBottom: configTheme.indents['indentX3'].margin },
    billboardPic: {
      marginRight: `${configTheme.indents['indentX3'].margin}px !important`,
      [`$billboard.${EContentType.GAME_ANDROID} & img`]: { borderRadius: 10 }
    },
    billboardTitle: {
      [`$billboard.${EContentType.GAME_ANDROID} &`]: { marginTop: configTheme.indents['indentX1'].margin }
    },
    billboardDescr: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      [`$billboard.${EContentType.GAME_ANDROID} &`]: { justifyContent: 'space-between' }
    },

    announce: {
      marginBottom: configTheme.indents['indentX1'].margin
    },

    imageArticle: {
      width: '100%',
      height: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 227 : 262) : 416,
      marginTop: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 20 : 25) : 35,
      objectFit: 'cover',
      borderRadius: 10
    },

    article: {
      color: configTheme.megafonColors.basic.black.normal,
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      '& a': {
        color: configTheme.megafonColors.basic.black.normal,
        ...configTheme.fontFamily.MegaFonGraphikLCRegular
      },
      '& h2': {
        ...configTheme.fontFamily.MegaFonGraphikLCRegular,
        marginTop: 0,
        fontSize: ({ isNotDesktop, isMobile }) => isMobile ? 16 : isNotDesktop ? 19 : 24,
        lineHeight: '44px',
        marginBottom: 20
      },
      '& h3, h4, h5': {
        fontSize: ({ isNotDesktop, isMobile }) => isMobile ? 16 : isNotDesktop ? 19 : 24,
        lineHeight: ({ isNotDesktop }) => isNotDesktop ? '23px' : '28px',
        ...configTheme.fontFamily.MegaFonGraphikLCRegular
      },
      '& p, li': {
        fontSize: ({ isNotDesktop, isMobile }) => isMobile ? 14 : isNotDesktop ? 16 : 19,
        lineHeight: ({ isNotDesktop, isMobile }) => isMobile ? '17px' : isNotDesktop ? '26px' : '30px'
      },
      '& :nth-child(-n+3)': {
        '& img': {
          display: 'none'
        }
      },
      '& img': {
        display: 'flex',
        margin: ({ isMobile }) => isMobile ? '20px auto' : '35px auto',
        width: ({ isMobile }) => isMobile ? '100%' : 'unset',
        height: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 227 : 295) : 'unset',
        objectFit: 'cover',
        borderRadius: ({ isNotDesktop }) => isNotDesktop ? 4 : 10
      }
    },
    articleTitle: {
      marginBottom: 20
    },

    audioBlock: {
      position: 'fixed',
      bottom: 0,
      left: 20,
      right: 20,
      maxWidth: 1116,
      margin: 'auto',
      height: '150px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: '5px',
      zIndex: 1,
      background: '#4D4D4D'
    },

    actions: {
      margin: `${configTheme.indents['indentX3'].margin}px 0`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    actionBtn: {
      marginRight: configTheme.indents['indentX1.5'].margin,
      width: 200,
      '&:last-child': {
        marginRight: 0
      }
    },

    promo: {
      marginTop: configTheme.indents['indentX3'].margin
    },

    recommends: {
      // margin: `auto -${configTheme.indents['indentX1'].margin}px 0`,
      // padding: `${configTheme.indents['indentX2'].margin}px ${configTheme.indents['indentX1'].margin}px`
      marginTop: configTheme.indents['indentX3'].margin
    },

    recommendsTitle: {
      marginBottom: `${configTheme.indents['indentX2'].margin}px`
    },

    recommendsPicture: {
      // marginBottom: `${configTheme.indents['indentX2'].margin + 4}px!important`
      marginBottom: '0!important',
      cursor: 'pointer'
    },

    recommendsCardDescr: {
      display: 'flex',
      alignItems: 'center',
      height: '100%'
    },

    recommendsCard: {
      height: '100%'
    },

    recommendsVirtual: {
      paddingBottom: 4,
      paddingLeft: 1
    },

    recommendsName: {
      margin: `0 ${configTheme.indents['indentX1'].margin}px`,
      cursor: 'pointer'
    },

    likesContainer: {
      lineHeight: ({ isMobile }) => isMobile ? '16px' : '22px'
    },

    iconFree: {
      marginBottom: '5%',
      marginLeft: '5%',
      padding: '2px 2px',
      borderRadius: '50%',
      backgroundColor: configTheme.colors.background.dark.default
    },
    icon: {
      '& svg': {
        fill: 'none',
        width: ({ isMobile }) => isMobile ? 20 : 28,
        height: ({ isMobile }) => isMobile ? 20 : 28
      },
      '& span': {
        fontSize: ({ isMobile }) => isMobile ? 12 : 15
      }
    },

    virtualItem: {
      backgroundColor: configTheme.colorsNew.background.white.default,
      '& img': {
        borderRadius: 5
      }
    },

    note: {
      padding: `${configTheme.indents['indentX1'].margin}px 0`,
      '& span': { display: 'inline-block', color: '#8F9399 !important' },
      '& span + span': { cursor: 'pointer', marginLeft: configTheme.indents['indentX2'].margin, textDecoration: 'underline' }
    },

    headline: { marginBottom: configTheme.indents['indentX2'].margin },

    thumbnail: { marginBottom: configTheme.indents['indentX1'].margin },
    thumbnailPic: {
      margin: `0 0 !important`
    },

    thumbnailPicBlur: {
      filter: 'blur(60px)'
    },

    thumbnailPicImage: {
      height: '416px!important'
    },

    descr: {
      marginBottom: configTheme.indents['indentX3'].margin,
      [`&.${EContentType.OLYMPIAD}`]: { marginBottom: configTheme.indents['indentX2'].margin * 1.33 }
    },

    screenshotList: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'flex-start'
    },
    screenshotItem: {
      flex: '1 1 calc((100% / 3) - 15px)',
      maxWidth: 'calc((100% / 3) - 15px + (15px / 3))',
      width: 'calc((100% / 3) - 15px + (15px / 3))',
      margin: `0 15px 0 0`,
      '&:nth-child(3n)': { marginRight: 0 }
    },
    screenshotImage: { width: '100%', height: 'auto' },

    recommendPack: {
      marginTop: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 10 : 15) : 10,
      paddingBottom: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 40 : 50) : 60,
      '& h1': {
        marginTop: ({ isNotDesktop, isMobile }) => isNotDesktop ? (isMobile ? 10 : 15) : 10
      }
    },
    recommendList: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-start',
      marginTop: configTheme.indents['indentX2'].margin
    },
    recommendItem: {
      cursor: 'pointer',
      flex: '1 1 calc((100% / 3) - 15px)',
      maxWidth: 'calc((100% / 3) - 15px + (15px / 3))',
      width: 'calc((100% / 3) - 15px + (15px / 3))',
      margin: `${configTheme.indents['indentX3'].margin}px 15px 0 0`,
      '&:nth-child(-n + 3)': { marginTop: 0 },
      '&:nth-child(3n)': { marginRight: 0 }
    },
    recommendElement: {
      cursor: 'pointer',
      flex: '1 1 calc((100% / 5) - 30px)',
      maxWidth: 'calc((100% / 5) - 30px + (30px / 5))',
      width: 'calc((100% / 5) - 30px + (30px / 5))',
      margin: `${configTheme.indents['indentX3'].margin}px 30px 0 0`,
      '&:nth-child(-n + 5)': { marginTop: 0 },
      '&:nth-child(5n)': { marginRight: 0 }
    },

    steps: {
      marginBottom: configTheme.indents['indentX2'].margin * 1.16,

      '& ol': {
        counterReset: 'step',
        margin: 0,
        paddingLeft: 0
      },
      '& li': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: configTheme.indents['indentX1'].margin * 1.16,
        fontSize: 16,
        lineHeight: 1.375,
        color: configTheme.megafonColors.basic.black.normal
      },
      '& li:before': {
        display: 'inline-flex',
        content: `counter(step) ""`,
        counterIncrement: 'step',
        flex: '0 0 auto',
        alignItems: 'center',
        justifyContent: 'center',
        float: 'left',
        clear: 'both',
        width: 36,
        height: 36,
        marginRight: configTheme.indents['indentX2'].margin * 0.83,
        borderRadius: '50%',
        fontSize: 22,
        lineHeight: 1.273,
        color: configTheme.colors.text.light.default,
        background: configTheme.colors.background.dark.default
      },
      '& li:after': { display: 'table', content: `""`, clear: 'both' },
      '& li:last-child': { marginBottom: 0 }
    },

    center: { display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' },

    btnWrapper: { marginTop: configTheme.indents['indentX3'].margin },

    disabled: {
      '& span': {
        cursor: 'default'
      }
    }
  }),
  { name: 'DesktopContentItemPage' }
);
