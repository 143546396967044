//#region imports
import { makeStyles, createStyles } from '@material-ui/core';

import { EIconColor, EIconSizeClassKey, EIconColorClassKey, IconColorClasses, IconSizeClasses } from 'app/models/model.icons';
import { IconTextStylesProps, EIconTextClassKey, IconTextClasses } from './icontext.models';
import { getIconColor } from 'app/utils/utils.icon';
import { configTheme } from 'app/configs/theme/config.theme';
//#endregion

export const useIconTextStyles = makeStyles(
  createStyles<IconTextClasses | IconColorClasses | IconSizeClasses, IconTextStylesProps>({
    [EIconTextClassKey.root]: {
      display: 'inline-flex',
      alignItems: 'center'
    },

    [EIconSizeClassKey.sizeDefault]: {
      fontSize: '15px',
      '& svg': {
        width: 28,
        height: 28,
        marginRight: 6
      }
    },
    [EIconSizeClassKey.sizeSmall]: {
      fontSize: '12px',
      '& svg': {
        width: 18,
        height: 18,
        marginRight: 4
      }
    },

    [EIconColorClassKey.colorDefault]: { color: getIconColor(EIconColor.default) },
    [EIconColorClassKey.colorPrimary]: { color: getIconColor(EIconColor.primary) },
    [EIconColorClassKey.colorSecondary]: { color: getIconColor(EIconColor.secondary) },
    [EIconColorClassKey.colorSuccess]: { color: getIconColor(EIconColor.success) },
    [EIconColorClassKey.colorError]: { color: getIconColor(EIconColor.error) },
    [EIconColorClassKey.colorWhite]: { color: getIconColor(EIconColor.white) },
    [EIconColorClassKey.colorDisabled]: { color: getIconColor(EIconColor.disabled) },

    [EIconTextClassKey.icon]: {
      'i&': {
        fontFamily: 'icons',
        fontStyle: 'normal',
        fontSize: '1.333em',
        '&:before': {
          color: 'currentColor',
          verticalAlign: 'baseline'
        }
      }

    },
    [EIconTextClassKey.iconOnly]: {},

    [EIconTextClassKey.text]: {
      marginBottom: '0.2em',
      whiteSpace: 'pre',
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      color: configTheme.colorsNew.text.light.default
    }
  }),
  { name: 'IconText' }
);
