import { IRootState } from 'app/shared/reducers/root.reducer';
import { IContentListState } from 'app/entities/content/content.list.reducer';

import {
  fetchContentList,
  resetContentList,
  updateRoteList,
  IFetchContentListProps,
  getItemLikes
} from 'app/entities/content/content.actions';

import { Dispatch, Action } from 'redux';
import { connect } from 'app/containers/connect';

export type IContainerContentListProps = IContentListState & {
  actions: {
    fetchContentList: (props: IFetchContentListProps) => void;
    resetContentList: typeof resetContentList;
    updateRoteList: typeof updateRoteList;
    getItemLikes: (arr) => void;
  };
};

const mapStateToProps = (state: IRootState) => state.contentList;
const mapDispatchToProps = (dispatch: Dispatch): IContainerContentListProps['actions'] => ({
  fetchContentList: props => dispatch((fetchContentList(props) as unknown) as Action),
  resetContentList: () => dispatch(resetContentList()),
  updateRoteList: route => dispatch(updateRoteList(route)),
  getItemLikes: arr => dispatch((getItemLikes(arr) as unknown) as Action)
});

export const ContainerContentList = connect<IContainerContentListProps>('containerContentList', mapStateToProps, mapDispatchToProps);
