import React, { FC } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

interface IInputMaskProps extends MaskedInputProps {
  inputRef: any;
}

export const InputMaskPhone: FC<IInputMaskProps> = props => {
  const { inputRef, mask, ...other } = props;

  return (
    <MaskedInput
      { ...other }
      ref={ ref => {
        inputRef(ref ? ref.inputElement : null);
      } }
      mask={ mask }
      guide
      showMask
      placeholderChar={ '_' }
    />
  );
};
