import { EDistribution } from 'app/models/shared.model';
import { ISubcategory } from 'app/models/model.subcategory';
import { ISubscription } from 'app/models/model.subscription';
import { IAnnounce } from 'app/entities/announce/announce.models';
import { EContentType } from 'app/models/model.content';

export enum ESectionLayout {
  IMAGE = 'IMAGE',
  MENU = 'MENU',
  TEXT = 'TEXT',
  NONE = 'NONE'
}

export interface ISectionItem {
  id: number;
  name: string;
  title?: string;
  preview: string;
  desktopPreview: string;
  parentId: number;
  contentType: EContentType;
  hasSubsections: boolean;
  allowedMainContent: number;
  allowedSectionContent: number;
  link?: string;
  onlySubscribers: boolean;
}

export interface ISection {
  announcement?: IAnnounce;
  contentType: EContentType;
  distribution: EDistribution;
  subcategory: ISubcategory;
  subscribe: ISubscription;
  subsections: ISectionItem[];
  section: ISectionItem;
}
