//#region imports
import React, { FC, forwardRef, useRef, useState } from 'react';
import { Banner as Carousel, Button, Header } from '@megafon/ui-core';
import { useForkRef } from '@material-ui/core';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import { useUpdateEffect, useSize } from 'react-use';
import classnames from 'classnames';
import shuffle from 'lodash/shuffle';
import map from 'lodash/map';

import { BannerProps, EBannerClassKey } from 'app/entities/banner/banner.model';
import { IBanner } from 'app/models/model.banner';

import useEllipsisStyles, { EEllipsisClassKey } from 'app/hooks/useEllipsisStyles';
import { useBannerStyles } from './banner.styles';
//#endregion

//#region Banner
export const Banner: FC<BannerProps> = forwardRef<HTMLDivElement, BannerProps>((props, ref) => {
  const { className, style, classes, data, shuffled, loading } = props;
  const styles = useBannerStyles({ classes });
  const ellipsisStyles = useEllipsisStyles({ lineClamp: 3 });
  const history = useHistory();

  const rootRef = useRef(null);
  const handleRef = useForkRef(rootRef, ref);

  const [displayData, setDisplayData] = useState(shuffled ? shuffle(data) : data);
  useUpdateEffect(() => {
    setDisplayData(shuffled ? shuffle(data) : data);
  }, [data, shuffled]);

  const handleRedirect = (e: React.SyntheticEvent<EventTarget>, url: string) => {
    e.preventDefault();
    e.stopPropagation();
    url.match('http') || url.match('https') ? window.open(url) : history.push(url);
  };

  const [loader] = useSize(({ width }) => {
    if (width === Infinity) return <div />;
    return (
      <div className={ styles[EBannerClassKey.banner] }>
        <ContentLoader key="loader" viewBox={ `0 0 ${width} 380` } width={ width } height={ 380 }>
          <rect x="0" y="0" rx="0" ry="0" width={ width } height="280" />
          <rect x="16" y="300" rx="8" ry="8" width="290" height="18" />
          <rect x="16" y="332" rx="16" ry="16" width="135" height="32" />
        </ContentLoader>
      </div>
    );
  });

  return (
    <div ref={ handleRef } className={ classnames(className, styles[EBannerClassKey.root]) } style={ style }>
      <div hidden={ !loading }>{ loader }</div>
      <div hidden={ loading }>
        <Carousel
          className={ styles[EBannerClassKey.carousel] }
          autoPlay
          loop
        >
          {
            map(displayData, (item: IBanner, index: number) => (
              <div
                key={ `banner_${index.toString(36)}/${item.url}` }
                className={ classnames(styles[EBannerClassKey.banner]) }
                onClick={ e => handleRedirect(e, item.url) }
              >
                <div className={ classnames(styles[EBannerClassKey.content]) }>
                  <Header className={ classnames(styles[EBannerClassKey.title], ellipsisStyles[EEllipsisClassKey.multiline]) } color="white" as="h2">
                    { item.displayText }
                  </Header>
                  <Button theme="purple" className={ classnames(styles[EBannerClassKey.button]) } onClick={ e => handleRedirect(e, item.url) }>
                    { item.buttonText }
                  </Button>
                </div>
                <img
                  src={ item?.imageUrl }
                  alt="banner-img"
                  className={ classnames(styles[EBannerClassKey.image]) }
                />
              </div>
            ))
          }
        </Carousel>
      </div>
    </div>
  );
});
Banner.displayName = 'Banner';

Banner.defaultProps = { data: [], loading: false, shuffled: false } as BannerProps;

export default Banner;
//#endregion
