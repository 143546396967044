import { EIconColor } from 'app/models/model.icons';
import { configTheme } from 'app/configs/theme/config.theme';

export function getIconColor(color: string) {
  switch (color) {
    case EIconColor.inherit:
      return 'inherit';
    case EIconColor.success:
      return configTheme.colors.icon.success;
    case EIconColor.error:
      return configTheme.colors.icon.error;
    case EIconColor.white:
      return configTheme.colors.icon.white;
    case EIconColor.disabled:
      return configTheme.colors.icon.disabled;
    case EIconColor.secondary:
      return configTheme.colors.icon.secondary;
    case EIconColor.primary:
      return configTheme.colors.icon.primary.default;
    case EIconColor.default:
    default:
      return configTheme.colors.icon.primary.default;
  }
}
