import { megafonColors } from './config.colors';

export const fontFamily = {
  MegaFonGraphikLCLight: {
    fontFamily: 'MegaFonGraphikLC Light, Arial, sans-serif',
    letterSpacing: '-0.3px',
    lineHeight: 1.2
  },
  MegaFonGraphikLCRegular: {
    fontFamily: 'MegaFonGraphikLC Regular, Arial, sans-serif',
    letterSpacing: '-0.3px',
    lineHeight: 1.2
  },
  MegaFonGraphikLCMedium: {
    fontFamily: 'MegaFonGraphikLC Medium, Arial, sans-serif',
    letterSpacing: '-0.3px',
    lineHeight: 1.2
  },
  MegaFonGraphikLCSemibold: {
    fontFamily: 'MegaFonGraphikLC Semibold, Arial, sans-serif',
    letterSpacing: '-0.3px',
    lineHeight: 1.2
  },
  MegaFonGraphikLCBold: {
    fontFamily: 'MegaFonGraphikLC Bold, Arial, sans-serif',
    letterSpacing: '-0.3px',
    lineHeight: 1.2
  },
  MegaFonGraphikLCBlack: {
    fontFamily: 'MegaFonGraphikLC Black, Arial, sans-serif',
    letterSpacing: '-0.3px',
    lineHeight: 1.2
  }
};
