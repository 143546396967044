import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { configTheme } from 'app/configs/theme/config.theme';

interface IProps {
  isMobile?: boolean;
}

export const useStylesModalIndividualProgram = makeStyles<{}, IProps>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 300,
      transform: 'translateZ(0)'
    },
    autocomplete: {
      '& .MuiFilledInput-root': {
        backgroundColor: configTheme.colors.background.light.default,
        border: '1px solid #D5D8DD'
      },
      '& .MuiFilledInput-underline:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none'
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: 'none'
      }
    },
    paper: {
      width: ({ isMobile }) => isMobile ? 335 : 372,
      maxHeight: '100vh',
      backgroundColor: configTheme.megafonColors.basic.white,
      boxSizing: 'border-box',
      borderRadius: 5
    },
    container: {
      padding: '30px 20px 20px 20px',
      fontSize: ({ isMobile }) => isMobile ? 14 : 17
    },
    header: {
      display: 'flex',
      height: ({ isMobile }) => isMobile ? 56 : 76,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
      backgroundColor: configTheme.megafonColors.basic.green.default
    },
    title: {
      fontSize: ({ isMobile }) => isMobile ? 16 : 19,
      lineHeight: ({ isMobile }) => isMobile ? '17px' : '30px',
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      color: configTheme.megafonColors.basic.white
    },
    resultDesc: {
      margin: ({ isMobile }) => isMobile ? '15px 0' : '25px 0'
    },
    weightContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: ({ isMobile }) => isMobile ? 20 : 30
    },
    weightDesc: {
      maxWidth: 188,
      marginRight: 15,
      fontSize: ({ isMobile }) => isMobile ? 14 : 17,
      '& strong': {
        ...configTheme.fontFamily.MegaFonGraphikLCRegular
      }
    },
    weightInput: {
      width: 90,
      '& .MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        top: 50,
        right: ({ isMobile }) => isMobile ? -15 : 4
      }
    },
    buttons: {
      margin: ({ isMobile }) => isMobile ? '30px 0 20px 0' : '35px 0 30px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& button:nth-child(2n)': { marginTop: 20 },
      '& .mfui-button__inner': {
        height: 43,
        fontSize: ({ isMobile }) => isMobile ? 16 : 17
      }
    },
    button: {
      padding: 0
    },
    imgClose: {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    telNumber: {
      fontWeight: 'bold'
    },
    btnBlock: {
      marginTop: 36
    },
    btnLinkCancel: {
      color: 'inherit',
      textDecoration: 'none'
    },
    btnCancel: {
      backgroundColor: 'inherit',
      width: 183,
      height: 60,
      color: 'inherit',
      ...configTheme.fontFamily.MegaFonGraphikLCRegular,
      fontWeight: 'bold'
    },
    btnSubscribe: {
      marginRight: 36,
      width: 183,
      height: 60
    },
    descBlock: {
      marginTop: 36,
      color: '#8A8F97'
    },
    linkUserAgreements: {
      color: '#8A8F97'
    }
  })
);
