//#region imports
import { makeStyles, createStyles } from '@material-ui/core';
import { configTheme } from 'app/configs/theme/config.theme';
import { EBannerClassKey, BannerClasses, BannerKeyframes } from 'app/entities/banner/banner.model';
//#endregion

export const useBannerStyles = makeStyles(theme =>
  createStyles<BannerClasses | BannerKeyframes, {}>({
    [EBannerClassKey.root]: {
      maxWidth: 1440,
      margin: `0 auto`
    },
    [EBannerClassKey.banner]: {
      background: configTheme.megafonColors.basic.green.default,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `32px ${configTheme.indents['indentX1'].margin}px 56px`,
      height: 'inherit',
      justifyContent: 'space-between',
      [theme.breakpoints.up(configTheme.breakpoints.values.sm)]: {
        padding: `0 89px 0 ${configTheme.indents['indentX1'].margin}px`,
        flexDirection: 'row'
      },
      [theme.breakpoints.up(configTheme.breakpoints.values.lg)]: {
        padding: `0 150px 0 ${configTheme.indentsDesktop['indentX1'].margin}px`,
        flexDirection: 'row'
      }
    },
    [EBannerClassKey.content]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up(configTheme.breakpoints.values.sm)]: {
        alignItems: 'flex-start'
      },
      '& .mfui-header': {
        margin: '0 0 23px 0',
        textAlign: 'center',
        [theme.breakpoints.up(configTheme.breakpoints.values.sm)]: {
          textAlign: 'left',
          margin: '0 30px 23px 0'
        }
      }
    },
    [EBannerClassKey.carousel]: {
      height: '453px',
      width: 'unset',
      [theme.breakpoints.up(configTheme.breakpoints.values.sm)]: {
        height: '268px'
      },
      [theme.breakpoints.up(configTheme.breakpoints.values.lg)]: {
        height: '501px'
      },
      '& .swiper-container, .swiper-wrapper, .swiper-slide': {
        height: 'inherit'
      },
      '& .mfui-banner__arrow_prev, .mfui-banner__arrow_next': {
        display: 'none'
      },
      '& .mfui-banner__pagination': {
        marginBottom: 5,
        background: configTheme.megafonColors.basic.green.transparent,
        borderRadius: '50px',
        [theme.breakpoints.up(configTheme.breakpoints.values.lg)]: {
          marginBottom: 10
        }
      },
      '& circle': {
        animation: 'none'
      }
    },
    [EBannerClassKey.image]: {
      height: 259,
      marginBottom: 40,
      [theme.breakpoints.up(configTheme.breakpoints.values.sm)]: {
        height: 222,
        marginBottom: 0
      },
      [theme.breakpoints.up(configTheme.breakpoints.values.lg)]: {
        height: 476,
        marginBottom: 0
      }
    },
    [EBannerClassKey.button]: {
      display: 'flex',
      alignItems: 'center',
      height: 43,
      width: 124,
      fontSize: '16px',
      [theme.breakpoints.up(configTheme.breakpoints.values.sm)]: {
        width: 156
      },
      [theme.breakpoints.up(configTheme.breakpoints.values.lg)]: {
        width: 223,
        height: 60,
        fontSize: '19px'
      }
    },
    [EBannerClassKey.title]: {
      fontSize: '26',
      lineHeight: '36px',
      margin: '0 0 20px 0',
      ...configTheme.fontFamily.MegaFonGraphikLCSemibold,
      [theme.breakpoints.up(configTheme.breakpoints.values.sm)]: {
        fontSize: 30
      },
      [theme.breakpoints.up(configTheme.breakpoints.values.lg)]: {
        fontSize: '52px',
        lineHeight: '62px'
      }
    },

    '@keyframes disappear': {
      '0%': {
        opacity: 1
      },

      '100%': {
        opacity: 0.5
      }
    },

    '@keyframes appear': {
      '0%': {
        opacity: 0.5
      },

      '100%': {
        opacity: 1
      }
    }
  }),
  { name: 'Banner' }
);
